import React, { useContext } from 'react'
import globalContext from '../../../context/globalContext';
import { items } from "./ItemsHeader";

const Header = (props) => {
  const {modalidad, final, clases, categorias} = props;
  const { state } = useContext(globalContext);
  const { competence } = state;
  if(competence) return (
    <>
      <tr id="encabezadoResultados" className={clases} children={
        <>{modalidad in items[competence.modalidad]?
            items[competence.modalidad][modalidad].map((i, index)=>
              final&&i.item==='q'?null:
                <th 
                  key={i.show+index} 
                  children={
                    typeof i.show === 'string'?
                    `${i.show}${i.show==='Gimnastas'?(categorias?categorias.map(e=>` / ${e?e.toLowerCase():''}`):''):''}`
                    :i.show
                  }
                  className={i.clases}
                />
            )
          :null}
        </>
      }/>
    </>
  )
}

export {Header};