import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import globalContext from '../../context/globalContext';
import { convenciones } from '../screens/Convenciones';
import Individual from "../screens/Individual";

const caracteres = ['1','2','3','4','5','6','7','8','9','.','0'];

export default function Generic() {
  const { state, sendServer, URLdomain } = useContext(globalContext);
  const { online, competence, current, authorized } = state;
  let { puesto, competencia_id, modalidad, organizacion, panel } = useParams();
  const [userText, setUserText] = useState("");
  const navigate = useNavigate();
  useEffect(() => {// Control de acceso!!!!!!!!!
    if(authorized===false){
      navigate(`/${organizacion}/${modalidad}/${competencia_id}`)
    }else if(authorized===undefined){
      sendServer({
        domain: organizacion,
        app: modalidad,
        action:'authorized',
        puesto: puesto,
        panel: panel,
        competencia_id: competencia_id,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorized])

  const [disabled, setdisabled] = useState(true);
  const [puestoLocal, setpuestoLocal] = useState('artística_1');

  useEffect(() => {
    if(modalidad==="Aerobica"&&puesto==='dificultad_1'&&current.dificultad_1!==null){
      setpuestoLocal('deducciones_dificultad_total')
    }else{
      setpuestoLocal(puesto)
    }
    setdisabled(current[puestoLocal]!==null||current.publicado)
    if(current[puestoLocal]&&current[puesto]!==undefined){
      setUserText(current[puestoLocal].toFixed(2));
    }else{
      setUserText('');
    }
    document.title = `${puesto} | ${current.participante}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current[puesto], current[puestoLocal], puesto, current['publicado']])
  
  const handleUserKeyPress = useCallback(event => {
    const { key, keyCode  } = event;
    if(caracteres.includes(key)){
      setUserText(prevUserText => {
        if(prevUserText.match(/[.]/) && key==='.'){
          console.log('No puedes poner dos puntos')
        }else if(prevUserText.length > 4){
          console.log('maximo 5 caracteres');
        }else if(parseFloat(`${prevUserText}${key}`) > 50){
          console.log('El valor maximo es 10');
        }else{
          return `${prevUserText}${key}`
        }
        return `${prevUserText}`
      })
    }else if(keyCode === 8){
      setUserText(prevUserText => `${prevUserText.substr(0, prevUserText.length-1)}`)
    }else if(keyCode === 46){
      setUserText(``)
    }else if(keyCode === 188){
      setUserText(prevUserText => {
        if(prevUserText.match(/[.]/))return `${prevUserText}`
        return `${prevUserText}.`
      })
    }else if(keyCode === 13){
      setUserText(prevUserText => {
        cargarNota(prevUserText)
        return `${prevUserText}`
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);
  useEffect(() => {
    if(online&&!competence)sendServer({domain: organizacion, app:'global', action:'getCompetence', id:competencia_id});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [online, competence, competencia_id, sendServer])

  useEffect(() => {
    sendServer({domain: organizacion, app:'global', action:'getAsignations', id:competencia_id})
    sendServer({
      domain: organizacion,
      app: modalidad,
      action:'getCurrent',
      panel: panel,
      competencia_id: competencia_id,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  

  useEffect(() => {
    let interval = setInterval(() => {// && params.ver==='resultados'
      // if(online){
        sendServer({
          domain: organizacion,
          app: modalidad,
          action:'getCurrent',
          panel: panel,
          competencia_id: competencia_id,
        })
      // }
    }, 3000);
    return ()=> clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [online, competence, competencia_id, sendServer])
  const cargarNota =(nota)=>{
    if(nota==='')nota=null;
    sendServer({
      domain: organizacion,
      app: modalidad,
      action:'cargarNota',
      puesto: puesto,
      panel: panel,
      competencia_id: competencia_id,
      data:{
        id:current.id,
        competencia_id:current.competencia,
        [puestoLocal]:nota,
        publicado:false,
      }
    })
  }
  if(!competence||!current||!authorized)return null;
  if(current.publicado)return <Individual panel={panel}/>;
  return (
    <div className='row marginAuto'>
      <div className='actionsContainer' children={
        <>
          {current?
            <p children={
              <>
                <img className='mini' src={`https://consugi.consugisoft.com/media/${ current[convenciones[modalidad]['bandera']] }`} alt='Bandera'/>
                <span children={`(${current[convenciones[modalidad]['representacion']]}) ${current[convenciones[modalidad]['categoria_edad']]} - ${current[convenciones[modalidad]['aparato']]} - ${current.participante}`}/>
              </>
            }/>
          :null}
          <div className='text-center userTextCont' children={<b className='userText' children={userText}/>} />
          <div className='text-center puesto' children={puestoLocal.replace('_total', ' ').replace('_', ' ')} />
          <div className="btn-group col-12" role="group" aria-label="Basic mixed styles example">
            <button disabled={disabled} type="button" className="btn btn-danger" onClick={()=>setUserText('')}>Borrar</button>
            <button disabled={disabled} type="button" className="btn btn-success" onClick={()=>cargarNota(userText)}>Enviar</button>
          </div>
        </>
      }/>
      <div className='tecladoContainer' children={
        <>
        {caracteres.map(n=><button disabled={disabled} type="button" key={n} onClick={()=>handleUserKeyPress({key:n})} className="btn btn-primary" children={<span children={n}/>}/>)}
        <button disabled={disabled} type="button" className="btn btn-primary" onClick={()=>setUserText(userText.substr(0, userText.length-1))} children={<span children={'◄'}/>}/>
        </>
      }/>
    </div>
  )
}