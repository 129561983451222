import { calendario, lista_de_jueces, panel, asignacion_de_paneles, SubTableAsignaciones } from "./Artistica"

const aer = [
  { item: 'puesto', show: '#', clases: 'text-end' },
  { item: 'participante', show: 'Gimnastas', clases: 'text-start' },
  { item: 'q', show: 'Q', clases: 'text-end' },
  { item: 'representacion__bandera', show: '', clases: 'text-end' },
  { item: 'representacion', show: 'Equipo', clases: 'text-start hide_md_down' },
  { item: 'artística_final', show: 'A', toFixed: 3, clases: 'text-end hide_md_down' },
  { item: 'ejecución_final', show: 'E', toFixed: 3, clases: 'text-end hide_md_down' },
  { item: 'dificultad_final', show: 'D', toFixed: 3, clases: 'text-end hide_md_down' },
  // { item: 'litf', show: 'Litf', toFixed: 1, clases: 'text-end hide_md_down' },
  { item: 'deducciones_CJP', show: 'Cjp', toFixed: 1, clases: 'text-end text-danger hide_md_down' },
  { item: 'deducciones_dificultad_total', show: 'D', toFixed: 1, clases: 'text-end text-danger hide_md_down' },
  { item: 'linea_total', show: 'L', toFixed: 1, clases: 'text-end text-danger hide_md_down' },
  { item: 'total', show: 'Total', toFixed: 3, clases: 'text-end' },
]
const team = [
  { item: 'puesto', show: '#', clases: 'text-end' },
  { item: 'representacion__bandera', show: '', clases: 'text-end' },
  { item: 'representacion', show: 'Equipo', clases: 'text-start' },
  { item: 'categoria', show: 'Mod.', clases: 'text-center hide_md_down' },
  { item: 'puesto_a', show: 'Puesto', clases: 'text-end hide_md_down' },
  { item: 'total', show: 'Total', clases: 'text-end' },
]

const lista_equipos = [
  { item: 'count', show: 'Total', clases: 'text-end' },
  { item: 'representacion__bandera', show: '', clases: 'text-end' },
  { item: 'representacion', show: 'Equipo', clases: 'text-start' },
]
const orden_de_paso = [
  { item: 'orden_de_salida', show: '#', clases: 'text-end' },
  { item: 'participante', show: 'Gimnastas', clases: 'text-start' },
  { item: 'categoria_edad', show: 'Edad', clases: 'text-start' },
  { item: 'categoria_de_participacion', show: '', clases: 'text-start' },
  { item: 'representacion__bandera', show: '', clases: 'text-end' },
  { item: 'representacion', show: 'Equipo', clases: 'text-start' },
]
const individualScores = [
  { item: 'puesto', show: '#', clases: 'text-end' },
  { item: 'participante', show: 'Gimnastas', clases: 'text-start' },
  { item: 'q', show: 'Q', clases: 'text-end' },
  { item: 'representacion__bandera', show: '', clases: 'text-end' },
  { item: 'representacion', show: 'Equipo', clases: 'text-start hide_md_down' },
  { item: 'artística_1', show: 'A1', toFixed: 1, clases: 'text-end hide_md_down lh15px ht12' },
  { item: 'artística_2', show: 'A2', toFixed: 1, clases: 'text-end hide_md_down lh15px ht12' },
  { item: 'artística_3', show: 'A3', toFixed: 1, clases: 'text-end hide_md_down lh15px ht12' },
  { item: 'artística_4', show: 'A4', toFixed: 1, clases: 'text-end hide_md_down lh15px ht12' },
  { item: 'artística_final', show: 'A', toFixed: 3, clases: 'text-end hide_md_down text-center' },
  { item: 'ejecución_1', show: 'E1', toFixed: 1, clases: 'text-end hide_md_down lh15px ht12' },
  { item: 'ejecución_2', show: 'E2', toFixed: 1, clases: 'text-end hide_md_down lh15px ht12' },
  { item: 'ejecución_3', show: 'E3', toFixed: 1, clases: 'text-end hide_md_down lh15px ht12' },
  { item: 'ejecución_4', show: 'E4', toFixed: 1, clases: 'text-end hide_md_down lh15px ht12' },
  { item: 'ejecución_final', show: 'E', toFixed: 3, clases: 'text-end hide_md_down text-center' },
  { item: 'dificultad_final', show: 'D', toFixed: 3, clases: 'text-end hide_md_down text-center' },
  // { item: 'litf', show: 'Litf', toFixed: 1, clases: 'text-end hide_md_down' },
  { item: 'deducciones_CJP', show: 'Cjp', toFixed: 1, clases: 'text-end text-danger hide_md_down' },
  { item: 'deducciones_dificultad_total', show: 'D', toFixed: 1, clases: 'text-end text-danger hide_md_down' },
  { item: 'linea_total', show: 'L', toFixed: 1, clases: 'text-end text-danger hide_md_down' },
  { item: 'total', show: 'Total', toFixed: 3, clases: 'text-end' },
]
const medallistas = [
  { item: 'evento', show: 'Evento', clases: 'text-center borderSolid align-middle backgroundWhite lh1-3' },
  { item: 'medalla', show: 'Medalla', clases: 'text-center borderSolid align-middle backgroundWhite lh1-3' },
  { item: 'representacion__bandera', show: '', clases: 'text-end noPadding w30px borderSolid align-middle backgroundWhite lh1-3' },
  { item: 'representacion__siglas', show: '', clases: 'text-center borderSolid align-middle backgroundWhite lh1-3' },
  { item: 'participante', show: 'Gimnastas', clases: 'text-start borderL borderSolid lh1-3' },
]
const Aerobica = {
  AD: aer,
  AS: aer,
  GR: aer,
  IF: aer,
  IM: aer,
  PAR: aer,
  TR: aer,
  team: team,
  medallistas: medallistas,
  lista_equipos: lista_equipos,
  orden_de_paso: orden_de_paso,
  'Individual Judges Scores': individualScores,
  calendario: calendario,
  lista_de_jueces: lista_de_jueces,
  panel: panel,
  asignacion_de_paneles: asignacion_de_paneles,
  SubTableAsignaciones: SubTableAsignaciones,
}

export {Aerobica};