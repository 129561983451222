import React, { useContext, useEffect } from 'react';
import globalContext from '../../../context/globalContext';
import { useParams } from 'react-router-dom';
import { convenciones } from "../Convenciones";

export default function Chroma() {
  const URLdomain = 'https://consugi.consugisoft.com';
  const { state, sendServer } = useContext(globalContext);
  const { current, competence, online } = state;
  const { competencia_id, modalidad, organizacion } = useParams();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  let urlParams = new URLSearchParams(window.location.search);
  const panel = urlParams.get('panel');
  const id = urlParams.get('id');

  useEffect(() => {
    document.title = `Chroma`;
    sendServer({
      domain: organizacion,
      app: modalidad,
      action: id ? 'getInscripcion' : 'getCurrent',
      competencia_id: competencia_id,
      panel: panel,
      id: id,
    });
    let interval = setInterval(() => {
      sendServer({
        domain: organizacion,
        app: modalidad,
        action: id ? 'getInscripcion' : 'getCurrent',
        competencia_id: competencia_id,
        panel: panel,
        id: id,
      });
    }, 8000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [online, competence]);

  if (!current.id) return null;

  return (
    <div className='chromaGreen h100' id='full' onClick={() => { !document.fullscreenElement ? document.getElementById("full").requestFullscreen() : document.exitFullscreen() }} children={
      <>
        <link rel="stylesheet" href="/static/css/fullScreen.css" />
        <link rel="stylesheet" href="/static/css/estilos.css" />
        <main />
        <div className="seccion-datos-chroma" children={
          <div className='bottomBlock gradient'
            children={
              <>
                {current.publicado ?
                  <div className="marginAuto row">
                    <div className="col-10"></div>
                    <div className="text-truncate text-end col-2">
                      <span className="float-start">E</span>
                      {current[convenciones[modalidad]['ejecución_final']] ? current[convenciones[modalidad]['ejecución_final']].toFixed(3) : null}
                    </div>
                  </div>
                  : null}
                <div className="marginAuto row">
                  <div className="col-10">
                    <div className="marginAuto row fila-dato1">
                      {modalidad === 'Ritmica' ?
                        <div style={{ backgroundImage: `url('${URLdomain}/static/media/${current['aparato__icono']}')` }}
                          className="modalidadChroma col-1"></div>
                        :
                        <div style={{ backgroundImage: `url('${URLdomain}/static/recursos/${modalidad}/${current[convenciones[modalidad]['aparato']]}.png')` }}
                          className="modalidadChroma col-1">{modalidad === 'Trampolin' ? current.aparato : null}</div>
                      }
                      <div style={{ backgroundImage: `url('https://consugi.consugisoft.com/media/${current[convenciones[modalidad]['bandera']]}')` }}
                        className="representacionChroma col-1"></div>
                      <div className="text-truncate col">
                        {`
                          ${current[convenciones[modalidad]['siglas']]} | ${current[convenciones[modalidad]['categoria_edad']]}
                          | ${current.participante}
                        `}
                      </div>
                      {current.publicado ?
                        <div className="text-truncate text-end col-2">
                          <b>
                            <span className="float-start">T</span>
                            {current.dns // Si el gimnasta no participó (DNS)
                              ? "DNS" // Muestra "DNS"
                              : current[convenciones[modalidad]['total']] !== null // Si no es DNS, verifica si el total no es null
                              ? current[convenciones[modalidad]['total']].toFixed(3) // Muestra el total formateado
                              : "" // Si no cumple las condiciones, muestra una cadena vacía
                            }
                          </b>
                        </div>
                        : null}
                    </div>
                  </div>
                  {current.publicado ?
                    <div className="text-truncate text-end col-2">
                      <span className="float-start">D</span>
                      {current[convenciones[modalidad]['dificultad']] ? current[convenciones[modalidad]['dificultad']].toFixed(3) : null}
                    </div>
                    : null}
                </div>
                {current.publicado && modalidad !== 'Ritmica' ?
                  <div className="marginAuto row">
                    <div className="col-10">
                    </div>
                    <div className="text-truncate text-end red-text col-2">
                      <span className="float-start">N</span>
                      {current[convenciones[modalidad]['neutrales']] ? current[convenciones[modalidad]['neutrales']].toFixed(3) : null}
                    </div>
                  </div>
                  : null}
                {current.publicado && modalidad === 'Ritmica' ?
                  <div className="marginAuto row">
                    <div className="col">
                    </div>
                    <div className="text-truncate text-end red-text col-2">
                      {current.total_faltas ? current.total_faltas.toFixed(3) : null}
                    </div>
                    <div className="text-truncate text-end col-2">
                      <span className="float-start">A</span>
                      {current.artistica_final ? current.artistica_final.toFixed(3) : null}
                    </div>
                  </div>
                  : null}
              </>
            }
          />
        } />
      </>
    } />
  );
}