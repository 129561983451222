import "./App.css";
import { useContext, useEffect, useState } from "react";
import globalContext from "./context/globalContext";
import Rutas from "./components/Rutas";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link, useNavigate } from "react-router-dom";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";

let puestos = {
  Aerobica: {
    editor: "judge/editor",
    cjp: "judge/cjp",
    dificultad_1: "judge/dificultad_1",
    linea_1: "judge/linea_1",
    linea_2: "judge/linea_2",
  },
  Artistica: {
    editor: "judge/editor",
    dificultad: "judge/dificultad",
    línea_1: "judge/línea_1",
    línea_2: "judge/línea_2",
    bonus: "judge/bonus",
  },
  Trampolin: {
    editor: "judge/editor",
    Arbitro: "judge/arbitro",
    dificultad: "judge/dificultad",
    línea_1: "judge/línea_1",
    línea_2: "judge/línea_2",
  },
  Ritmica: {
    editor: "judge/editor",
    coordinador: "judge/coordinador",
    dificultad_a: "judge/dificultad_a",
    dificultad_b: "judge/dificultad_b",
    línea_1: "judge/línea_1",
    línea_2: "judge/línea_2",
    cronómetro: "judge/cronómetro",
  },
  Parkour: {
    editor: "judge/editor",
    cjpparkour: "judge/CjpParkour",
    dificultad_1: "judge/dificultad_1",
    linea_1: "judge/linea_1",
    linea_2: "judge/linea_2",
  },
};

const sequencia = ["1", "2", "3", "4", "5", "6", "7"];
// eslint-disable-next-line array-callback-return
sequencia.map((n) => {
  puestos.Aerobica[`artística_${n}`] = `judge/artística_${n}`;
  puestos.Aerobica[`ejecución_${n}`] = `judge/ejecución_${n}`;
  puestos.Artistica[`ejecución_${n}`] = `judge/ejecución_${n}`;
  puestos.Ritmica[`ejecución_${n}`] = `judge/ejecución_${n}`;
  puestos.Ritmica[`artistica_${n}`] = `judge/artistica_${n}`;
  puestos.Parkour[`parkour_${n}`] = `judge/parkour${n}`;
  puestos.Parkour[`ejecución_${n}`] = `judge/ejecución_${n}`;
});

// console.log(puestos)
export default function App() {
  const { state, URLdomain, sendState } = useContext(globalContext);
  const {
    online,
    contenido,
    enlaces,
    asignations,
    competence,
    messages,
    user,
    nav,
  } = state;
  const [likns, setlikns] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    if (asignations && competence) {
      let urls = [];
      
      // Mapear asignaciones con verificación de existencia
      asignations.forEach((a) => {
        const modalidad = competence.modalidad;
        
        // Verificar si la modalidad existe en puestos
        if (puestos[modalidad] && puestos[modalidad][a.puesto]) {
          urls.push({
            name: a.puesto,
            url: puestos[modalidad][a.puesto],
            panel: a.panel || '1' // Valor por defecto si panel es undefined
          });
        }
      });
  
      // Agregar URLs adicionales
      urls = urls.concat([
        { name: "Competencia", url: "" },
        { name: "Chroma", url: "chroma?panel=" },
        { name: "Individual", url: "individual?panel=" },
        { name: "Ranking", url: "ranking?chroma=black&panel=" },
        { name: "Ranking-Chroma", url: "ranking?chroma=green&panel=" },
        { name: "Team", url: "team?chroma=black&categoria=SENIOR" },
        { name: "Team-Chroma", url: "team?chroma=green&categoria=SENIOR" },
        { name: "All", url: "all?chroma=black&categoria=SENIOR" },
        { name: "All-Chroma", url: "all?chroma=green&categoria=SENIOR" },
        { name: "Reposo", url: "reposo?panel=" },
        { name: "MedalleriaAA", url: "MedalleriaAA?chroma=black&panel=" },
      ]);
  
      setlikns(
        urls.map((u, index) => (
          <Link
            key={`${u.name}${index}`}
            to={`/${state.URLdomain}/${competence.modalidad}/${competence.id}/${u.url}${
              u.panel ? `/${u.panel}` : ""
            }`}
            onClick={() => sendState({ nav: false })}
            children={u.name.toLocaleUpperCase() + (u.panel ? ` - P${u.panel}` : "")}
            className="nav-link"
          />
        ))
      );
    } else {
      setlikns([]);
    }
  }, [asignations, competence, state.URLdomain, sendState]);
  const deleteMessage = (id) => {
    sendState({ messages: messages.filter((m) => m.id !== id) });
  };
  const date = new Date();
  return (
    <>
      {!online ? (
        <span
          className="offline"
          children={
            <>
              <div />
              {"Offline"}
            </>
          }
        />
      ) : (
        ""
      )}
      <Navbar
        className="noPrint notranslate"
        variant="dark"
        expand={false}
        style={{ background: contenido ? contenido.color : null }}
      >
        <Container fluid="lg" className="notranslate">
          <Link to={`/${state.URLdomain}`}>
            {contenido ? (
              <img
                style={{ maxHeight: "40px" }}
                src={`https://consugi.consugisoft.com/media/${contenido.logo}`}
                alt={"logo"}
              />
            ) : null}
          </Link>
          {user ? (
            <span className="text-white" children={user.toUpperCase()} />
          ) : (
            ""
          )}
          <Navbar.Toggle aria-controls="offcanvasNavbar" />
          <Navbar.Offcanvas
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvasNavbarLabel">
                {/* (!document.fullscreenElement?(document.documentElement.requestFullscreen()):document.exitFullscreen()); */}
                <span
                  children={"🏠 Home"}
                  className="pointer"
                  onClick={() => {
                    navigate(`/`);
                    sendState({ nav: false });
                  }}
                />
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                {online ? (
                  !user ? (
                    <Link
                      to={`/${
                        state.URLdomain
                      }/login${`?next=${window.location.pathname}`}`}
                      children="Login"
                      key={`Login`}
                      className="nav-link"
                    />
                  ) : (
                    <Link
                      to={`/${
                        state.URLdomain
                      }/logout${`?next=${window.location.pathname}`}`}
                      children="Logout"
                      key={`Logout`}
                      className="nav-link"
                    />
                  )
                ) : null}
                <hr />
                {likns}
                {likns.length ? <hr /> : null}
                {enlaces
                  ? enlaces.map((e) => (
                      <a
                        key={`${e.titulo}${e.id}`}
                        className="nav-link"
                        href={e.url}
                        children={e.titulo}
                        target="_blank"
                        rel="noreferrer"
                      />
                    ))
                  : null}
                <hr />
                <a
                  href="https://consugi.com/"
                  children={`© CONSUGI ${date.getFullYear()}`}
                  key={`consugi`}
                  className="nav-link text-center"
                  target="_blank"
                  rel="noreferrer"
                />
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
      <Container fluid="lg" className="notranslate" children={<Rutas />} />
      <ToastContainer className="p-3 zi1056" position="top-center">
        {messages.slice(0, 2).map((m, index) => (
          <Toast
            bg={m.bg}
            className="noPrint"
            key={`${m.title}${index}`}
            autohide={true}
            delay={m.delay}
            onClose={() => deleteMessage(m.id)}
          >
            <Toast.Header closeButton={true}>
              <strong className="me-auto">{m.title}</strong>
              {/* <small>11 mins ago</small> */}
            </Toast.Header>
            <Toast.Body>{m.message}</Toast.Body>
          </Toast>
        ))}
      </ToastContainer>
    </>
  );
}
