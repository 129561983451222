import { Aerobica } from "./items/Aerobica";
import { Artistica } from "./items/Artistica";
import { Trampolin } from "./items/Trampolin";

const items ={
  Aerobica: Aerobica,
  Artistica: Artistica,
  Ritmica:{

  },
  Trampolin:Trampolin,
};

export {items};