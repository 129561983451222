// ejecución_final Aerobica, Artistica y Ritmica
const convenciones = {
  Aerobica: {
    total: 'total',
    categoria_edad: 'categoria_edad',
    siglas: 'representacion__siglas',
    representacion: 'representacion',
    bandera: 'representacion__bandera',
    aparato: 'categoria_de_participacion',
    dificultad: 'dificultad_final',
    artística_final: 'artística_final',
    ejecución_final: 'ejecución_final',
    neutrales: 'deducciones_totales',
    total_team: 'total_team',
    total_general:'total'
  },
  Artistica: {
    total: 'nota_final',
    categoria_edad: 'categoria',
    siglas: 'equipo__siglas',
    representacion: 'equipo_id',
    bandera: 'equipo__bandera',
    aparato: 'aparato',
    artística_final: 'artística_final',//no existe
    ejecución_final: 'ejecución_final',
    dificultad: 'dificultad',
    neutrales: 'neutrales',
    total_team: 'total_equipo',
    total_general: 'total_general',
  },
  Ritmica: {
    total: 'nota_final',
    categoria_edad: 'categoria_edad',
    siglas: 'representacion__siglas',
    representacion: 'representacion',
    bandera: 'representacion__bandera',
    aparato: 'aparato',
    dificultad: 'dificultad_final',
    ejecución_final: 'ejecución_final',
    artística_final: 'artistica_final',
    neutrales: 'total_faltas',
    total_team: 'total_team',
    total_general: 'total_general',
  },
  Trampolin: {
    total: 'total_general',
    categoria_edad: 'categoria_edad',
    siglas: 'representacion__siglas',
    representacion: 'representacion',
    bandera: 'representacion__bandera',
    aparato: 'aparato',
    dificultad: 'dificultad',
    ejecución_final: 'total_ejecucion',
    artística_final: 'artistica_final',
    neutrales: 'penalizacion',
    total_team: 'total_team',
    total_general: 'total_global',//ejercicio
  },
  Parkour: {
    total: 'total',
    categoria_edad: 'categoria_edad',
    siglas: 'representacion__siglas',
    representacion: 'representacion',
    bandera: 'representacion__bandera',
    aparato: 'categoria_de_participacion',
    dificultad: 'dificultad_final',
    artística_final: 'deduccion_final',
    ejecución_final: 'ejecución_final',
    neutrales: 'deducciones_totales',
    total_team: 'total_team',
    total_general:'total'
  },
}
export {convenciones};