import React, { useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import globalContext from '../../context/globalContext';

export default function Logout() {
  const { state, sendServer, sendState } = useContext(globalContext);
  const { user, online } = state;
  const navigate = useNavigate();
  let { organizacion } = useParams();
  useEffect(() => {
    sendState({nav:false})
    let urlParams = new URLSearchParams(window.location.search);
    if ((user === null && urlParams.get('next'))||!online) {
      navigate(urlParams.get('next'))
    } else if (user === null) {
      navigate('/')
    }else if (user !== undefined && user !== null) {
      sendServer({domain: organizacion, app: 'jueces', action: 'logout', data: {} });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, online])
  return (
    <div>Logout</div>
  )
}