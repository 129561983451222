import { Aerobica } from "./items/Aerobica";
import { Artistica } from "./items/Artistica";
import { Trampolin } from "./items/Trampolin";
import { Ritmica } from "./items/Ritmica";
const items ={
  Aerobica: Aerobica,
  Artistica: Artistica,
  Ritmica: Ritmica,
  Trampolin:Trampolin,
};

export {items};