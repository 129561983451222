import React, { useContext, useEffect, useState } from "react";
import globalContext from "../../../context/globalContext";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Header } from "./Header";
import { items } from "./ItemsHeader";
import { Format } from "./Format";
import { useParams } from "react-router-dom";

const clasifican = 8;
const reservas = 2;
const finalesdirectas = ["ALL AROUND M", "ALL AROUND F", "TEAM M", "TEAM F"];

const Table = (props) => {
  const { state, URLdomain } = useContext(globalContext);
  const { organizacion } = useParams();
  const urlParams = useParams();
  const [localQuery, setlocalQuery] = useState([]);
  const { competence } = state;
  const { query, modalidad, params, titulo } = props;
  const [concurso, setconsurso] = useState(params.concurso);

  const aparatosOrden = ["MANOS", "CUERDA", "ARO", "BALON", "MAZAS", "CINTA"];
  const [aparatos, setAparatos] = useState([]);
  const [aparatoImg, setAparatoImg] = useState({});

  console.log("Props recibidos:", props);
  console.log("Estado global:", state);
  console.log("URLdomain:", URLdomain);
  console.log("organizacion (params):", organizacion);

  // Efecto para ordenar los aparatos y obtener sus imágenes
  useEffect(() => {
    // Si query no es un array o está vacío, no hacer nada
    if (!Array.isArray(query) || query.length === 0) {
      setAparatos([]); // Opcional: Limpiar el estado si no hay datos
      setAparatoImg({});
      return;
    }
  
    let newAparatos = new Set();
    let images = {};
    query.forEach((e) => {
      if (e.aparato) { // Verifica que cada elemento tenga `aparato`
        newAparatos.add(e.aparato);
        images[e.aparato] = e.aparato__icono || null; // Maneja casos donde `aparato__icono` no exista
      }
    });
    
    const orderedAparatos = Array.from(newAparatos).sort((a, b) => {
      return aparatosOrden.indexOf(a) - aparatosOrden.indexOf(b);
    });
    
    setAparatos(orderedAparatos);
    setAparatoImg(images);
  }, [query]);

  // Efecto para establecer el concurso
  useEffect(() => {
    if (urlParams.modalidad === "Artistica" && query.length) {
      setconsurso(query[0].concurso);
    } else if (
      (urlParams.modalidad === "Aerobica" ||
        urlParams.modalidad === "Ritmica" ||
        urlParams.modalidad === "Parkour") &&
      query.length &&
      query[0].final === true
    ) {
      setconsurso("III");
    } else {
      setconsurso("I");
    }
  }, [query, urlParams.modalidad]);

  // Efecto para procesar y ordenar la query
  useEffect(() => {
    let clasificados = {};
    let count = 0;
    let newQuery = [];

    if (Format[urlParams.modalidad] && Format[urlParams.modalidad][modalidad]) {
      if (
        modalidad === "ALL AROUND M" ||
        modalidad === "ALL AROUND F" ||
        modalidad === "TEAM M" ||
        modalidad === "TEAM F"
      ) {
        let calculo_aa_team = competence.calculo_aa_team;
        setlocalQuery(
          Format[urlParams.modalidad][modalidad](query, false, calculo_aa_team)
        );
      } else {
        setlocalQuery(Format[urlParams.modalidad][modalidad](query));
      }
    } else {
      query.forEach((e, index) => {
        if (!e.dns && !e.dsq && !e.dnf) {
          if (count < reservas + clasifican) {
            if (
              !clasificados.hasOwnProperty(
                competence.modalidad === "Aerobica"
                  ? e.representacion
                  : e.equipo
              )
            ) {
              clasificados[
                competence.modalidad === "Aerobica"
                  ? e.representacion
                  : e.equipo
              ] = 1;
              e = { ...e, q: count < clasifican ? "Q" : "R" };
              count++;
            } else if (
              clasificados[
                competence.modalidad === "Aerobica"
                  ? e.representacion
                  : e.equipo
              ] < 2
            ) {
              clasificados[
                competence.modalidad === "Aerobica"
                  ? e.representacion
                  : e.equipo
              ]++;
              e = { ...e, q: count < clasifican ? "Q" : "R" };
              count++;
            }
          }
        }
        if (competence.modalidad === "Ritmica") e = { ...e, puesto: index + 1 };
        newQuery.push(e);
      });

      // Lista de modalidades donde el menor tiempo es mejor
      const speedrunModalidades = ["Speedrun_(M)", "Speedrun_(W)"];

      // Ordenar los resultados
      const sorted = newQuery.sort((a, b) => {
        const aDNS = a.dns || false;
        const bDNS = b.dns || false;
        const aDSQ = a.dsq || false;
        const bDSQ = b.dsq || false;
        const aDNF = a.dnf || false;
        const bDNF = b.dnf || false;

        // Si alguno tiene DNS, DSQ o DNF, se coloca al final
        if ((aDNS || aDSQ || aDNF) && !(bDNS || bDSQ || bDNF)) return 1;
        if (!(aDNS || aDSQ || aDNF) && (bDNS || bDSQ || bDNF)) return -1;
        if ((aDNS || aDSQ || aDNF) && (bDNS || bDSQ || bDNF)) return 0;

        // Si la modalidad es Speedrun, ordenar de menor a mayor
        if (speedrunModalidades.includes(modalidad)) {
          return a.total - b.total; // Menor tiempo primero
        }

        // Para el resto de las modalidades, ordenar de mayor a menor
        return b.total - a.total;
      });

      let orderNumber = 1;
      const sortedWithNumbers = sorted.map((gimnasta) => ({
        ...gimnasta,
        puesto:
          gimnasta.dns || gimnasta.dsq || gimnasta.dnf ? null : orderNumber++,
      }));

      setlocalQuery(sortedWithNumbers);
    }
  }, [query, competence, modalidad, urlParams.modalidad]);

  // Función para formatear valores
  const formatValue = (value, toFixed) => {
    // Primero verifica si es DNS o DNF
    if (value === "DNS" || value === "DNF") {
      return value;
    }
    
    // Luego verifica si es null/undefined
    if (value === null || value === undefined) {
      return "";
    }
    
    // Finalmente, si es número aplica toFixed
    if (typeof value === "number" && toFixed) {
      return value.toFixed(toFixed);
    }
    
    // Para cualquier otro caso, devuelve el valor como string
    return value?.toString() || "";
  };
  
  // Determinar si se debe mostrar la imagen en el encabezado
  const sinImageHeader = [
    "ALL AROUND",
    "ALL AROUND F",
    "ALL AROUND M",
    "TEAM F",
    "TEAM M",
    "orden_de_paso",
    "calendario",
    "medallistas",
    "clasificacion_medallas",
    "multi_medallistas",
    "lista_de_jueces",
    "asignacion_de_paneles",
  ];

  // Determinar si se debe mostrar la categoría Q
  const sinCategoriaQ = [
    "calendario",
    "medallistas",
    "clasificacion_medallas",
    "multi_medallistas",
    "lista_de_jueces",
    "asignacion_de_paneles",
  ];

  // Determinar si se debe usar el estilo "striped"
  const noStriped = ["asignacion_de_paneles"];

  // Determinar el título de la modalidad
  const itemMod = !titulo
    ? modalidad
    : modalidad === "PRIMER SALTO"
    ? "Individual Judges Scores Salto"
    : titulo;

  // Si no hay competencia o la organización no coincide, no renderizar
  if (!competence || organizacion !== state.URLdomain) return null;

  return (
    <table
      className={
        "centered table newTable " +
        (noStriped.indexOf(modalidad) === -1 ? "striped" : "noborderButton")
      }
    >
      <thead className="upper">
        <tr>
          <th colSpan={40}>
            <Row>
              {competence && (
                <>
                  <Col className="encabezado hide_md_down imgHeader">
                    <img
                      alt="org"
                      src={`https://consugi.consugisoft.com/media/${competence.organizadores}`}
                    />
                  </Col>
                  <Col className="encabezado" xs={12} sm={7}>
                    <p className="text-center m0">
                      {competence.nombre_de_la_competencia}
                    </p>
                    <p className="text-center m0">
                      <a
                        href={`${competence.ubicacion}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {competence.lugar}
                      </a>
                      <span>
                        {" "}
                        Del {competence.inicio} al {competence.fin}
                      </span>
                    </p>
                    <p className="text-center m0 bold">
                      {sinCategoriaQ.indexOf(modalidad) === -1 &&
                      localQuery.length
                        ? `${
                            concurso !== "I" ||
                            modalidad === "team" ||
                            finalesdirectas.indexOf(modalidad) > -1
                              ? "FINAL"
                              : "CLASIFICACION"
                          } - ${
                            competence.modalidad === "Aerobica" ||
                            competence.modalidad === "Ritmica" ||
                            competence.modalidad === "Parkour"
                              ? localQuery[0].categoria_edad
                              : localQuery[0].categoria
                          } - ${modalidad ? modalidad.replace(/_/g, " ") : ""}`
                        : modalidad
                        ? modalidad.replace(/_/g, " ")
                        : ""}
                      {titulo && (
                        <>
                          <br />
                          {titulo}
                        </>
                      )}
                    </p>
                  </Col>
                  <Col className="encabezado hide_md_down imgHeader">
                    <img
                      alt="log"
                      src={`https://consugi.consugisoft.com/media/${competence.logo}`}
                    />
                  </Col>
                </>
              )}
            </Row>
            {sinImageHeader.indexOf(modalidad) === -1 && <hr color={"grey"} />}
            {competence.modalidad === "Ritmica" && localQuery.length > 0 ? (
              <Row
                className={
                  sinImageHeader.indexOf(modalidad) !== -1 ? "" : "modalidad"
                }
                style={{
                  backgroundImage: `url('https://consugi.consugisoft.com/static/media/${localQuery[0].aparato__icono}')`,
                }}
              />
            ) : (
              <Row
                className={
                  sinImageHeader.indexOf(modalidad) !== -1 ? "" : "modalidad"
                }
                style={{
                  backgroundImage: `url('${URLdomain}/static/recursos/${competence.modalidad}/${modalidad}.png')`,
                }}
              />
            )}
          </th>
        </tr>
        <Header
          modalidad={`${titulo ? titulo : modalidad}`}
          final={concurso !== "I"}
          query={query}
          aparatos={aparatos}
          aparatoImg={aparatoImg}
          key={`header-${modalidad}`}
        />
      </thead>
      <tbody className="resultadosCont">
  {localQuery.map((q, index) => {
    const rowKey = q.id ? `${q.id}-${index}` : `row-${index}`;
    const isDns = q.dns;
    const isDnf = q.dnf;

    return (
      <tr 
        key={rowKey} 
        className={`${q.class} ${isDns ? 'dns-row' : ''} ${isDnf ? 'dnf-row' : ''}`}
      >
        {competence.modalidad === "Ritmica" && modalidad === "ALL AROUND" ? (
          <>
            {items[competence.modalidad]["all"].map((i, idx) => {
              if ((concurso !== "I" && i.item === "q") || q[i.item] === "blank") {
                return null;
              }

              const cellKey = q.id ? `${q.id}-${i.item}-${idx}` : `cell-${idx}`;
              
              return (
                <td
                  key={cellKey}
                  rowSpan={q.rowSpan && q.rowSpan[i.item] ? q.rowSpan[i.item] : null}
                  className={i.clases}
                >
                  {i.format ? i.format(q[i.item]) : 
                   i.toFixed ? (isDns ? 'DNS' : isDnf ? 'DNF' : formatValue(q[i.item], i.toFixed)) : 
                   i.item !== "representacion__bandera" && i.item !== "equipo__bandera" ? q[i.item] : 
                   q[i.item] ? (
                    <div
                      className="miniBandera"
                      style={{
                        backgroundImage: `url('https://consugi.consugisoft.com/media/${q[i.item]}')`,
                      }}
                    />
                  ) : "\n"}
                </td>
              );
            })}

            {aparatos.map((aparato, idx) => {
              const aparatoKey = q.id ? `${q.id}-${aparato}-${idx}` : `aparato-${idx}`;
              
              // Manejo especial para DNS/DNF en aparatos
              if (isDns) {
                return <td key={aparatoKey} className="dns-cell">DNS</td>;
              }
              if (isDnf) {
                return <td key={aparatoKey} className="dnf-cell">DNF</td>;
              }
              
              const aparatoValue = q[aparato];
              return (
                <td key={aparatoKey}>
                  {typeof aparatoValue === 'number' ? aparatoValue.toFixed(3) : aparatoValue}
                </td>
              );
            })}

            <td className="text-end w80">
              {isDns ? (
                <span className="dns-cell">DNS</span>
              ) : isDnf ? (
                <span className="dnf-cell">DNF</span>
              ) : (
                formatValue(q.total_general, 3)
              )}
            </td>
          </>
        ) : (
          items[competence.modalidad][
            items[competence.modalidad].hasOwnProperty(itemMod) ? itemMod : "generic"
          ].map((i, idx) => {
            if ((concurso !== "I" && i.item === "q") || q[i.item] === "blank") {
              return null;
            }

            const cellKey = q.id ? `${q.id}-${i.item}-${idx}` : `cell-${idx}`;
            
            return (
              <td
                key={cellKey}
                rowSpan={q.rowSpan && q.rowSpan[i.item] ? q.rowSpan[i.item] : null}
                className={i.clases}
              >
                {i.format ? i.format(q[i.item]) : 
                 i.toFixed ? (isDns ? 'DNS' : isDnf ? 'DNF' : formatValue(q[i.item], i.toFixed)) : 
                 i.item !== "representacion__bandera" && i.item !== "equipo__bandera" ? q[i.item] : 
                 q[i.item] ? (
                  <div
                    className="miniBandera"
                    style={{
                      backgroundImage: `url('https://consugi.consugisoft.com/media/${q[i.item]}')`,
                    }}
                  />
                ) : "\n"}
              </td>
            );
          })
        )}
      </tr>
    );
  })}
  {localQuery.length === 0 && (
    <tr>
      <td colSpan={40} className="text-center">
        ...Sin resultados, consulta con sistemas para mas información.
      </td>
    </tr>
  )}
</tbody>
      {competence.patrocinadores && localQuery.length > 4 && (
        <tfoot>
          <tr>
            <td colSpan={40} className="noPadding">
              <div
                className="encabezado"
                style={{
                  backgroundImage: `url('https://consugi.consugisoft.com/media/${competence.patrocinadores}')`,
                }}
              />
            </td>
          </tr>
        </tfoot>
      )}
    </table>
  );
};

export { Table, clasifican, reservas };
