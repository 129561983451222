import React, { useContext } from 'react';
import Form from 'react-bootstrap/Form';
import globalContext from '../../../context/globalContext';

export const Filters = props => {
  const { state } = useContext(globalContext);
  const {competence, filtros, user} = state;
  const {categorias, modalidades, subdivisiones} = filtros;
  const {params, onChange} = props;
  return (
    <div className="row noPrint marginAuto">
      {competence&&competence.modalidad==='Artistica'&&competence.niveles_usag?
        <div className="col-12 text-center subdivision filter"> 
          <h6 className="text-center">Grupo</h6>
          <Form.Select className='text-center params' name='subdivision' value={params.subdivision} onChange={onChange} children={
            <>
            {/* <option value="">Select</option> */}
            {params.subdivision&&subdivisiones?
              subdivisiones.map(c=><option key={c} value={c}>Grupo {c}</option>)
            :null}
            </>
          }/>
        </div>
      :null}
        <div className="col-6 text-end filter">
          <h6 className="text-end">Visualizar</h6>
          <Form.Select className='params' name='ver' value={params.ver} onChange={onChange} children={
            <>
              <option value="resultados">Resultados</option>
              <option value="calendario">Calendario</option>
              <option value="lista_equipos">Lista de equipos</option>
              <option value="medallistas">Medallistas</option>
              <option value="clasificacion_medallas">Clasificación medallas</option>
              <option value="multi_medallistas">Multi - Medallistas</option>
              <option value="orden_de_paso">Orden de paso</option>
              <option value='getPaneles' children='Paneles'/>
              {user?
                <>
                  <option value="media_book">Media Book</option>
                  <option value="delegation_book">Delegation Book</option>
                  <option value="publicarCategorias">publicarCategorias</option>
                </>
              :''}
            </>
          }/>
        </div>
        {params.categoria?
          <div className="col-6 filter">
            <h6>Categoria</h6>
              <Form.Select className='params' name='categoria' value={params.categoria} onChange={onChange} children={
                <>
                {params.categoria&&categorias?
                  categorias.map(c=><option key={c} value={c}>{c}</option>)
                :null}
                </>
              }/>
          </div>
         :null}
        {params.concurso? 
          <div className="col-6 text-end filter">
            <h6>Concurso</h6>
            <Form.Select className='params' name='concurso' value={params.concurso} onChange={onChange} children={
              <>
              <option value="I">Clasificación</option>
              {competence&&(competence.modalidad==='Artistica'||competence.modalidad==='Ritmica')?<option value="II">All Around</option>:null}
              <option value="III">Finales</option>
              <option value="IV">Team</option>
              </>
            }/>
          </div>
        :null}
        {params.modalidad? 
          <div className="col-6 filter">
            <h6>Modalidad / Aparato</h6>
              <Form.Select className='params' name='modalidad' value={params.modalidad} onChange={onChange} children={
                <>
                {params.modalidad&&modalidades?
                  modalidades.map(c=><option key={c} value={c}>{c}</option>)
                :null}
                </>
              }/>
          </div>
        :null}
      <hr/>
    </div>
  )
}
