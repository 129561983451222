import React, { useCallback, useContext, useEffect, useState } from 'react';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { useParams } from 'react-router-dom';
import globalContext from '../../../context/globalContext';
import { promedioART } from '../Functions';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import "../../../App.css";
import styles from "../styles/judgment.module.css";

const lineone = [
  { name: 'ejecución_1', show: 'E1' },
  { name: 'ejecución_2', show: 'E2' },
  { name: 'ejecución_3', show: 'E3' },
  { name: 'ejecución_4', show: 'E4' },
];
const linetwo = [
  { name: 'ejecución_5', show: 'E5' },
  { name: 'ejecución_6', show: 'E6' },
  { name: 'ejecución_7', show: 'E7' },
];
const linethree = [
  { name: 'dificultad', show: 'D' },
  { name: 'bonus', show: 'Bonus' },
];

const caracteres = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '.', '0'];

export default function Dificultad() {
  const initialNotas = {
    ejecución_final: 0,
    nota_final: 0,
  };
  const [notas, setnotas] = useState(initialNotas);
  const [puesto, setpuesto] = useState('');
  const [inscripcion, setInscripcion] = useState(null);
  const [del, setdel] = useState(false);
  const { state, sendServer, sendState } = useContext(globalContext);
  const { online, competence, current, success, orden_de_paso, authorized } = state;
  const {
    id,
    ejecución_1, ejecución_2, ejecución_3, ejecución_4,
    ejecución_5, ejecución_6, ejecución_7,
    dificultad, línea_1, línea_2, bonus, cronómetro, deducciones,
  } = current;
  let { competencia_id, modalidad, panel, organizacion } = useParams();
  const [control, setcontrol] = useState({ anterior: null, siguiente: null });
  const navigate = useNavigate();

  const initialFilter = { modalidad: '', edad: '', gimnasta: '', concurso: '', rotacion: '' };
  const [filter, setfilter] = useState(initialFilter);

  useEffect(() => {
    if (organizacion !== state.URLdomain) {
      sendState({ URLdomain: organizacion });
    } else {
      sendServer({ domain: organizacion, app: 'global', action: 'getAsignations', id: competencia_id, rotacion: filter.rotacion });
    }
  }, [filter.rotacion]);

  useEffect(() => {
    if (authorized === false) {
      navigate(`/${organizacion}/${modalidad}/${competencia_id}`);
    } else if (authorized === undefined && organizacion === state.URLdomain) {
      sendServer({
        domain: organizacion,
        app: modalidad,
        action: 'authorized',
        puesto: 'dificultad',
        panel: panel,
        competencia_id: competencia_id,
      });
      sendServer({ domain: organizacion, app: 'global', action: 'getAsignations', id: competencia_id });
    }
  }, [authorized, state.URLdomain]);

  const [orden_de_paso_local, setorden_de_paso_local] = useState([]);
  const [edades, setedades] = useState([]);
  const [modalidades, setmodalidades] = useState([]);

  useEffect(() => {
    if (!edades.length && orden_de_paso) {
      let newEdades = [];
      let modalidades = [];
      orden_de_paso.map(o => {
        if (!newEdades.includes(o.categoria)) newEdades.push(o.categoria);
        if (!modalidades.includes(o.aparato)) modalidades.push(o.aparato);
      });
      setedades(newEdades);
      setmodalidades(modalidades);
    }
    if (orden_de_paso) {
      setorden_de_paso_local(
        orden_de_paso.filter(o =>
          (
            (filter.modalidad === '' || filter.modalidad === o.aparato) &&
            (filter.edad === '' || filter.edad === o.categoria) &&
            (filter.concurso === '' || ((filter.concurso === 'final' && o.concurso === 'III') || (filter.concurso === 'clasificación' && o.concurso === 'I'))) &&
            (filter.gimnasta === '' || o.participante.toLowerCase().includes(filter.gimnasta.toLowerCase())) &&
            (filter.rotacion === '' || Number(filter.rotacion) === o.rotacion)
        )
      ));
    }
  }, [orden_de_paso, filter]);

  useEffect(() => {
    sendServer({
      domain: organizacion,
      app: modalidad,
      action: 'getCurrent',
      panel: panel,
      competencia_id: competencia_id,
    });
    let urlParams = new URLSearchParams(window.location.search);
    let newFilter = filter;
    for (let f in filter) {
      if (urlParams.get(f)) {
        newFilter = { ...newFilter, [f]: urlParams.get(f) };
      }
    }
    setfilter(newFilter);
  }, []);

  useEffect(() => {
    if (orden_de_paso_local) {
      let anterior, actual, siguiente;
      orden_de_paso_local.map((gimnasta) => {
        if (id === gimnasta.id) {
          actual = gimnasta.id;
        } else if (!actual) {
          anterior = gimnasta.id;
        } else if (actual && !siguiente) {
          siguiente = gimnasta.id;
        }
      });
      setcontrol({ anterior: anterior, siguiente: siguiente });
    }
  }, [id, orden_de_paso_local]);

  useEffect(() => {
    if (online && !competence && organizacion === state.URLdomain) sendServer({ domain: organizacion, app: 'global', action: 'getCompetence', id: competencia_id });
  }, [online, competence, competencia_id, state.URLdomain]);

  useEffect(() => {
    if (organizacion === state.URLdomain && !current) sendServer({
      domain: organizacion,
      app: modalidad,
      action: !inscripcion ? 'getCurrent' : 'getInscripcion',
      id: inscripcion,
      getOrdenDePaso: true,
      panel: panel,
      competencia_id: competencia_id,
    });
    document.title = `DIF | ${current.participante}`;
  }, [inscripcion, id, state.URLdomain]);

  useEffect(() => {
    if (inscripcion && id !== inscripcion) sendServer({
      domain: organizacion,
      app: modalidad,
      action: !inscripcion ? 'getCurrent' : 'getInscripcion',
      id: inscripcion,
      getOrdenDePaso: true,
      panel: panel,
      competencia_id: competencia_id,
    });
    document.title = `DIF | ${current.participante}`;
  }, [inscripcion, id, state.URLdomain]);

  useEffect(() => {
    let interval = setInterval(() => {
      if (organizacion === state.URLdomain) {
        sendServer({
          domain: organizacion,
          app: modalidad,
          action: !inscripcion ? 'getCurrent' : 'getInscripcion',
          id: inscripcion,
          panel: panel,
          competencia_id: competencia_id,
        });
      }
    }, 3000);
    return () => clearInterval(interval);
  }, [competence, competencia_id, sendServer]);

  useEffect(() => {
    if (competence) sendServer({
      domain: organizacion,
      app: modalidad,
      action: 'getOrdenDePaso',
      competencia_id: competencia_id,
      panel: panel,
    });
  }, [competence]);

  useEffect(() => {
    if (current) {
      let ejecución_final = 0;
      if (ejecución_1 !== null || ejecución_2 !== null || ejecución_3 !== null || ejecución_4 !== null || ejecución_5 !== null || ejecución_6 !== null || ejecución_7 !== null) {
        let ejecución_promedia = promedioART([ejecución_1, ejecución_2, ejecución_3, ejecución_4, ejecución_5, ejecución_6, ejecución_7]);
        ejecución_final = competence.niveles_usag ? ejecución_promedia : 10 - ejecución_promedia;
        ejecución_final = parseFloat(ejecución_final.toFixed(3));
      }
      let nota_final = 0;
      let newNeutrales = 0;
      if (competence.niveles_usag && dificultad) {
        nota_final = dificultad - ejecución_final;
      } else {
        [dificultad, ejecución_final].forEach(l => { if (l) nota_final += l });
      }
      if (bonus) {
        nota_final += bonus; // Aquí se suma el bonus al total
      }
      let línea_total = 0;
      [línea_1, línea_2].forEach(l => { if (l) línea_total += l });
      [línea_total, cronómetro, deducciones].forEach(s => { if (s) { newNeutrales += s; nota_final -= s } });
      if (nota_final < 0) nota_final = 0;
      setnotas({
        ejecución_final: ejecución_final,
        línea_total: línea_total,
        neutrales: newNeutrales,
        nota_final: parseFloat(nota_final.toFixed(3)),
      });
    }
  }, [id, ejecución_1, ejecución_2, ejecución_3, ejecución_4, ejecución_5, ejecución_6, ejecución_7, dificultad, línea_1, línea_2, bonus, cronómetro, deducciones]);

  useEffect(() => {
    if (success) {
      setpuesto('');
      setUserText('');
      setdel(false);
      sendState({ success: false });
    }
  }, [success]);

  const [userText, setUserText] = useState("");
  const handleUserKeyPress = useCallback(event => {
    const { key, keyCode } = event;
    if (caracteres.includes(key)) {
      setUserText(prevUserText => {
        if (prevUserText.match(/[.]/) && key === '.') {
          console.log('No puedes poner dos puntos');
        } else if (prevUserText.length > 4) {
          console.log('maximo 5 caracteres');
        } else if (parseFloat(`${prevUserText}${key}`) > 50) {
          console.log('El valor maximo es 10');
        } else {
          return `${prevUserText}${key}`;
        }
        return `${prevUserText}`;
      });
    } else if (keyCode === 8) {
      setUserText(prevUserText => `${prevUserText.substr(0, prevUserText.length - 1)}`);
    } else if (keyCode === 46) {
      if (puesto !== "") {
        setUserText(``);
      } else {
        publicar(false);
      }
    } else if (keyCode === 188) {
      setUserText(prevUserText => {
        if (prevUserText.match(/[.]/)) return `${prevUserText}`;
        return `${prevUserText}.`;
      });
    } else if (keyCode === 13) {
      if (puesto !== "") {
        setUserText(prevUserText => {
          cargarNota(prevUserText);
          return `${prevUserText}`;
        });
      } else {
        publicar(true);
      }
    } else if (keyCode === 37 && control.anterior) {
      cambiarGimnasta(control.anterior);
    } else if (keyCode === 39 && control.siguiente) {
      cambiarGimnasta(control.siguiente);
    }
  }, [current, id, control]);

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  const cargarNota = (nota) => {
    if (nota === '') nota = null;
    sendServer({
      domain: organizacion,
      app: modalidad,
      action: 'cargarNota',
      puesto: 'dificultad',
      panel: panel,
      competencia_id: competencia_id,
      data: {
        id: current.id,
        competencia_id: current.competencia,
        [puesto]: nota,
        bonus: bonus, //temporal
        publicado: false,
      }
    });
  };

  const publicar = (publicado) => {
    sendServer({
      domain: organizacion,
      app: modalidad,
      action: 'cargarNota',
      puesto: 'dificultad',
      panel: panel,
      getOrdenDePaso: true,
      competencia_id: competencia_id,
      data: {
        ...notas,
        id: current.id,
        competencia_id: current.competencia,
        publicado: publicado,
      }
    });
  };

  const clearInscription = () => {
    sendServer({
      domain: organizacion,
      app: modalidad,
      action: 'cargarNota',
      puesto: 'dificultad',
      panel: panel,
      getOrdenDePaso: true,
      competencia_id: competencia_id,
      data: {
        id: current.id,
        publicado: false,
        ejecución_1: null,
        ejecución_2: null,
        ejecución_3: null,
        ejecución_4: null,
        ejecución_5: null,
        ejecución_6: null,
        ejecución_7: null,
        ejecución_final: null,
        dificultad: null,
        línea_1: null,
        línea_2: null,
        bonus: null,
        línea_total: null,
        deducciones: null,
        neutrales: null,
        nota_final: null,
      }
    });
  };

  const loadtype = puesto => {
    setpuesto(puesto);
    if (current[puesto]) {
      setUserText(current[puesto].toString());
    } else {
      setUserText('');
    }
  };

  const cambiarGimnasta = id => {
    if (inscripcion) setInscripcion(null);
    if (id) sendServer({
      domain: organizacion,
      app: modalidad,
      action: 'cambiarGimnasta',
      puesto: 'dificultad',
      panel: panel,
      getOrdenDePaso: false,
      competencia_id: competencia_id,
      id: id
    });
  };

  const onChange = e => {
    let newFilter = { ...filter, [e.target.name]: e.target.value };
    if (e.target.value === 'Limpiar') {
      newFilter = initialFilter;
    }
    setfilter(newFilter);
    let newUrl = '?';
    for (let f in newFilter) {
      if (newFilter[f] !== '') {
        newUrl += `${newUrl === '?' ? '' : '&'}${f}=${newFilter[f]}`;
      }
    }
    navigate(newUrl);
  };

  if (!current || !authorized) return null;

  return (
    <>
      <p className='noPrint' children={
        <>
          {current.id ?
            <>
              <img className='mini' src={`https://consugi.consugisoft.com/media/${current.equipo__bandera}`} alt='Bandera' />
              <span
                children={`(${current.equipo__nombre}) ${current.categoria} - ${current.aparato} - ${current.numero_gimnasta} ${current.participante}`}
              />
            </>
            : null}
        </>
      } />
      <Row className='marginAuto noPrint' children={
        <>
          {lineone.map(l => <Col onClick={() => loadtype(l.name)} key={l.name} className={`${l.red ? 'red' : 'blue'} enter`} children={`${l.show}\n${current[l.name] != null ? current[l.name].toFixed(2) : ''}`} />)}
        </>
      } />
      <Row className='marginAuto noPrint' children={
        <>
          {linetwo.map(l => <Col onClick={() => loadtype(l.name)} key={l.name} className={`${l.red ? 'red' : 'blue'} enter`} children={`${l.show}\n${current[l.name] != null ? current[l.name].toFixed(2) : ''}`} />)}
          <Col className={`totales ${current.ejecución_final === notas.ejecución_final ? styles.success : styles.warning}`} children={`ET\n${notas.ejecución_final.toFixed(3)}`} />
        </>
      } />
      <Row className='marginAuto noPrint' children={
        <>
          {linethree.map(l => <Col onClick={() => loadtype(l.name)} key={l.name} className={`${l.red ? 'red' : 'blue'} enter`} children={`${l.show}\n${current[l.name] != null ? current[l.name].toFixed(2) : ''}`} />)}
          <Col className='enter blue enterCont' children={
            <>
              <Col onClick={() => loadtype('bonus')} className='enter' children={`Bonus: ${current.bonus != null ? current.bonus.toFixed(1) : ''}`} />
            </>
          } />
          <Col className='enter red enterCont' children={
            <>
              <Col onClick={() => loadtype('deducciones')} className='enter' children={`O: ${current.deducciones != null ? current.deducciones.toFixed(1) : ''}`} />
              <Col onClick={() => loadtype('cronómetro')} className='enter' children={`T: ${current.cronómetro != null ? current.cronómetro.toFixed(1) : ''}`} />
            </>
          } />
          <Col className='enter red enterCont' children={
            <>
              <Col onClick={() => loadtype('línea_1')} className='enter' children={`L1: ${current.línea_1 != null ? current.línea_1.toFixed(1) : ''}`} />
              <Col onClick={() => loadtype('línea_2')} className='enter' children={`L2: ${current.línea_2 != null ? current.línea_2.toFixed(1) : ''}`} />
            </>
          } />
          <Col onClick={() => loadtype('neutrales_equipo')} className='enter red' children={`NE\n${current.neutrales_equipo != null ? current.neutrales_equipo.toFixed(2) : ''}`} />
          <Col className={`totales ${current.nota_final === notas.nota_final ? 'success' : 'warning'}`} children={`Total\n${notas.nota_final.toFixed(3)}`} />
        </>
      } />
      <Row className="controles marginAuto noPrint mt10" children={
        <>
          <Col onClick={() => cambiarGimnasta(control.anterior)} className={`${styles["btn-div"]} ${control.anterior ? styles["btn-primary"] : styles["btn-disabled"]}`} children={'◄'} />
          <Col onClick={() => cambiarGimnasta(control.siguiente)} className={`${styles["btn-div"]} ${control.siguiente ? styles["btn-primary"]: styles["btn-disabled"]}`} children={'►'} />
          <Col onClick={() => setdel(true)} className={styles["btn-div"] + " " + styles["btn-danger"]} children={'Del'} />
          <Col onClick={current.publicado ? () => publicar(false) : () => { }} className={`${styles["btn-div"]} ${current.publicado ? styles["btn-warning"] : styles["btn-disabled"]}`} children={'Desp'} />
          <Col onClick={!current.publicado ? () => publicar(true) : () => { }} className={`${styles["btn-div"]} ${current.publicado ? styles["btn-disabled"] : styles["success"]}`} children={'Enviar'} />
        </>
      } />
      <div className='tableContainer' children={
        <table className='mt30 w100 table table-striped' children={
          <>
            <thead children={
              <>
                <tr children={
                  <th colSpan={40} className='text-center' children={
                    <>
                      ORDEN DE PASO
                      <Row className="controles marginAuto noPrint mt30" children={
                        <>
                          <Col xs={6} sm={4} md={3} lg={2} className="mt10" children={
                            <>
                              <label children={'_'} />
                              <input className="w100" type='button' value='Limpiar' onClick={onChange} />
                            </>
                          } />
                          <Col xs={6} sm={4} md={3} lg={2} className="mt10" children={
                            <>
                              <label children={'Final/Clasificación'} />
                              <select className='w100' name='concurso' value={filter.concurso} onChange={onChange} children={
                                <>
                                  <option></option>
                                  <option value="clasificación">Clasificación</option>
                                  <option value="final">Final</option>
                                </>
                              } />
                            </>
                          } />
                          <Col xs={6} sm={4} md={3} lg={2} className="mt10" children={
                            <>
                              <label children={'Modalidad'} />
                              <select className='w100' name='modalidad' value={filter.modalidad} onChange={onChange} children={
                                <>
                                  <option></option>
                                  {modalidades.map(e => <option key={e} value={e}>{e}</option>)}
                                </>
                              } />
                            </>
                          } />
                          <Col xs={6} sm={4} md={3} lg={2} className="mt10" children={
                            <>
                              <label children={'Edad'} />
                              <select className='w100' name='edad' value={filter.edad} onChange={onChange} children={
                                <>
                                  <option></option>
                                  {edades.map(e => <option key={e} value={e}>{e}</option>)}
                                </>
                              } />
                            </>
                          } />
                          <Col xs={6} sm={4} md={3} lg={2} className="mt10" children={
                            <>
                              <label children={'Gimnasta'} />
                              <input className='w100' type='text' name='gimnasta' value={filter.gimnasta} onChange={onChange} />
                            </>
                          } />
                          <Col xs={6} sm={4} md={3} lg={2} className="mt10" children={
                            <>
                              <label children={'Rotacion'} />
                              <input className='w100' type='number' name='rotacion' value={filter.rotacion} onChange={onChange} />
                            </>
                          } />
                        </>
                      } />
                    </>
                  } />
                } />
                <tr children={
                  <>
                    <th children={'.'} className='text-end' />
                    <th children={'#'} className='text-end' />
                    <th children={'Gimnastas'} />
                    <th children={'Rep.'} />
                    <th children={'Mod.'} />
                    <th children={'Edad'} />
                    <th children={'Rot.'} />
                    <th children={'Total'} />
                  </>
                } />
              </>
            } />
            <tbody children={
              <>
                {orden_de_paso_local ?
                  orden_de_paso_local.map(i =>
                    <tr key={i.id} className={`click ${i.puntuando ? styles["btn-primary"] : ''}  ${i.id === id && !i.puntuando ? styles["btn-warning"] : ''}`} children={
                      <>
                        <td children={i.orden_de_salida} onClick={() => setInscripcion(i.id)} className='text-end' />
                        <td children={i.numero_gimnasta} onClick={() => setInscripcion(i.id)} className='text-end' />
                        <td onClick={() => cambiarGimnasta(i.id)} children={i.participante} />
                        <td onClick={() => cambiarGimnasta(i.id)} children={i.equipo} />
                        <td onClick={() => cambiarGimnasta(i.id)} children={i.aparato} />
                        <td onClick={() => cambiarGimnasta(i.id)} children={i.categoria} />
                        <td onClick={() => cambiarGimnasta(i.id)} children={i.rotacion} />
                        <td onClick={() => cambiarGimnasta(i.id)} children={i.nota_final !== null ? i.nota_final.toFixed(3) : ''} className={`text-end ${i.publicado ? styles.success : ''}`} />
                      </>
                    } />
                  )
                  : null}
              </>
            } />
          </>
        } />
      } />

      <Modal show={Boolean(puesto)} fullscreen={true} onHide={() => setpuesto('')}>
        <Modal.Header closeButton>
          <Modal.Title>
            <p className='noPrint' children={
              <>
                {current.id ?
                  <>
                    <img className='mini' src={`https://consugi.consugisoft.com/media/${current.equipo__bandera}`} alt='Bandera' />
                    <span
                      children={`(${current.equipo__nombre}) ${current.categoria} - ${current.aparato} - ${current.numero_gimnasta} ${current.participante}`}
                    />
                  </>
                  : null}
              </>
            } />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row marginAuto'>
            <div className='actionsContainer' children={
              <>
                <div className='text-center userTextCont' children={<b className='userText' children={userText} />} />
                <div className='text-center puesto' children={puesto.replace('_', ' ').replace('_total', ' ')} />
                <div className="btn-group col-12" role="group" aria-label="Basic mixed styles example">
                  {userText === '' ?
                    <button type="button" className="btn btn-danger" onClick={() => setpuesto('')}>Cancel</button>
                    : <button type="button" className="btn btn-danger" onClick={() => setUserText('')}>Del</button>}
                  <button type="button" className="btn btn-success" onClick={() => cargarNota(userText)}>Enviar</button>
                </div>
              </>
            } />
            <div className='tecladoContainer' children={
              <>
                {caracteres.map(n => <button type="button" key={n} onClick={() => handleUserKeyPress({ key: n })} className="btn btn-primary" children={<span children={n} />} />)}
                <button type="button" className="btn btn-primary" onClick={() => setUserText(userText.substr(0, userText.length - 1))} children={<span children={'◄'} />} />
              </>
            } />
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={del} onHide={() => setdel(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar Notas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='noPrint' children={
            <>
              {current.id ?
                <>
                  <img className='mini' src={`https://consugi.consugisoft.com/media/${current.equipo__bandera}`} alt='Bandera' />
                  <span
                    children={`(${current.equipo__nombre}) ${current.categoria} - ${current.aparato} - ${current.numero_gimnasta} ${current.participante}`}
                  />
                </>
                : null}
            </>
          } />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setdel(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={() => clearInscription()}>
            Eliminar notas
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}