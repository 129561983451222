import React, { useCallback, useContext, useEffect, useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { useParams } from "react-router-dom";
import globalContext from "../../../context/globalContext";
import { promedioAER, promedio } from "../Functions";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import "../../../App.css";
import styles from "../styles/judgment.module.css";

const lineone = [
  { name: "artística_1", show: "A1" },
  { name: "artística_2", show: "A2" },
  { name: "artística_3", show: "A3" },
  { name: "artística_4", show: "A4" },
];
const linetwo = [
  { name: "ejecución_1", show: "E1" },
  { name: "ejecución_2", show: "E2" },
  { name: "ejecución_3", show: "E3" },
  { name: "ejecución_4", show: "E4" },
];
const linethree = [
  { name: "dificultad_1", show: "DIF" },
  { name: "deducciones_dificultad_total", show: "D", red: true },
];

const caracteres = ["1", "2", "3", "4", "5", "6", "7", "8", "9", ".", "0"];

export default function Cjp() {
  const initialNotas = {
    artística_final: 0,
    ejecución_final: 0,
    linea_total: 0,
    total: 0,
  };
  const [notas, setnotas] = useState(initialNotas);
  const [puesto, setpuesto] = useState("");
  const [inscripcion, setInscripcion] = useState(null);
  const [del, setdel] = useState(false);
  const [showDNSModal, setShowDNSModal] = useState(false); // Estado para el modal DNS
  const [showDNFModal, setShowDNFModal] = useState(false);
  const { state, sendServer, URLdomain, sendState } = useContext(globalContext);
  const { online, competence, current, success, orden_de_paso, authorized } =
    state;
  const {
    id,
    artística_1,
    artística_2,
    artística_3,
    artística_4,
    ejecución_1,
    ejecución_2,
    ejecución_3,
    ejecución_4,
    dificultad_1,
    linea_1,
    linea_2,
    deducciones_CJP,
    deducciones_dificultad_total,
    division_d,
  } = current;
  let { competencia_id, modalidad } = useParams();
  const [control, setcontrol] = useState({ anterior: null, siguiente: null });
  const navigate = useNavigate();

  let { organizacion, panel } = useParams();

  useEffect(() => {
    if (organizacion !== state.URLdomain) {
      sendState({ URLdomain: organizacion });
    }
    sendServer({
      domain: organizacion,
      app: "global",
      action: "getAsignations",
      id: competencia_id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.URLdomain]);

  useEffect(() => {
    // Control de acceso!!!!!!!!!
    if (authorized === false) {
      navigate(`/${organizacion}/${modalidad}/${competencia_id}`);
    } else if (authorized === undefined) {
      sendServer({
        domain: organizacion,
        app: modalidad,
        action: "authorized",
        puesto: "cjp",
        panel: panel,
        competencia_id: competencia_id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorized, state.URLdomain]);

  // Navigate
  const [orden_de_paso_local, setorden_de_paso_local] = useState([]);

  const initialFilter = { modalidad: "", edad: "", gimnasta: "", concurso: "" };
  const [filter, setfilter] = useState(initialFilter);
  const [edades, setedades] = useState([]);
  useEffect(() => {
    // filtrado
    if (!edades.length && orden_de_paso) {
      let newEdades = [];
      // eslint-disable-next-line array-callback-return
      orden_de_paso.map((o) => {
        if (!newEdades.includes(o.categoria_edad))
          newEdades.push(o.categoria_edad);
      });
      setedades(newEdades);
    }
    if (orden_de_paso) {
      // && orden_de_paso.length
      setorden_de_paso_local(
        orden_de_paso.filter(
          (o) =>
            (filter.modalidad === "" ||
              filter.modalidad === o.categoria_de_participacion) &&
            (filter.edad === "" || filter.edad === o.categoria_edad) &&
            (filter.concurso === "" ||
              (filter.concurso === "final" && o.final) ||
              (filter.concurso === "clasificación" && !o.final)) &&
            (filter.gimnasta === "" ||
              o.participante
                .toLowerCase()
                .includes(filter.gimnasta.toLowerCase()))
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orden_de_paso, filter]);

  useEffect(() => {
    let urlParams = new URLSearchParams(window.location.search);
    let newFilter = filter;
    for (let f in filter) {
      if (urlParams.get(f)) {
        newFilter = { ...newFilter, [f]: urlParams.get(f) };
      }
    }
    setfilter(newFilter);
    sendServer({
      domain: organizacion,
      app: "global",
      action: "getAsignations",
      id: competencia_id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // End navigate

  useEffect(() => {
    // anterior - siguiente
    if (orden_de_paso_local) {
      let anterior, actual, siguiente;
      // eslint-disable-next-line array-callback-return
      orden_de_paso_local.map((gimnasta) => {
        if (id === gimnasta.id) {
          actual = gimnasta.id;
        } else if (!actual) {
          anterior = gimnasta.id;
        } else if (actual && !siguiente) {
          siguiente = gimnasta.id;
        }
      });
      setcontrol({ anterior: anterior, siguiente: siguiente });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, orden_de_paso_local]);

  useEffect(() => {
    if (online && !competence)
      sendServer({
        domain: organizacion,
        app: "global",
        action: "getCompetence",
        id: competencia_id,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [online, competence, competencia_id, state.URLdomain]);

  useEffect(() => {
    sendServer({
      domain: organizacion,
      app: modalidad,
      action: !inscripcion ? "getCurrent" : "getInscripcion",
      id: inscripcion,
      getOrdenDePaso: true,
      competencia_id: competencia_id,
    });
    document.title = `CJP | ${current.participante}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inscripcion, state.URLdomain]);

  useEffect(() => {
    let interval = setInterval(() => {
      // && params.ver==='resultados'
      // if(online){
      sendServer({
        domain: organizacion,
        app: modalidad,
        action: !inscripcion ? "getCurrent" : "getInscripcion",
        id: inscripcion,
        competencia_id: competencia_id,
      });
      // }
    }, 3000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competence, competencia_id, sendServer]);

  useEffect(() => {
    // 6.2 CARTA DE PUNTUACIÓN
    // https://www.gymnastics.sport/publicdir/rules/files/es_AER%20CoP%202022-2024.pdf
    if (current) {
      // Si el total es "DNS", no recalcular
      if (current.dns) {
        setnotas({
          artística_final: 0,
          ejecución_final: 0,
          linea_total: 0,
          dificultad_final: 0,
          total: "DNS",
        });
        return; // Salimos de la función
      }

      // Cálculo normal del total
      let artística_final = promedioAER([
        current.artística_1,
        current.artística_2,
        current.artística_3,
        current.artística_4,
      ]);
      let ejecución_final = 0;
      if (
        current.ejecución_1 !== null ||
        current.ejecución_2 !== null ||
        current.ejecución_3 !== null ||
        current.ejecución_4 !== null
      ) {
        ejecución_final = promedioAER([
          current.ejecución_1,
          current.ejecución_2,
          current.ejecución_3,
          current.ejecución_4,
        ]);
      }
      let linea_total = 0;
      [current.linea_1, current.linea_2].map((l) => {
        if (l) linea_total += l;
      });
      let dificultad_final = 0;
      if (current.dificultad_1)
        dificultad_final = current.dificultad_1 / current.division_d;
      let total =
        artística_final + ejecución_final + dificultad_final - linea_total;
      [current.deducciones_CJP, current.deducciones_dificultad_total].map(
        (l) => {
          if (l) total -= l;
        }
      );
      if (total < 0) total = 0;

      setnotas({
        artística_final: artística_final,
        ejecución_final: ejecución_final,
        linea_total: linea_total,
        dificultad_final: dificultad_final.toFixed(3),
        total: parseFloat(total.toFixed(3)),
      });
    }
  }, [
    id,
    artística_1,
    artística_2,
    artística_3,
    artística_4,
    ejecución_1,
    ejecución_2,
    ejecución_3,
    ejecución_4,
    dificultad_1,
    linea_1,
    linea_2,
    deducciones_CJP,
    deducciones_dificultad_total,
    division_d,
  ]);

  useEffect(() => {
    // Cerrar modal
    if (success) {
      setpuesto("");
      setUserText("");
      setdel(false);
      sendState({ success: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  // Teclado fisico
  const [userText, setUserText] = useState("");
  const handleUserKeyPress = useCallback(
    (event) => {
      const { key, keyCode } = event;
      if (caracteres.includes(key)) {
        setUserText((prevUserText) => {
          if (prevUserText.match(/[.]/) && key === ".") {
            console.log("No puedes poner dos puntos");
          } else if (prevUserText.length > 4) {
            console.log("maximo 5 caracteres");
          } else if (parseFloat(`${prevUserText}${key}`) > 50) {
            console.log("El valor maximo es 10");
          } else {
            return `${prevUserText}${key}`;
          }
          return `${prevUserText}`;
        });
      } else if (keyCode === 8) {
        setUserText(
          (prevUserText) => `${prevUserText.substr(0, prevUserText.length - 1)}`
        );
      } else if (keyCode === 46) {
        // suprimir
        if (puesto !== "") {
          setUserText(``);
        } else {
          publicar(false);
        }
      } else if (keyCode === 188) {
        setUserText((prevUserText) => {
          if (prevUserText.match(/[.]/)) return `${prevUserText}`;
          return `${prevUserText}.`;
        });
      } else if (keyCode === 13) {
        // Enter
        if (puesto !== "") {
          setUserText((prevUserText) => {
            cargarNota(prevUserText);
            return `${prevUserText}`;
          });
        } else {
          publicar(true);
        }
      } else if (keyCode === 37 && control.anterior) {
        // izquierda
        cambiarGimnasta(control.anterior);
      } else if (keyCode === 39 && control.siguiente) {
        // derecha
        cambiarGimnasta(control.siguiente);
      }
      // console.log(event)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [current, id]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  // End teclado fisico
  const cargarNota = (nota) => {
    if (nota === "") nota = null;
    sendServer({
      domain: organizacion,
      app: modalidad,
      action: "cargarNota",
      puesto: "cjp",
      panel: panel,
      competencia_id: competencia_id,
      data: {
        id: current.id,
        competencia_id: current.competencia,
        [puesto]: nota,
        publicado: false,
      },
    });
  };
  const publicar = (publicado) => {
    sendServer({
      domain: organizacion,
      app: modalidad,
      action: "cargarNota",
      puesto: "cjp",
      panel: panel,
      getOrdenDePaso: true,
      competencia_id: competencia_id,
      data: {
        ...notas,
        id: current.id,
        competencia_id: current.competencia,
        dns: false,
        publicado: publicado,
      },
    });
  };

  const ButtonDNS = () => {
    sendServer({
      domain: organizacion,
      app: modalidad,
      action: "cargarNota",
      puesto: "cjp",
      panel: panel,
      getOrdenDePaso: true,
      competencia_id: competencia_id,
      data: {
        id: current.id,
        competencia_id: current.competencia,
        artística_1: null,
        artística_2: null,
        artística_3: null,
        artística_4: null,
        artística_final: null,
        ejecución_1: null,
        ejecución_2: null,
        ejecución_3: null,
        ejecución_4: null,
        ejecución_final: null,
        dificultad_1: null,
        dificultad_final: null,
        linea_1: null,
        linea_2: null,
        linea_total: null,
        deducciones_CJP: null,
        deducciones_dificultad_total: null,
        total: null,
        dns: true,
        dnf: false,
        publicado: true,
      },
    });
  };

  const ButtonDNF = () => {
    sendServer({
      domain: organizacion,
      app: modalidad,
      action: "cargarNota",
      puesto: "cjp",
      panel: panel,
      getOrdenDePaso: true,
      competencia_id: competencia_id,
      data: {
        id: current.id,
        competencia_id: current.competencia,
        artística_1: null,
        artística_2: null,
        artística_3: null,
        artística_4: null,
        artística_final: null,
        ejecución_1: null,
        ejecución_2: null,
        ejecución_3: null,
        ejecución_4: null,
        ejecución_final: null,
        dificultad_1: null,
        dificultad_final: null,
        linea_1: null,
        linea_2: null,
        linea_total: null,
        deducciones_CJP: null,
        deducciones_dificultad_total: null,
        total: null,
        dns: false,
        dnf: true,
        publicado: true,
      },
    });
  };

  const clearInscription = () => {
    sendServer({
      domain: organizacion,
      app: modalidad,
      action: "cargarNota",
      puesto: "cjp",
      panel: panel,
      getOrdenDePaso: true,
      competencia_id: competencia_id,
      data: {
        id: current.id,
        publicado: false,
        artística_1: null,
        artística_2: null,
        artística_3: null,
        artística_4: null,
        artística_final: null,
        ejecución_1: null,
        ejecución_2: null,
        ejecución_3: null,
        ejecución_4: null,
        ejecución_final: null,
        dificultad_1: null,
        dificultad_final: null,
        linea_1: null,
        linea_2: null,
        linea_total: null,
        deducciones_CJP: null,
        deducciones_dificultad_total: null,
        total: null,
        dns: false, // Resetear el estado DNS
        dnf: false,
      },
    });
  };

  const loadtype = (puesto) => {
    setpuesto(puesto);
    if (current[puesto]) {
      setUserText(current[puesto].toString());
    } else {
      setUserText("");
    }
  };

  const cambiarGimnasta = (id) => {
    if (inscripcion) setInscripcion(null);
    if (id)
      sendServer({
        domain: organizacion,
        app: modalidad,
        action: "cambiarGimnasta",
        puesto: "cjp",
        panel: panel,
        getOrdenDePaso: true,
        competencia_id: competencia_id,
        id: id,
      });
  };
  const onChange = (e) => {
    let newFilter = { ...filter, [e.target.name]: e.target.value };
    if (e.target.value === "Limpiar") {
      newFilter = initialFilter;
    }
    setfilter(newFilter);
    let newUrl = "?";
    for (let f in newFilter) {
      if (newFilter[f] !== "") {
        newUrl += `${newUrl === "?" ? "" : "&"}${f}=${newFilter[f]}`;
      }
    }
    navigate(newUrl);
  };
  const formatTotal = (total, dnf) => {
    if (total === null || total === undefined) {
      return ""; // Si es null o undefined, devuelve una cadena vacía
    }

    // Si el total es "DNS", devolver "DNS" directamente
    if (dnf) {
      return "DNF";
    }
    if (total === "DNS") {
      return "DNS";
    }

    // Intenta convertir a número
    const number = parseFloat(total);

    // Si la conversión es exitosa y no es NaN, devuelve el número formateado
    if (!isNaN(number)) {
      return number.toFixed(3);
    }

    // Si no se puede convertir a número, devuelve el valor original como string
    return total.toString();
  };

  if (!current || !authorized) return null;
  return (
    <>
      <p
        className="noPrint"
        children={
          <>
            {current.id ? (
              <>
                <img
                  className="mini"
                  src={`https://consugi.consugisoft.com/media/${current.representacion__bandera}`}
                  alt="Bandera"
                />
                <span
                  onClick={() => loadtype("division_d")}
                  children={`(${current.representacion}) ${current.categoria_de_participacion}(D/${current.division_d}) - ${current.categoria_edad} - ${current.participante}`}
                />
              </>
            ) : null}
          </>
        }
      />
      <Row
        className="marginAuto noPrint"
        children={
          <>
            {lineone.map((l) => (
              <Col
                onClick={() => loadtype(l.name)}
                key={l.name}
                className={`${l.red ? "red" : "blue"} enter`}
                children={`${l.show}\n${
                  current[l.name] != null ? current[l.name].toFixed(2) : ""
                }`}
              />
            ))}
            <Col
              className={`totales ${
                current.artística_final === notas.artística_final
                  ? styles.success
                  : styles.warning
              }`}
              children={`AT\n${notas.artística_final.toFixed(3)}`}
            />
          </>
        }
      />
      <Row
        className="marginAuto noPrint"
        children={
          <>
            {linetwo.map((l) => (
              <Col
                onClick={() => loadtype(l.name)}
                key={l.name}
                className={`${l.red ? "red" : "blue"} enter`}
                children={`${l.show}\n${
                  current[l.name] != null ? current[l.name].toFixed(2) : ""
                }`}
              />
            ))}
            <Col
              className={`totales ${
                current.ejecución_final === notas.ejecución_final
                  ? styles.success
                  : styles.warning
              }`}
              children={`ET\n${notas.ejecución_final.toFixed(3)}`}
            />
          </>
        }
      />
      <Row
        className="marginAuto noPrint"
        children={
          <>
            {linethree.map((l) => (
              <Col
                onClick={() => loadtype(l.name)}
                key={l.name}
                className={`${l.red ? "red" : "blue"} enter`}
                children={`${l.show}\n${
                  current[l.name] != null ? current[l.name].toFixed(2) : ""
                }`}
              />
            ))}
            <Col
              className="enter red enterCont"
              children={
                <>
                  <Col
                    onClick={() => loadtype("linea_1")}
                    className="enter"
                    children={`L1: ${
                      current.linea_1 != null ? current.linea_1.toFixed(1) : ""
                    }`}
                  />
                  <Col
                    onClick={() => loadtype("linea_2")}
                    className="enter"
                    children={`L2: ${
                      current.linea_2 != null ? current.linea_2.toFixed(1) : ""
                    }`}
                  />
                </>
              }
            />
            <Col
              onClick={() => loadtype("deducciones_CJP")}
              className="enter red"
              children={`cjp\n${
                current.deducciones_CJP != null
                  ? current.deducciones_CJP.toFixed(2)
                  : ""
              }`}
            />
            <Col
              className={`totales ${
                current.total === notas.total ? "success" : "warning"
              }`}
              children={`T\n${
                current.dns
                  ? "DNS"
                  : typeof notas.total === "number"
                  ? notas.total.toFixed(3)
                  : ""
              }`}
            />
          </>
        }
      />
      <Row
        className="controles marginAuto noPrint mt10"
        children={
          <>
            <Col
              onClick={() => cambiarGimnasta(control.anterior)}
              className={`btn-div btn-${
                control.anterior ? "primary" : "disabled"
              }`}
              children={"◄"}
            />
            <Col
              onClick={() => cambiarGimnasta(control.siguiente)}
              className={`${styles["btn-div"]} ${
                control.anterior
                  ? styles["btn-primary"]
                  : styles["btn-disabled"]
              }`}
              children={"►"}
            />
            <Col
              onClick={() => setdel(true)}
              className={`${styles["btn-div"]} ${
                control.siguiente
                  ? styles["btn-primary"]
                  : styles["btn-disabled"]
              }`}
              children={"Delete"}
            />
            <Col
              onClick={() => setShowDNSModal(true)} // Abrir el modal DNS
              className={styles["btn-div"] + " " + styles["btn-danger"]}
              children={"DNS"}
            />
            <Col
              onClick={() => setShowDNFModal(true)} // Abrir el modal DNF
              className={styles["btn-div"] + " " + styles["btn-dnf"]}
              children={"DNF"}
            />
            <Col
              onClick={current.publicado ? () => publicar(false) : () => {}}
              className={`btn-div btn-${
                current.publicado
                  ? styles["btn-warning"]
                  : styles["btn-disabled"]
              }`}
              children={"Desp"}
            />
            <Col
              onClick={!current.publicado ? () => publicar(true) : () => {}}
              className={`${styles["btn-div"]} ${
                current.publicado ? styles["btn-disabled"] : styles["success"]
              }`}
              children={"Enviar"}
            />
          </>
        }
      />
      <div
        className="tableContainer"
        children={
          <table
            className="mt30 w100 table table-striped"
            children={
              <>
                <thead
                  children={
                    <>
                      <tr
                        children={
                          <th
                            colSpan={40}
                            className="text-center"
                            children={
                              <>
                                ORDEN DE PASO
                                <Row
                                  className="controles marginAuto noPrint mt30"
                                  children={
                                    <>
                                      <Col
                                        xs={6}
                                        sm={4}
                                        md={3}
                                        lg={2}
                                        className="mt10"
                                        children={
                                          <>
                                            <label children={"_"} />
                                            <input
                                              className="w100"
                                              type="button"
                                              value="Limpiar"
                                              onClick={onChange}
                                            />
                                          </>
                                        }
                                      />
                                      <Col
                                        xs={6}
                                        sm={4}
                                        md={3}
                                        lg={2}
                                        className="mt10"
                                        children={
                                          <>
                                            <label
                                              children={"Final/Clasificación"}
                                            />
                                            <select
                                              className="w100"
                                              name="concurso"
                                              value={filter.concurso}
                                              onChange={onChange}
                                              children={
                                                <>
                                                  <option></option>
                                                  <option value="clasificación">
                                                    Clasificación
                                                  </option>
                                                  <option value="final">
                                                    Final
                                                  </option>
                                                </>
                                              }
                                            />
                                          </>
                                        }
                                      />
                                      <Col
                                        xs={6}
                                        sm={4}
                                        md={3}
                                        lg={2}
                                        className="mt10"
                                        children={
                                          <>
                                            <label children={"Modalidad"} />
                                            <select
                                              className="w100"
                                              name="modalidad"
                                              value={filter.modalidad}
                                              onChange={onChange}
                                              children={
                                                <>
                                                  <option></option>
                                                  <option value="AD">AD</option>
                                                  <option value="AS">AS</option>
                                                  <option value="GR">GR</option>
                                                  <option value="IF">IF</option>
                                                  <option value="IM">IM</option>
                                                  <option value="PAR">
                                                    PAR
                                                  </option>
                                                  <option value="TR">TR</option>
                                                </>
                                              }
                                            />
                                          </>
                                        }
                                      />
                                      <Col
                                        xs={6}
                                        sm={4}
                                        md={3}
                                        lg={2}
                                        className="mt10"
                                        children={
                                          <>
                                            <label children={"Edad"} />
                                            <select
                                              className="w100"
                                              name="edad"
                                              value={filter.edad}
                                              onChange={onChange}
                                              children={
                                                <>
                                                  <option></option>
                                                  {edades.map((e) => (
                                                    <option key={e} value={e}>
                                                      {e}
                                                    </option>
                                                  ))}
                                                </>
                                              }
                                            />
                                          </>
                                        }
                                      />
                                      <Col
                                        xs={6}
                                        sm={4}
                                        md={3}
                                        lg={2}
                                        className="mt10"
                                        children={
                                          <>
                                            <label children={"Gimnasta"} />
                                            <input
                                              className="w100"
                                              type="text"
                                              name="gimnasta"
                                              value={filter.gimnasta}
                                              onChange={onChange}
                                            />
                                          </>
                                        }
                                      />
                                    </>
                                  }
                                />
                              </>
                            }
                          />
                        }
                      />
                      <tr
                        children={
                          <>
                            <th children={"#"} />
                            <th children={"Gimnastas"} />
                            <th children={"Rep."} />
                            <th children={"Mod."} />
                            <th children={"Edad"} />
                            <th children={"Total"} />
                          </>
                        }
                      />
                    </>
                  }
                />
                <tbody
                  children={
                    <>
                      {orden_de_paso_local
                        ? orden_de_paso_local.map((i) => (
                            <tr
                              key={i.id}
                              className={`click ${
                                i.puntuando ? styles["btn-primary"] : ""
                              } ${i.id === id ? styles["btn-warning"] : ""}`}
                              children={
                                <>
                                  <td
                                    children={i.orden_de_salida || ""}
                                    onClick={() => setInscripcion(i.id)}
                                    className={`text-end`}
                                  />
                                  <td
                                    onClick={() => cambiarGimnasta(i.id)}
                                    children={i.participante}
                                  />
                                  <td children={i.representacion} />
                                  <td children={i.categoria_de_participacion} />
                                  <td children={i.categoria_edad} />
                                  <td
                                    children={formatTotal(i.total, i.dnf)}
                                    className={`text-end ${
                                      i.publicado ? styles.success : ""
                                    }`}
                                  />
                                </>
                              }
                            />
                          ))
                        : null}
                    </>
                  }
                />
              </>
            }
          />
        }
      />

      <Modal
        show={Boolean(puesto)}
        fullscreen={true}
        onHide={() => setpuesto("")}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {current ? (
              <p
                children={
                  <>
                    <img
                      className="mini"
                      src={`https://consugi.consugisoft.com/media/${current.representacion__bandera}`}
                      alt="Bandera"
                    />
                    <span
                      children={`(${current.representacion}) ${current.categoria_de_participacion} - ${current.categoria_edad} - ${current.participante}`}
                    />
                  </>
                }
              />
            ) : null}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row marginAuto">
            <div
              className="actionsContainer"
              children={
                <>
                  <div
                    className="text-center userTextCont"
                    children={<b className="userText" children={userText} />}
                  />
                  <div
                    className="text-center puesto"
                    children={puesto.replace("_", " ").replace("_total", " ")}
                  />
                  <div
                    className="btn-group col-12"
                    role="group"
                    aria-label="Basic mixed styles example"
                  >
                    {userText === "" ? (
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => setpuesto("")}
                      >
                        Cancel
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => setUserText("")}
                      >
                        Del
                      </button>
                    )}
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={() => cargarNota(userText)}
                    >
                      Enviar
                    </button>
                  </div>
                </>
              }
            />
            <div
              className="tecladoContainer"
              children={
                <>
                  {caracteres.map((n) => (
                    <button
                      type="button"
                      key={n}
                      onClick={() => handleUserKeyPress({ key: n })}
                      className="btn btn-primary"
                      children={<span children={n} />}
                    />
                  ))}
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() =>
                      setUserText(userText.substr(0, userText.length - 1))
                    }
                    children={<span children={"◄"} />}
                  />
                </>
              }
            />
          </div>
        </Modal.Body>
      </Modal>

      {/* Modal Eliminar notas */}
      <Modal show={del} onHide={() => setdel(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar Notas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {current ? (
            <p
              children={
                <>
                  <img
                    className="mini"
                    src={`https://consugi.consugisoft.com/media/${current.representacion__bandera}`}
                    alt="Bandera"
                  />
                  <span
                    children={`(${current.representacion}) ${current.categoria_de_participacion} - ${current.categoria_edad} - ${current.participante}`}
                  />
                </>
              }
            />
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setdel(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={() => clearInscription()}>
            Eliminar notas
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal DNS */}
      <Modal show={showDNSModal} onHide={() => setShowDNSModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Marcar como DNS</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {current ? (
            <p
              children={
                <>
                  <img
                    className="mini"
                    src={`https://consugi.consugisoft.com/media/${current.representacion__bandera}`}
                    alt="Bandera"
                  />
                  <span
                    children={`(${current.representacion}) ${current.categoria_de_participacion} - ${current.categoria_edad} - ${current.participante}`}
                  />
                </>
              }
            />
          ) : null}
          <p>
            ¿Estás seguro de que deseas marcar a este gimnasta como{" "}
            <strong>DNS</strong>?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDNSModal(false)}>
            Cancelar
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              ButtonDNS(); // Ejecutar la función DNS
              setShowDNSModal(false); // Cerrar el modal
            }}
          >
            Marcar como DNS
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal DNF */}
      <Modal show={showDNFModal} onHide={() => setShowDNFModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Marcar como DNF</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {current ? (
            <p
              children={
                <>
                  <img
                    className="mini"
                    src={`https://consugi.consugisoft.com/media/${current.representacion__bandera}`}
                    alt="Bandera"
                  />
                  <span
                    children={`(${current.representacion}) ${current.categoria_de_participacion} - ${current.categoria_edad} - ${current.participante}`}
                  />
                </>
              }
            />
          ) : null}
          <p>
            ¿Estás seguro de que deseas marcar a este gimnasta como{" "}
            <strong>DNF</strong>?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDNFModal(false)}>
            Cancelar
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              ButtonDNF(); // Ejecutar la función DNF
              setShowDNFModal(false); // Cerrar el modal
            }}
          >
            Marcar como DNF
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
