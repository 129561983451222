import { formatTotal } from "../../../judgment/utils/utils"
import { calendario, lista_de_jueces, panel, asignacion_de_paneles, SubTableAsignaciones } from "./Artistica"

const fs = [
  { item: 'puesto', show: '#', clases: 'text-end' },
  { item: 'participante', show: 'Gimnastas', clases: 'text-start' },
  { item: 'q', show: 'Q', clases: 'text-end' },
  { item: 'representacion__bandera', show: '', clases: 'text-end' },
  { item: 'representacion', show: 'Equipo', clases: 'text-start hide_md_down' },
  { item: 'deduccion_final', show: 'D', toFixed: 3, clases: 'text-end hide_md_down' },
  { item: 'ejecución_final', show: 'E', toFixed: 3, clases: 'text-end hide_md_down' },
  { item: 'deducciones_CJP', show: 'Penalidad', toFixed: 1, clases: 'text-end text-danger hide_md_down' },
  { item: 'total',show: 'Total', toFixed: 3, clases: 'text-end', format: (total, dns) => formatTotal(total, dns),},
]

const sp = [
  { item: 'puesto', show: '#', clases: 'text-end' },
  { item: 'participante', show: 'Gimnastas', clases: 'text-start' },
  { item: 'q', show: 'Q', clases: 'text-end' },
  { item: 'representacion__bandera', show: '', clases: 'text-end' },
  { item: 'representacion', show: 'Equipo', clases: 'text-start hide_md_down' },
  { item: 'tiempo_final', show: 'T', toFixed: 3, clases: 'text-end hide_md_down' },
  { item: 'deducciones_CJP', show: 'Penalidad', toFixed: 1, clases: 'text-end text-danger hide_md_down' },
  { item: 'total',show: 'Total', toFixed: 3, clases: 'text-end', format: (total, dns) => formatTotal(total, dns),},
]

const skl = [
  { item: 'puesto', show: '#', clases: 'text-end' },
  { item: 'participante', show: 'Gimnastas', clases: 'text-start' },
  { item: 'q', show: 'Q', clases: 'text-end' },
  { item: 'representacion__bandera', show: '', clases: 'text-end' },
  { item: 'representacion', show: 'Equipo', clases: 'text-start hide_md_down' },
  { item: 'skill_1', show: 'S1', toFixed: 3, clases: 'text-end hide_md_down' },
  { item: 'skill_2', show: 'S2', toFixed: 3, clases: 'text-end hide_md_down' },
  { item: 'skill_3', show: 'S3', toFixed: 3, clases: 'text-end hide_md_down' },
  { item: 'skill_4', show: 'S4', toFixed: 3, clases: 'text-end hide_md_down' },
  { item: 'skill_5', show: 'S5', toFixed: 3, clases: 'text-end hide_md_down' },
  { item: 'total',show: 'Total', toFixed: 3, clases: 'text-end', format: (total, dns) => formatTotal(total, dns),},
]

const team = [
  { item: 'puesto', show: '#', clases: 'text-end' },
  { item: 'representacion__bandera', show: '', clases: 'text-end' },
  { item: 'representacion', show: 'Equipo', clases: 'text-start' },
  { item: 'categoria', show: 'Mod.', clases: 'text-center hide_md_down' },
  { item: 'puesto_a', show: 'Puesto', clases: 'text-end hide_md_down' },
  { item: 'total', show: 'Total', clases: 'text-end' },
]

const lista_equipos = [
  { item: 'count', show: 'Total', clases: 'text-end' },
  { item: 'representacion__bandera', show: '', clases: 'text-end' },
  { item: 'representacion', show: 'Equipo', clases: 'text-start' },
]

const orden_de_paso = [
  { item: 'orden_de_salida', show: '#', clases: 'text-end' },
  { item: 'participante', show: 'Gimnastas', clases: 'text-start' },
  { item: 'categoria_edad', show: 'Edad', clases: 'text-start' },
  { item: 'categoria_de_participacion', show: '', clases: 'text-start' },
  { item: 'representacion__bandera', show: '', clases: 'text-end' },
  { item: 'representacion', show: 'Equipo', clases: 'text-start' },
]

const individualScores = [
  { item: 'puesto', show: '#', clases: 'text-end' },
  { item: 'participante', show: 'Gimnastas', clases: 'text-start' },
  { item: 'q', show: 'Q', clases: 'text-end' },
  { item: 'representacion__bandera', show: '', clases: 'text-end' },
  { item: 'representacion', show: 'Equipo', clases: 'text-start hide_md_down' },
  { item: 'deduccion_1', show: 'D1', toFixed: 1, clases: 'text-end hide_md_down lh15px ht12' },
  { item: 'deduccion_2', show: 'D2', toFixed: 1, clases: 'text-end hide_md_down lh15px ht12' },
  { item: 'deduccion_3', show: 'D3', toFixed: 1, clases: 'text-end hide_md_down lh15px ht12' },
  { item: 'deduccion_final', show: 'D', toFixed: 3, clases: 'text-end hide_md_down text-center' },
  { item: 'ejecución_1', show: 'E1', toFixed: 1, clases: 'text-end hide_md_down lh15px ht12' },
  { item: 'ejecución_2', show: 'E2', toFixed: 1, clases: 'text-end hide_md_down lh15px ht12' },
  { item: 'ejecución_3', show: 'E3', toFixed: 1, clases: 'text-end hide_md_down lh15px ht12' },
  { item: 'ejecución_final', show: 'E', toFixed: 3, clases: 'text-end hide_md_down text-center' },
  { item: 'skill_1', show: 'S1', toFixed: 1, clases: 'text-end hide_md_down lh15px ht12' },
  { item: 'skill_2', show: 'S2', toFixed: 1, clases: 'text-end hide_md_down lh15px ht12' },
  { item: 'skill_3', show: 'S3', toFixed: 1, clases: 'text-end hide_md_down lh15px ht12' },
  { item: 'skill_4', show: 'S4', toFixed: 1, clases: 'text-end hide_md_down lh15px ht12' },
  { item: 'skill_5', show: 'S5', toFixed: 1, clases: 'text-end hide_md_down lh15px ht12' },
  { item: 'skill_final', show: 'S', toFixed: 3, clases: 'text-end hide_md_down text-center' },
  { item: 'tiempo_final', show: 'T', toFixed: 3, clases: 'text-end hide_md_down text-center' },
  { item: 'deducciones_CJP', show: 'Cjp-par', toFixed: 1, clases: 'text-end text-danger hide_md_down' },
  { item: 'total', show: 'Total', toFixed: 3, clases: 'text-end' },
]

const medallistas = [
  { item: 'evento', show: 'Evento', clases: 'text-center borderSolid align-middle backgroundWhite lh1-3' },
  { item: 'medalla', show: 'Medalla', clases: 'text-center borderSolid align-middle backgroundWhite lh1-3' },
  { item: 'representacion__bandera', show: '', clases: 'text-end noPadding w30px borderSolid align-middle backgroundWhite lh1-3' },
  { item: 'representacion__siglas', show: '', clases: 'text-center borderSolid align-middle backgroundWhite lh1-3' },
  { item: 'participante', show: 'Gimnastas', clases: 'text-start borderL borderSolid lh1-3' },
]

const Parkour = {
  "FREESTYLE_(M)": fs,
  "FREESTYLE_(W)": fs,
  "SPEEDRUN_(M)": sp,
  "SPEEDRUN_(W)": sp,
  "SKILL_(M)": skl,
  "SKILL_(W)": skl,
  team: team,
  medallistas: medallistas,
  lista_equipos: lista_equipos,
  orden_de_paso: orden_de_paso,
  'Individual Judges Scores': individualScores,
  calendario: calendario,
  lista_de_jueces: lista_de_jueces,
  panel: panel,
  asignacion_de_paneles: asignacion_de_paneles,
  SubTableAsignaciones: SubTableAsignaciones,
  generic: [], // Fallback genérico
}

export { Parkour };