import React, { useContext, useEffect, useState } from 'react'
import globalContext from '../../../../context/globalContext'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Format } from "../Format";
import { useParams } from 'react-router-dom'
import { SubTable } from "./SubTable";

const TableOrdArt = (props) => {
  const { state, URLdomain } = useContext(globalContext);
  let {organizacion } = useParams();
  const [localQuery, setlocalQuery] = useState([])
  const {competence} = state;
  const {query, modalidad, params} = props;
  const {concurso} = params
  useEffect(() => {
    let newQuery= []
    if(Format[modalidad]){
      setlocalQuery(Format[modalidad](query))
    }else{
      let aparato = null;
      query.forEach(e => {
        if((aparato !== e.aparato&&modalidad==='orden_de_paso')||(modalidad==='lista_de_equipos'&&aparato!==e.equipo__nombre)){
          newQuery.push([])
        }
        newQuery[newQuery.length-1].push(e);
        aparato = modalidad==='orden_de_paso'?e.aparato:e.equipo__nombre;
      });
      let subQuerys = []
      let isPar = 0;
      newQuery.forEach((q, index) => {
        if(isPar%2===0){
          subQuerys.push([])
        }
        subQuerys[subQuerys.length-1].push(<SubTable localQuery={q} modalidad={modalidad} key={index} concurso={concurso}/>)
        isPar++;
      });
      setlocalQuery(subQuerys);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])
  if(!competence||organizacion!==state.URLdomain)return null;
  return (
    <table className="centered table newTable" children={
      <>
        <thead className='upper' children={
          <>
            <tr children={
              <th colSpan={40} children={
                <>
                <Row children={
                  <>
                    {competence?
                      <>
                        <Col
                          className="encabezado hide_md_down imgHeader"
                          children={
                            <img
                              src={`https://consugi.consugisoft.com/media/${competence.organizadores}`}
                              alt='img.org'
                            />
                          }
                        />
                        <Col className="encabezado" xs={12} sm={7}>
                          <p className='text-center m0' children={competence.nombre_de_la_competencia}/>
                          <p className='text-center m0' children={
                            <>
                              <a href={`${competence.ubicacion}`} target="_blank" rel="noreferrer" children={competence.lugar}/>
                              <span> Del {competence.inicio} al {competence.fin}</span>
                            </>
                          }/>
                          <p className='text-center m0 bold' children={
                            <>
                              {localQuery.length?
                              `${modalidad!=='lista_de_equipos'?(query[0].concurso!=='I'?'FINAL - ':'CLASIFICACION - '):''}${modalidad!=='lista_de_equipos'?(competence.modalidad==='Aerobica'?query[0].categoria_edad+' - ':query[0].categoria+' - '):''}${modalidad.replace(/_/g, ' ')}${modalidad==='orden_de_paso'?(' - R'+query[0].rotacion):''}`
                              :null}
                            </>
                          }/>
                        </Col>
                        <Col
                          className="encabezado hide_md_down imgHeader"
                          children={
                            <img
                              src={`https://consugi.consugisoft.com/media/${competence.logo}`}
                              alt='img.log'
                            />
                          }/>
                      </>
                    :null}
                    </>
                }/>
                </>
              }/>
            }/>
          </>
        }/>	
        <tbody className="resultadosCont" children={
          <>
          {localQuery.map((q, index)=>
            <tr key={`tebleOrd${index}`} children={
              <td colSpan={40} children={q} className='subTableCont'/>
            }/>
          )}
          {localQuery.length===0?<tr children={<td colSpan={40} className='text-center' children={'...Sin resultados, consulta con sistemas para mas información.'}/>}/>:null}
          </>
        }/>
        {competence.patrocinadores&&localQuery.length>7?
          <tfoot  children={
            <tr children={
              <td colSpan={40} children={
                <div className="encabezado" style={{backgroundImage:`url('https://consugi.consugisoft.com/media/${competence.patrocinadores}')`}}/>
              }/>
            }/>
          }/>
        :null}
      </>
    }/>
  )
}

export {TableOrdArt};