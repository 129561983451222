import React, { useContext, useEffect, useState } from 'react'
import globalContext from '../../context/globalContext'
import { useParams } from 'react-router-dom'
import Col from 'react-bootstrap/esm/Col'
import Row from 'react-bootstrap/esm/Row'
import { convenciones } from "./Convenciones";

export default function Individual(props) {
  const {state, sendServer, URLdomain, sendState} = useContext(globalContext)
  const {current, competence, online} = state;
  const { competencia_id, modalidad, organizacion } = useParams();
  const [params, setParams] = useState({back:'black'})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  let urlParams = new URLSearchParams(window.location.search);
  useEffect(() => {
    if(urlParams.get('back')){
      setParams({back:urlParams.get('back')});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParams])
  const panel = urlParams.get('panel')
  const id = urlParams.get('id')
  useEffect(() => {
    document.title = `Chroma`;
    sendState({URLdomain:organizacion})
    sendServer({
      domain: organizacion,
      app: modalidad,
      action:id?'getInscripcion':'getCurrent',
      competencia_id: competencia_id,
      panel: props.panel?props.panel:panel,
      id: id,
    })
    if(!competence)sendServer({domain: organizacion, app:'global', action:'getCompetence', id:competencia_id});
    let interval = setInterval(() => {
      sendServer({
        domain: organizacion, 
        app: modalidad,
        action:id?'getInscripcion':'getCurrent',
        competencia_id: competencia_id,
        panel: props.panel?props.panel:panel,
        id: id,
      })
    }, 6000);
    return ()=> clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [online, competence])
  if(!current.id||!competence)return null;
  return (
    <div className={`h100 ${params.back} seccion-datos`} id='full' onClick={() => { !document.fullscreenElement ? document.getElementById("full").requestFullscreen() : document.exitFullscreen() }} children={
      <>
        <link rel="stylesheet" href="/static/css/fullScreen.css" />
        <Row className={`marginAuto individual`} children={
          <>
            <Col xs={3} className={`contenedorbandera`} style={{backgroundImage:`url('https://consugi.consugisoft.com/media/${current[convenciones[modalidad]['bandera']]}')`}}/>
            <Col xs={7} className='text-truncate marquees noPadding' children={
              <>
                <span children={`${current[convenciones[modalidad]['representacion']]} ${current[convenciones[modalidad]['categoria_edad']]}`}/>
                <br/>
                {/* <marquee scrollamount="2" behavior="alternate">{current.participante}</marquee> */}
                <span className='marqueeChild' children={current.participante}/>
              </>
            }/>
            <Col xs={2} className={`modalidadChroma`} style={{backgroundImage:`url('${URLdomain}/static/recursos/${competence.modalidad}/${current[convenciones[modalidad]['aparato']]}.png')`}}/>
          </>
        }/>
        <main/>
        <Row className={`marginAuto individual`} children={
          <>
            {current.publicado?
              <>
                <Col xs={3} className={`notasIndividual text-end noPadding`} children={
                  <>
                    
                    
                    {current.artística_final?
                      <>
                        <span className='float-start' children={'A'}/>{current.artística_final.toFixed(3)}<br/>
                      </>
                    :null}
                    {current.ejecución_final?
                      <>
                        <span className='float-start' children={'E'}/>{current.ejecución_final.toFixed(3)}<br/>
                      </>
                    :null}
                    {current[convenciones[modalidad]['dificultad']]?
                      <>
                        <span className='float-start' children={'D'}/>{current[convenciones[modalidad]['dificultad']].toFixed(3)}<br/>
                      </>
                    :null}
                      
                    <div className='red-text' children={
                      <>
                        {current.neutrales?<><span className='float-start' children={'N'}/>{current.neutrales.toFixed(3)}<br/></>:null}
                        {current.deducciones_dificultad_total?<><span className='float-start' children={'D'}/>{current.deducciones_dificultad_total.toFixed(3)}<br/></>:null}
                        {current.linea_total?<><span className='float-start' children={'L'}/>{current.linea_total.toFixed(3)}<br/></>:null}
                        {current.deducciones_CJP?<><span className='float-start' children={'C'}/>{current.deducciones_CJP.toFixed(3)}</>:null}
                      </>
                    }/>
                  </>
                }/>
                
                {current.publicado?
                  <Col className="totalIndividual text-end noPadding" children={current.dns? "DNS": current[convenciones[modalidad]['total']] !== null? current[convenciones[modalidad]['total']].toFixed(3): null}
                />
                :null}
              </>
            :null}
          </>
        }/>
      </>
    } />
  )
}