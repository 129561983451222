const notasView = {
  IND: [
    {val: 'dificultad', show: 'D', class: 'text-white btn btn-primary', fixed: 2},
    {val: 'tiempo_de_vuelo', show: 'T', class: 'text-white btn btn-primary', fixed: 2},
    {val: 'desplazamiento_horizontal', show: 'H', class: 'text-white btn btn-primary', fixed: 2},
    {val: 'penalizacion', show: 'P', class: 'btn btn-danger', fixed: 2},
  ],
  SYN: [
    {val: 'total_ejecucion', show: 'E', class: ''},
    {val: 'dificultad', show: 'D', class: ''},
    {val: 'sincronizacion', show: 'S', class: ''},
    {val: 'desplazamiento_horizontal', show: 'H', class: ''},
    {val: 'penalizacion', show: 'P', class: 'red-text'},
    {val: 'total_general', show: 'Total', class: ''},
  ],
}
const saltosView = {
  IND:[
    {show: 's1', val: 1},
    {show: 's2', val: 2},
    {show: 's3', val: 3},
    {show: 's4', val: 4},
    {show: 's5', val: 5},
    {show: 's6', val: 6},
    {show: 's7', val: 7},
    {show: 's8', val: 8},
    {show: 's9', val: 9},
    {show: 's10', val: 10},
    {show: 'L', val: 10},
  ]
}
const caracteres = ['1','2','3','4','5','6','7','8','9','.','0'];
const elementosMax = {
  IND:10,
  SYN:10,
  DMT:2,
  TUM:8,
}
export {notasView, saltosView, caracteres, elementosMax};