import React, { useContext, useEffect } from 'react'
import globalContext from '../../../context/globalContext'
import { useParams } from 'react-router-dom'
import { convenciones } from "../Convenciones";

export default function Individual() {
  const URLdomain = 'https://consugi.consugisoft.com'
  const {state, sendServer, URLdomainb, sendState} = useContext(globalContext)
  const {current, competence, online} = state;
  const { competencia_id, modalidad, organizacion } = useParams();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  let urlParams = new URLSearchParams(window.location.search);
  const panel = urlParams.get('panel')
  const id = urlParams.get('id')
  useEffect(() => {
    document.title = `Individual`;
    sendState({URLdomain:organizacion})
    sendServer({
      domain: organizacion,
      app: modalidad,
      action:id?'getInscripcion':'getCurrent',
      competencia_id: competencia_id,
      panel: panel,
      id: id,
    })
    if(!competence)sendServer({domain: organizacion, app:'global', action:'getCompetence', id:competencia_id});
    let interval = setInterval(() => {
      sendServer({
        domain: organizacion, 
        app: modalidad,
        action:id?'getInscripcion':'getCurrent',
        competencia_id: competencia_id,
        panel: panel,
        id: id,
      })
    }, 8000);
    return ()=> clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [online, competence])
  if(!current.id||!competence)return null;
  return (
    <div className="seccion-datos ranking h100" id="full" onClick={() => { !document.fullscreenElement ? document.getElementById("full").requestFullscreen() : document.exitFullscreen() }} >
      <div className="notranslate noSelect container-lg">
        <div className=" black">
          <div className="cabecera-titulo">
            <img src={`/static/img/${competence.modalidad}.png`} alt='logo'/>
            <h1>{competence.modalidad.toUpperCase()}</h1>
            <h2>RESULTADOS{current.concurso?` - CONCURSO ${current.concurso}`:null} {current.final?` - FINAL`:null}</h2>
          </div>
          <link rel="stylesheet" href="/static/css/fullScreen.css"/>
          <link rel="stylesheet" href="/static/css/estilos.css" />
          <div className="marginAuto individual row">
            <div style={{ backgroundImage: `url('https://consugi.consugisoft.com/media/${current[convenciones[modalidad]['bandera']]}')` }}
              className="contenedorbandera col-3"></div>
            <div className="text-truncate marquees noPadding col-7">
              <span>
                {current[convenciones[modalidad]['siglas']]} - {current[convenciones[modalidad]['categoria_edad']]}
              </span>
              <br />
              <span className="marqueeChild">
                {current.participante}
              </span>
            </div>
            
            {modalidad==='Ritmica'?
              <div style={{ backgroundImage: `url('${URLdomain}/static/media/${current['aparato__icono']}')` }}
              className="modalidadChroma col-2"></div>
            :
              <div style={{ backgroundImage: `url('${URLdomain}/static/recursos/${modalidad}/${current[convenciones[modalidad]['aparato']]}.png')` }}
                className="modalidadChroma col-2">{modalidad==='Trampolin'?current.aparato:null}</div>
            }
          </div>
          <main></main>
          <div className="marginAuto individual row">
            <div className="notasIndividual text-end noPadding col-3">
              <div className='alFinal' style={{fontSize:'4vw'}}>
                {current.publicado?
                  <>
                    {current[convenciones[modalidad]['artística_final']]?
                      <>
                        <span className="float-start">A</span>
                        {current[convenciones[modalidad]['artística_final']].toFixed(3)}<br />
                      </>
                    :null}
                    {current[convenciones[modalidad]['ejecución_final']]?
                      <>
                        <span className="float-start">E</span>
                        {current[convenciones[modalidad]['ejecución_final']].toFixed(3)}<br />
                      </>
                    :null}
                    {modalidad==='Ritmica'?
                      <>
                      {current.dificultad_a?
                        <>
                          <span className="float-start">DA</span>
                          {current.dificultad_a.toFixed(3)}<br />
                        </>
                      :null}
                      {current.dificultad_b?
                        <>
                          <span className="float-start">DB</span>
                          {current.dificultad_b.toFixed(3)}<br />
                        </>
                      :null}
                      </>
                    :
                      <>
                        {current[convenciones[modalidad]['dificultad']]?
                          <>
                            <span className="float-start">D</span>
                            {current[convenciones[modalidad]['dificultad']].toFixed(3)}<br />
                          </>
                        :null}
                      </>
                    }
                    {/* //////////////////here */}

                    {current[convenciones[modalidad]['aparato']]==='IND'&&current.tiempo_de_vuelo?
                      <>
                        <span className="float-start">T</span>
                        {current.tiempo_de_vuelo.toFixed(3)}<br />
                      </>
                    :null}
                    {current[convenciones[modalidad]['aparato']]==='SYN'&&current.sincronizacion?
                      <>
                        <span className="float-start">S</span>
                        {current.sincronizacion.toFixed(3)}<br />
                      </>
                    :null}
                    {(current[convenciones[modalidad]['aparato']]==='SYN'||current[convenciones[modalidad]['aparato']]==='IND')&&current.desplazamiento_horizontal?
                      <>
                        <span className="float-start">H</span>
                        {current.desplazamiento_horizontal.toFixed(3)}<br />
                      </>
                    :null}
                    {/* //////////////////stop */}
                    <div className='red-text' children={
                      <>
                        {current[convenciones[modalidad]['neutrales']]?
                          <>
                            <span className='red-text'>{current[convenciones[modalidad]['neutrales']].toFixed(3)}</span><br />
                          </>
                        :null}
                        {current.deducciones_dificultad_total!==null&&current.deducciones_dificultad_total!==undefined?<><span className='float-start' children={'D'}/>{current.deducciones_dificultad_total.toFixed(3)}<br/></>:null}
                        {current.linea_total!==null&&current.deducciones_dificultad_total!==undefined?<><span className='float-start' children={'L'}/>{current.linea_total.toFixed(3)}<br/></>:null}
                        {current.deducciones_CJP!==null&&current.deducciones_dificultad_total!==undefined?<><span className='float-start' children={'C'}/>{current.deducciones_CJP.toFixed(3)}</>:null}
                      </>
                    }/>
                  </>
                :null}
              </div>
            </div>
            <div className="totalIndividual text-end noPadding col">{current[convenciones[modalidad]['total']]!==null&&current.publicado?current[convenciones[modalidad]['total']].toFixed(3):`${current.publicado?'DNS':''}`}</div>
          </div>
        </div>
      </div>
    </div>
  )
}