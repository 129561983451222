import React, { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useParams } from "react-router-dom";
import globalContext from '../../../context/globalContext';

const ModaJueces = (props) => {
  const { object, clearJuez } = props;
  const { sendServer, sendState, state } = useContext(globalContext);
  const { success, representaciones, lista_de_jueces } = state;
  let { competencia_id, organizacion, puesto, panel } = useParams();

  const initial = () => {
    let exist = Boolean(lista_de_jueces && lista_de_jueces.length);
    return {
      competencia_id: null,
      orden: exist ? parseInt(lista_de_jueces[lista_de_jueces.length - 1]['orden']) + 1 : '1',
      representacion_id: exist ? lista_de_jueces[lista_de_jueces.length - 1]['representacion_id'] : '',
      nombre: '',
      funcion: exist ? lista_de_jueces[lista_de_jueces.length - 1]['funcion'] : '',
      id_fig: '',
      categoria: '',
    }
  }

  const [juez, setJuez] = useState(initial());
  const [deleteObject, setdeleteObject] = useState(false);

  useEffect(() => {
    if (object.id) setJuez({
      id: object.id,
      competencia_id: object.competencia_id,
      orden: object.orden,
      representacion_id: object.representacion_id,
      nombre: object.nombre,
      funcion: object.funcion,
      id_fig: object.id_fig,
      categoria: object.categoria,
    });
  }, [object]);

  useEffect(() => {
    if (success) { setJuez(initial()); sendState({ success: null }); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  const onChange = e => {
    setJuez({ ...juez, [e.target.name]: e.target.value });
  }

  const save = () => {
    sendServer(
      {
        domain: organizacion,
        app: 'global',
        action: juez.id ? 'updateJuez' : 'createJuez',
        competencia: competencia_id,
        params: juez,
        puesto: puesto,
        panel: panel,
      });
  }

  const deleteJuez = () => {
    sendServer(
      {
        domain: organizacion,
        app: 'global',
        action: 'deleteJuez',
        competencia_id: competencia_id,
        params: juez,
        puesto: puesto,
        panel: panel,
      });
    clear();
  }

  const clear = () => {
    clearJuez();
    setJuez(initial());
    setdeleteObject(false);
  }

  return (
    <>
      <Button variant="outline-primary" onClick={() => setJuez({ ...initial(), competencia_id: competencia_id })}>
        NUEVO JUEZ
      </Button>

      <Modal show={Boolean(juez.competencia_id)} onHide={clear}>
        <Modal.Header closeButton className={deleteObject ? 'btn-danger' : ''}>
          <Modal.Title>{deleteObject ? 'CONFIRMAR ELIMINAR' : (juez.id ? 'MODIFICAR' : 'NUEVO')} JUEZ</Modal.Title>
        </Modal.Header>
        <Form onSubmit={e => { e.preventDefault(); save() }}>
          <Modal.Body className='row'>
            <Form.Group className="mb-3 col-sm-4">
              <Form.Label className='bold'>Orden</Form.Label>
              <Form.Control
                autoFocus={Boolean(!juez.representacion_id)}
                type="number"
                name='orden'
                onChange={onChange}
                value={juez.orden}
                required={true}
              />
            </Form.Group>
            <Form.Group className="mb-3 col-sm-8">
              <Form.Label className='bold'>Representacion</Form.Label>
              <Form.Select
                autoFocus={Boolean(juez.representacion_id)}
                required={true}
                className='representacion_id'
                name='representacion_id'
                value={juez.representacion_id}
                onChange={onChange}
              >
                <option value={''} children={'Seleccione'} />
                {representaciones ? representaciones.map((r, index) => (
                  <option key={index} value={r.nombre} children={r.nombre} />
                )) : ''}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className='bold'>Nombre</Form.Label>
              <Form.Control
                type="text"
                name='nombre'
                onChange={onChange}
                value={juez.nombre}
                required={true}
              />
            </Form.Group>
            <Form.Group className="mb-3 col-sm-5">
              <Form.Label className='bold'>Funcion</Form.Label>
              <Form.Control
                type="text"
                name='funcion'
                onChange={onChange}
                value={juez.funcion}
                required={true}
              />
            </Form.Group>
            <Form.Group className="mb-3 col-sm-4">
              <Form.Label>Id Fig</Form.Label>
              <Form.Control
                type="number"
                name='id_fig'
                onChange={onChange}
                value={juez.id_fig}
              />
            </Form.Group>
            <Form.Group className="mb-3 col-sm-3">
              <Form.Label>Categoria</Form.Label>
              <Form.Control
                type="number"
                name='categoria'
                onChange={onChange}
                value={juez.categoria}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={deleteObject ? () => setdeleteObject(false) : clear}>
              CANCELAR
            </Button>
            {Boolean(juez.id) && !deleteObject ? <Button variant="danger" onClick={() => setdeleteObject(true)}>
              ELIMINAR
            </Button> : ''}
            {deleteObject ? <Button variant="danger" onClick={deleteJuez}>
              CONFIRMAR
            </Button> : ''}
            {!deleteObject ? <Button variant="primary" type='submit'>
              {juez.id ? 'GUARDAR' : 'CREAR'}
            </Button> : ''}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default ModaJueces;