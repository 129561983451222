import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import globalContext from '../../../context/globalContext';
import { items } from './ItemsHeader';

const Header = (props) => {
  const { modalidad, final, clases, categorias, aparatoImg, aparatos } = props;
  let { modalidad: tipoDiciplina } = useParams();
  const { state } = useContext(globalContext);
  const { competence } = state;

  if (!competence || !items[competence.modalidad]) return null; // Validar datos

  const modalidadItems = items[competence.modalidad];

  // Función para generar claves únicas
  const generateKey = (base, index) => `${base}-${index}`;

  if (tipoDiciplina === 'Ritmica' && modalidad === 'ALL AROUND') {
    return (
      <tr id="encabezadoResultados" className={clases}>
        {modalidadItems['all'].map((i, index) =>
          final && i.item === 'q' ? null : (
            <th
              key={generateKey(i.item, index)} // Clave única
              className={i.clases}
            >
              {typeof i.show === 'string'
                ? `${i.show}${
                    i.show === 'Gimnastas' && categorias
                      ? categorias.map((e) => ` / ${e ? e.toLowerCase() : ''}`).join('')
                      : ''
                  }`
                : i.show}
            </th>
          )
        )}
        {aparatos.map((i, index) => (
          <th
            key={generateKey(i.show, index)} // Clave única
            className="modalidad"
          >
            <div
              className="modalidad text-start hide_md_down ht12 w80 borderL"
              style={{
                backgroundImage: `url(https://consugi.consugisoft.com/static/media/${aparatoImg[i]})`,
              }}
            />
          </th>
        ))}
        <th className="text-end w80">TOTAL</th>
      </tr>
    );
  }

  const modalidadKey = modalidadItems.hasOwnProperty(modalidad) ? modalidad : 'generic';
  const itemsToRender = modalidadItems[modalidadKey];

  if (!itemsToRender) return null; // Validar datos

  return (
    <tr id="encabezadoResultados" className={clases}>
      {itemsToRender.map((i, index) =>
        final && i.item === 'q' ? null : (
          <th
            key={generateKey(i.item, index)} // Clave única
            className={i.clases}
          >
            {typeof i.show === 'string'
              ? `${i.show}${
                  i.show === 'Gimnastas' && categorias
                    ? categorias.map((e) => ` / ${e ? e.toLowerCase() : ''}`).join('')
                    : ''
                }`
              : i.show}
          </th>
        )
      )}
    </tr>
  );
};

export { Header };