import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import globalContext from '../../../context/globalContext';
import { items } from "./ItemsHeader";

const Header = (props) => {
  const {modalidad, final, clases, categorias, aparatoImg, aparatos } = props;
  let { modalidad: tipoDiciplina } = useParams();
  const { state } = useContext(globalContext);
  const { competence } = state;
  
  if (tipoDiciplina === 'Ritmica' && modalidad === 'ALL AROUND') return (
    <>
      <tr id="encabezadoResultados" className={clases} children={
        <>
          {items[competence.modalidad]['all'].map((i, index)=>
            final&&i.item==='q'?null:
              <th 
                key={i.show+index} 
                children={
                  typeof i.show === 'string'?
                  `${i.show}${i.show==='Gimnastas'?(categorias?categorias.map(e=>` / ${e?e.toLowerCase():''}`):''):''}`
                  :i.show
                }
                className={i.clases}
              />
          )}
          {aparatos.map((i, index)=>
            <th 
              key={i.show+index} 
              children={
                <div className="modalidad text-start hide_md_down ht12 w80 borderL" style={{backgroundImage:`url(https://consugi.consugisoft.com/static/media/${aparatoImg[i]})`}}/>
              }
              className={'modalidad'}
            />
          )}
          <th
            children={'TOTAL'}
            className={'text-end w80'}
          />
        </>
      }/>
    </>
  )

  if(competence) return (
    <>
      <tr id="encabezadoResultados" className={clases} children={
        <>{modalidad in items[competence.modalidad] || 'generic' in items[competence.modalidad]?
            items[competence.modalidad][items[competence.modalidad].hasOwnProperty(modalidad)?modalidad:'generic'].map((i, index)=>
              final&&i.item==='q'?null:
                <th 
                  key={i.show+index} 
                  children={
                    typeof i.show === 'string'?
                    `${i.show}${i.show==='Gimnastas'?(categorias?categorias.map(e=>` / ${e?e.toLowerCase():''}`):''):''}`
                    :i.show
                  }
                  className={i.clases}
                />
            )
          :null}
        </>
      }/>
    </>
  )
}

export {Header};