import { useContext, useEffect, useState } from 'react'
import globalContext from '../../../context/globalContext';
import { Header } from "../../competence/results/Header";
import { items } from "../../competence/results/ItemsHeader";
import ModaAsignacion from './ModalAsignacion';

export const SubTablePanel = (props) => {
  const { state } = useContext(globalContext);
  const { competence, lista_de_asignaciones,  lista_de_jueces } = state;
  const [localQuery, setLocalQuery] = useState([]);
  const [asignacion, setAsignacion] = useState({});
  const {panel, setPanel, juecesDict} = props;
  useEffect(() => {
    if(lista_de_asignaciones){
      let newQuery = [];
      lista_de_asignaciones.forEach(e =>{
        if(e.panel_id ===panel.id)newQuery.push({
          ...juecesDict[e.juez_id],
          orden:e.orden,
          puesto:e.puesto,
          asignacion:e
        })
      });
      setLocalQuery(newQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lista_de_asignaciones, juecesDict, lista_de_jueces])
  const clearAsignacion = ()=>setAsignacion({});
  return <table className="centered striped table mt50px" children={
    <>
      <thead children={
        <>
          <tr children={
            <>
            <th onClick={()=>setPanel(panel)} colSpan={5} className='text-center changeOver' children={<span children={<><span className='noPrint' children={`(${panel.orden}) `} />{panel.nombre_panel}</>}/>}/>
            </>
          }/>
          <tr className='noPrint' children={
            <>
            <th colSpan={5} className='text-center' children={<ModaAsignacion panelObject={panel} asignacion={asignacion} setAsignacion={setAsignacion} clearAsignacion={clearAsignacion} objects={localQuery}/>}/>
            </>
          }/>
          <Header modalidad={'panel'} clases={'borderSubTable title'}/>
        </>
      }/>
      <tbody children={
        <>
          {localQuery.map((q, index)=>
            <tr onClick={()=>setAsignacion(q.asignacion)} key={`${q.id}${index}`} className={q.class+'  changeOver'} children={
              <>
                {items[competence.modalidad]['panel'].map((i, index)=>
                  <td
                    key={`${q.id}${i}${index}`}
                    children={i.format&&q[i.item]?i.format(q[i.item]):q[i.item]}
                    className={i.clases}
                  />
                )}
              </>
            }/>
          )}
        </>
      }/>
    </>
  }/>
}