import React, { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useParams } from "react-router-dom";
import globalContext from '../../../context/globalContext';

const ModaAsignacion = (props) => {
  const { clearAsignacion, panelObject, asignacion, objects } = props;
  const { sendServer, sendState, state } = useContext(globalContext);
  const { success, lista_de_jueces } = state;
  let { competencia_id, organizacion, puesto, panel } = useParams();
  const initial= ()=>{
    let exist = Boolean(objects&&objects.length);
    return {
      competencia_id: null,
      panel_id: null,
      juez_id: null,
      orden:  exist?parseInt(objects[objects.length-1]['orden'])+1:'1',
      puesto: '',
    }
  }
  const [asignacionState, setAsignacionState] = useState(initial())
  const [deleteObject, setdeleteObject] = useState(false)
  useEffect(() => {
    if(asignacion.id)setAsignacionState({
      id: asignacion.id,
      competencia_id:asignacion.competencia_id,
      orden:asignacion.orden,
      juez_id:asignacion.juez_id,
      puesto:asignacion.puesto,
    })
  }, [asignacion])
  useEffect(() => {
    if(success){setAsignacionState(initial());sendState({success:null})}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success])

  const onChange = e => {
    setAsignacionState({ ...asignacionState, [e.target.name]: e.target.value })
  }
  const save = () => {
    sendServer(
      {
        domain: organizacion,
        app: 'global',
        action: asignacionState.id ? 'updateAsignacionesDePanel' : 'createAsignacionesDePanel',
        competencia_id: competencia_id,
        params: asignacionState,
        puesto: puesto,
        panel: panel,
      });
  }
  const deleteAsignacion = () => {
    sendServer(
      {
        domain: organizacion,
        app: 'global',
        action: 'deleteAsignacionesDePanel',
        competencia_id: competencia_id,
        params: asignacionState,
        puesto: puesto,
        panel: panel,
      });
    clear()
  }
  const clear = () => {
    clearAsignacion()
    setAsignacionState(initial())
    setdeleteObject(false)
  }
  return (
    <>
      <Button
        variant="outline-primary"
        onClick={() => setAsignacionState({
          ...initial(),
          competencia_id: competencia_id,
          panel_id: panelObject.id,
        })}
      >
        NUEVA ASIGNACIÓN
      </Button>

      <Modal show={Boolean(asignacionState.competencia_id)} onHide={clear}>
        <Modal.Header closeButton className={deleteObject ? 'btn-danger' : ''}>
          <Modal.Title>{panelObject.nombre_panel}<br/>{deleteObject ? 'CONFIRMAR ELIMINAR' : (asignacionState.id ? 'MODIFICAR' : 'NUEVA')} ASIGNACIÓN</Modal.Title>
        </Modal.Header>
        <Form onSubmit={e => { e.preventDefault(); save() }}>
          <Modal.Body className='row'>
            <Form.Group className="mb-3 col-sm-4">
              <Form.Label className='bold'>Orden</Form.Label>
              <Form.Control
                autoFocus={Boolean(!asignacionState.orden)}
                type="number"
                name='orden'
                onChange={onChange}
                value={asignacionState.orden}
                required={true}
              />
            </Form.Group>
            <Form.Group className="mb-3 col-sm-8">
              <Form.Label className='bold'>Puesto</Form.Label>
              <Form.Control
                autoFocus={Boolean(asignacionState.orden)}
                type="text"
                name='puesto'
                onChange={onChange}
                value={asignacionState.puesto}
                required={true}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className='bold'>Juez</Form.Label>
              <Form.Select required={true} className='representacion_id' name='juez_id' value={asignacionState.juez_id} onChange={onChange} children={
                <>
                  <option value={''} children={'Seleccione'}/>
                  {lista_de_jueces?lista_de_jueces.map(l=><option value={l.id} children={`${l.nombre} - (${l.orden}) -  ${l.representacion}`}/>):''}
                </>
              }/>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={deleteObject ? () => setdeleteObject(false) : clear}>
              CANCELAR
            </Button>
            {Boolean(asignacionState.id) && !deleteObject ? <Button variant="danger" onClick={() => setdeleteObject(true)}>
              ELIMINAR
            </Button> : ''}
            {deleteObject ? <Button variant="danger" onClick={deleteAsignacion}>
              CONFIRMAR
            </Button> : ''}
            {!deleteObject ? <Button variant="primary" type='submit'>
              {asignacionState.id ? 'GUARDAR' : 'CREAR'}
            </Button> : ''}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default ModaAsignacion;