import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import globalContext from "../context/globalContext";
import Form from 'react-bootstrap/Form';
import { Calendario } from "./editar/Calendario";
import { Paneles } from "./editar/Paneles";
const editores = {
  calendario: <Calendario/>,
  paneles:<Paneles/>,
}
export const Editor = () => {
  const [select, setselect] = useState('calendario')
  const { state, sendServer, sendState, URLdomain } = useContext(globalContext)
  const { competencia_id, organizacion } = useParams();
  const navigate = useNavigate();
  const { competence } = state;
  useEffect(() => {
    sendState({URLdomain:organizacion})
    sendServer({ domain: organizacion, app: 'global', action: 'getAsignations', id: competencia_id });
    sendServer({domain: organizacion, app:'global', action:'getCompetence', id:competencia_id});
    let urlParams = new URLSearchParams(window.location.search);
    console.log(urlParams);
    if(urlParams.get('e'))setselect(urlParams.get('e'))
  }, [])
  const onChange = e=>{
    setselect(e.target.value)
    navigate(`?e=${e.target.value}`);
  }
  if(!competence)return null;
  return (
    <>
      <div className="col text-center filter noPrint" children={
        <>
          <h6 className="text-center">Editar</h6>
          <Form.Select className='params' name='editar' value={select} onChange={onChange} children={
            <>
              <option value="calendario" children={'Calendario'}/>
              <option value="paneles" children={'Paneles'}/>
            </>
          }/>
        </>
      }/>
      <div className='tablePadding' children={editores[select]}/>
    </>
  )
}