import React, { useContext, useEffect, useState } from "react";
import globalContext from "../../../context/globalContext";
import { useParams } from "react-router-dom";
import { convenciones } from "../Convenciones";

export default function MedalleriaAA() {
  const URLdomain = "https://consugi.consugisoft.com";
  const { state, sendServer, URLdomainb, sendState } = useContext(globalContext);
  const { current, competence, online, ranking } = state;
  let { competencia_id, modalidad, organizacion } = useParams();
  const [params, setParams] = useState({ chroma: "black", panel: null });
  let urlParams = new URLSearchParams(window.location.search);

  const handleFullScreen = () => {
    const element = document.getElementById("full");

    if (!document.fullscreenElement) {
      // Solicitar fullscreen
      if (element.requestFullscreen) {
        element.requestFullscreen().catch((err) => {
          console.error("Error attempting to enable fullscreen:", err);
        });
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    } else {
      // Salir del fullscreen
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  useEffect(() => {
    if (urlParams.get("chroma")) {
      setParams({
        chroma: urlParams.get("chroma"),
        panel: urlParams.get("panel"),
      });
    }
  }, []);

  useEffect(() => {
    if (organizacion === state.URLdomain && competence) {
      sendServer({
        domain: organizacion,
        app: modalidad,
        action: "getRanking",
        competencia_id: competencia_id,
        panel: urlParams.get("panel"),
      });
      document.title = `Ranking`;
    } else {
      sendServer({
        domain: organizacion,
        app: "global",
        action: "getCompetence",
        id: competencia_id,
      });
      sendState({ URLdomain: organizacion });
    }
  }, [competence, URLdomain]);

  useEffect(() => {
    let interval = setInterval(() => {
      if (organizacion === state.URLdomain && competence && competence.activa) {
        sendServer({
          domain: organizacion,
          app: modalidad,
          action: "getRanking",
          competencia_id: competencia_id,
          panel: urlParams.get("panel"),
        });
      }
    }, 8000);
    return () => clearInterval(interval);
  }, [online, competence]);

  if (!current.id || !competence) return null;

  const sortedRanking = ranking
    ? [...ranking].sort((a, b) => {
        const aStatus = a.dns || a.dnf || a.dsq;
        const bStatus = b.dns || b.dnf || b.dsq;

        if (aStatus && !bStatus) return 1;
        if (!aStatus && bStatus) return -1;
        if (aStatus && bStatus) return 0;

        return (
          b[convenciones[modalidad]["total"]] -
          a[convenciones[modalidad]["total"]]
        );
      })
    : [];

  return (
    <div
      className="seccion-datos ranking h100"
      id="full"
      onClick={handleFullScreen} // Aquí se activa el fullscreen al hacer clic en cualquier parte del contenedor
      style={{ fontFamily: "'Archia', sans-serif", cursor: "pointer" }} // Cambia el cursor para indicar que es clickeable
    >
      <link rel="stylesheet" href="/static/css/fullScreen.css" />
      <link href="/static/css/estilos.css" rel="stylesheet" />

      <div>
        <header
          className="cabecera-titulo"
          style={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <img src="/static/img/ASU2025_B.png" />
          <div style={{ gap: "20px" }}>
            <div>
              <h1 style={{ fontSize: "70px" }}>
                FEMEMINO{" "}
                {current.concurso === "III" || current.final
                  ? "- ALL AROUND"
                  : null}{" "}
              </h1>
              <h2
                style={{
                  paddingTop: "10px",
                  fontSize: "40px",
                  paddingTop: "10px",
                }}
              >
                MEDALLERIA
              </h2>
            </div>
          </div>
          <img
            src={`/static/img/${competence.modalidad}.png`}
            alt="logo"
            style={{ marginLeft: "0%" }}
          />
        </header>

        <div className="datos">
          <div
            className="col-md-9 bottomBlock"
            style={{
              width: "100%",
            }}
          >
            <div className="marginAuto bordergreen gradient row fila-dato">
              <div className="text-truncate text-center col-2"></div>
              <div className="text-truncate col"></div>
              <div className="text-end col-3 text-yellow">RESULTADO</div>
            </div>

            <div className="scrollRank">
              {sortedRanking
                .filter((r) => !r.dns && !r.dnf && !r.dsq)
                .slice(0, 3)
                .map((r, index) => (
                  <div
                    className="marginAuto bordergreen gradient row fila-dato"
                    id={index + 1}
                    key={index + 1}
                    style={{
                      height: "30%",
                      display: "flex",
                      alignItems: "center",
                      gap: "25px",
                    }}
                  >
                    <div className="text-center col-1">
                      <img
                        src={`/static/img/${
                          index === 0
                            ? "medal_gold.png"
                            : index === 1
                            ? "medal_silver.png"
                            : "medal_bronze.png"
                        }`}
                        style={{ width: "100px", marginRight: "20px" }}
                        alt="medal"
                      />
                    </div>

                    <div
                      style={{
                        backgroundImage: `url('https://consugi.consugisoft.com/media/${
                          r[convenciones[modalidad]["bandera"]]
                        }')`,
                        marginRight: "5px",
                      }}
                      className="representacionChroma col-1"
                    ></div>

                    <div className="col" style={{ fontSize: "50px" }}>
                      {r.participante}
                    </div>

                    <div
                      className="text-end col-3"
                      style={{ fontSize: "1.2em", flex: "1", fontSize: "50px" }}
                    >
                      {modalidad !== "Trampolin"
                        ? `${
                            r.ranking_salto && r.aparato === "SALTO"
                              ? r.salto.toFixed(3)
                              : r[convenciones[modalidad]["total"]].toFixed(3)
                          }`
                        : r.total_global !== null
                        ? r.total_global.toFixed(3)
                        : ""}
                      <span style={{ color: "inherit", marginLeft: "5px" }}>
                        {r.siglas}
                      </span>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <footer>
        <img src="/static/img/footer.png" alt="Footer" />
      </footer>
    </div>
  );
}