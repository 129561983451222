import React, { useContext, useEffect, useState } from 'react'
import globalContext from '../../context/globalContext'
import { v4 as uuidv4 } from "uuid";
import { Link, useNavigate, useParams } from 'react-router-dom';

export default function Login() {
  const { state, URLdomain, sendServer, sendState } = useContext(globalContext);
  const { contenido, user, online } = state;
  const [stateLogin, setstateLogin] = useState({b27c3ee47c:'', c8323478d1f:''})
  const [view, setview] = useState(false)
  const navigate = useNavigate();
  let { organizacion } = useParams();

  useEffect(() => {
    if(organizacion!==state.URLdomain){
      sendState({URLdomain:organizacion})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.URLdomain])

  useEffect(() => {
    sendState({nav:false})
    let urlParams = new URLSearchParams(window.location.search);
    if (user && urlParams.get('next')) {
      navigate(urlParams.get('next'))
    }else if(user){
      navigate('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const onChange = e =>{
    setstateLogin({...stateLogin, [e.target.name]:e.target.value})
  }
  const sendLogin = e=>{
    e.preventDefault();
    if(stateLogin.b27c3ee47c ==='' || stateLogin.c8323478d1f ===''){
      sendState({message:{"id":uuidv4(),"bg":"info","title":"Completar campos","message":"Por favor, completa los campos de 'Username' y 'Password' para continuar con la autenticación.","delay":10000}})
    }else {
      if(!online)sendState({message:{"id":uuidv4(),"bg":"warning","title":"Offline","message":"Por favor verifica tu coneccion a internet.","delay":10000}})
      sendServer({domain: organizacion, app: 'jueces', action: 'login', data: stateLogin});
    }
  }
  return (
    <section className='w-100 p-4 d-flex justify-content-center pb-4 text-white' children={
      <form className='pb15vh' onSubmit={sendLogin} autoComplete='off'>
        <link rel="stylesheet" href="/static/css/gradient.css" />
        <link rel="stylesheet" href="/static/css/fullScreen.css" />
        <div
          className='w-100 logoLogin'
          onClick={() => navigate(`/${state.URLdomain}`)}
          style={{
            backgroundImage:`url('https://consugi.consugisoft.com/media/${contenido?contenido.logo:null}')`,
          }}
        />
        <h3 children='Login' className='text-center'/>
        <div className="form-outline mb-4">
          <input value={stateLogin.b27c3ee47c} onChange={onChange} autoFocus={true} name="b27c3ee47c" id="b27c3ee47c" className="form-control" />
          <label className="form-label" htmlFor="b27c3ee47c">Username</label>
        </div>
        <div className="form-outline mb-4">
          <div className="input-group">
            <input value={stateLogin.c8323478d1f} onChange={onChange} type={view?'text':'password'} name="c8323478d1f" className="form-control password"/>
            <button className="btn btn-outline-secondary view" onClick={()=>setview(!view)} type="button">{!view?'🙈':'🙉'}</button>
          </div>
          <label className="form-label" htmlFor="b27c3ee47c">Password</label>
        </div>
        
        <button type="submit" className="btn btn-primary btn-block mb-4 w100">Sign in</button>
        <Link to={`/${state.URLdomain}`} children='Home' className='noDecoration'/>
      </form>
    } />
  )
}
