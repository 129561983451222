const globalReducer = (state, action) => {
  switch (action.type) {
    case 'DATA_CHANGE':
      let newState = { ...state };
      for (const property in action.payload) {
        if(property!=='message'){
          newState = { ...newState, [property]: action.payload[property] };
        }else{
          // newState = { ...newState, messages: [...newState.messages, action.payload[property]] };
          newState = { ...newState, messages: [action.payload[property]] };
        }
      }
      return newState;
    default:
      return state;
  }
};
export default globalReducer;