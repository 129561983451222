import React, { useCallback, useContext, useEffect, useState } from 'react'
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Modal from 'react-bootstrap/Modal'
import { useNavigate, useParams } from 'react-router-dom';
import globalContext from '../../../context/globalContext';
import { calcularE, diferencia, mediana } from './Operaciones';
import { notasView, saltosView, caracteres, elementosMax } from './View';
import { v4 as uuidv4 } from "uuid";
import Button from 'react-bootstrap/Button';

export default function Arbitro() {
  const {state, sendServer, URLdomain, sendState} = useContext(globalContext);
  const {competence, current, success, authorized, orden_de_paso } = state;
  const { id, publicado, saltos, aparato, ejecución_1, ejecución_2, ejecución_3, ejecución_4, ejecución_5, ejecución_6} = current;
  let { competencia_id, modalidad, panel, organizacion } = useParams();
  const [inscripcion, setInscripcion] = useState(null)
  const [medias, setMedias] = useState([])
  const [control, setcontrol] = useState({anterior:null, siguiente:null})
  const initialNotas = {
    total_ejecucion:0,
    total_general:0,
  }
  const [indexE, setindexE] = useState(null)
  const [notas, setnotas] = useState(initialNotas)
  const [puesto, setpuesto] = useState('')
  const [del, setdel] = useState(false)
  const initialFilter = {modalidad:'', edad:'', gimnasta:'', concurso:'', rotacion:''};
  const [filter, setfilter] = useState(initialFilter);
  const [modalidades, setmodalidades] = useState([])
  const [edades, setedades] = useState([])
  const [orden_de_paso_local, setorden_de_paso_local] = useState([]);
  
  const navigate = useNavigate();
  useEffect(() => {// Control de acceso!!!!!!!!!
    if(authorized===false){
      navigate(`/${organizacion}/${modalidad}/${competencia_id}`)
    }else if(authorized===undefined && organizacion===state.URLdomain){
      sendServer({
        domain: organizacion,
        app: modalidad,
        action:'authorized',
        puesto: 'dificultad',
        panel: panel,
        competencia_id: competencia_id,
      })
      sendServer({domain: organizacion, app:'global', action:'getAsignations', id:competencia_id})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorized, state.URLdomain])
  useEffect(() => {// Medias
    let newMedia = [];
    saltosView[aparato!==undefined?aparato:'IND'].forEach((e, index)=>{
      if((saltos&&index<saltos)||(saltos+1===saltosView[aparato!==undefined?aparato:'IND'].length)){
        newMedia.push(mediana(
          [ejecución_1[index], ejecución_2[index], ejecución_3[index],
          ejecución_4[index], ejecución_5[index], ejecución_6[index]],
          aparato!==undefined?aparato:'IND',
        ))
      }
    })
    setMedias(newMedia)
    let total_ejecucion = calcularE(newMedia, saltos, aparato);
    let total_general = 0;


    if(aparato==='IND'){
      [total_ejecucion, current.desplazamiento_horizontal, current.dificultad, current.tiempo_de_vuelo].forEach((n)=>{
        if(n!==null){
          total_general+=n;
        }
      })
    }else if(aparato==='SYN'){
      [total_ejecucion, current.desplazamiento_horizontal, current.dificultad, current.sincronizacion].forEach((n)=>{
        if(n!==null){
          total_general+=n;
        }
      })
    }else if(aparato==='DMT'||state.aparato==='TUM'){
      [total_ejecucion, current.dificultad].forEach((n)=>{
        if(n!==null){
          total_general+=n;
        }
      })
    }
    if(current.penalizacion!==null){
      total_general-=current.penalizacion;
    }
    if(total_general<0){
      total_general=0;
    }else{
      total_general = Math.round10(total_general, -3);
    }
    setnotas({
      ...notas, 
      total_ejecucion: total_ejecucion,
      total_general: total_general,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ejecución_1, ejecución_2, ejecución_3, ejecución_4, ejecución_5, ejecución_6])
  
  useEffect(() => {
    getCurrent();
    if(competence)sendServer({
      domain: organizacion,
      app: modalidad,
      action:'getOrdenDePaso',
      competencia_id: competencia_id,
      panel:panel,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  useEffect(() => {// filtrado
    if(!edades.length && orden_de_paso){
      let newEdades = [];
      let modalidades = [];
      // eslint-disable-next-line array-callback-return
      orden_de_paso.map(o=>{
        if(!newEdades.includes(o.categoria_edad))newEdades.push(o.categoria_edad);
        if(!modalidades.includes(o.aparato))modalidades.push(o.aparato);
      })
      setedades(newEdades);
      setmodalidades(modalidades);
    }
    if(orden_de_paso){// && orden_de_paso.length
      setorden_de_paso_local(
        orden_de_paso.filter(o=>
          (
            (filter.modalidad===''||filter.modalidad===o.aparato)
            && (filter.edad===''||filter.edad===o.categoria_edad)
            && (filter.concurso===''|| ((filter.concurso==='final' && o.final===true)||(filter.concurso==='clasificación' && o.final===false)) )
            && (filter.gimnasta===''||o.participante.toLowerCase().includes(filter.gimnasta.toLowerCase()))
            && (filter.rotacion===''||Number(filter.rotacion)===o.rotacion)
          )
        )
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orden_de_paso, filter])
  
  useEffect(() => {// anterior - siguiente
    if(orden_de_paso_local){
      let anterior,actual,siguiente;
      // eslint-disable-next-line array-callback-return
      orden_de_paso_local.map((gimnasta)=>{
        if(id===gimnasta.id){
          actual = gimnasta.id;
        }else if(!actual){
          anterior = gimnasta.id;
        }else if(actual && !siguiente){
          siguiente = gimnasta.id;
        }
      })
      setcontrol({anterior:anterior, siguiente:siguiente})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, orden_de_paso_local])
  useEffect(() => {
    // Cerrar modal
    if(success){
      setpuesto('');
      setUserText('');
      setdel(false)
      sendState({success:false})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success])
  useEffect(() => {
    let interval = setInterval(() => {
      getCurrent();
    }, 3000);
    return ()=> clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ competence, competencia_id, inscripcion])
  useEffect(() => {
      getCurrent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inscripcion])
  const getCurrent = () =>{
    if(organizacion===state.URLdomain){
      sendServer({
        domain: organizacion,
        app: modalidad,
        action:'getCurrent',
        panel: panel,
        competencia_id: competencia_id,
        id: inscripcion,
      })
    }
  }
  // Teclado fisico
  const [userText, setUserText] = useState("");
  const handleUserKeyPress = useCallback(event => {
    const { key, keyCode } = event;
    if(caracteres.includes(key)){
      setUserText(prevUserText => {
        if(puesto.includes('ejecución_')){
          if(key==='.')return prevUserText;
          if(indexE!==null&&indexE<=saltos){
            let newArray = [...prevUserText];
            newArray[indexE] = Number(key);
            setindexE(prevIndexE=>prevIndexE+1)
            return newArray;
          }else if(saltos>prevUserText.length||(saltos===elementosMax[aparato]&&prevUserText.length===elementosMax[aparato])){
            return [...prevUserText,Number(key)];
          // La siguiente linea es para el 10 en el landing
          }else if(saltos===elementosMax[aparato]&&(prevUserText.length===elementosMax[aparato]+1)&&(prevUserText[elementosMax[aparato]]===1)&&key==='0'){
            let newArray = [...prevUserText];
            newArray[elementosMax[aparato]] = 10
            return newArray;
          }else {
            sendState({message:{"id":uuidv4(),"bg":"warning","title":`Calificando sobre ${saltos?saltos:0} elementos `,"message":"No puedes ingresar mas elementos.","delay":10000}})
            return prevUserText;
          }
        }else if(prevUserText.match(/[.]/) && key==='.'){
          console.log('No puedes poner dos puntos')
        }else if(prevUserText.length > 4){
          console.log('maximo 5 caracteres');
        }else if(parseFloat(`${prevUserText}${key}`) > 50){
          console.log('El valor maximo es 10');
        }else{
          return `${prevUserText}${key}`
        }
        return `${prevUserText}`
      })
    }else if(keyCode === 8){// <= borrar
      console.log(keyCode)
      if(puesto.includes('ejecución_')){
        setUserText(prevUserText=>prevUserText.slice(0,prevUserText.length-1))
      }else if(puesto!==''){
        setUserText(prevUserText => `${prevUserText.substr(0, prevUserText.length-1)}`)
      }
    }else if(keyCode === 46){// suprimir
      if(puesto!==""){
        setUserText(``)
      }else{
        publicar(false)
      }
    }else if(keyCode === 188){
      setUserText(prevUserText => {
        if(prevUserText.match(/[.]/))return `${prevUserText}`
        return `${prevUserText}.`
      })
    }else if(keyCode === 13){// Enter
      if(puesto!==""){
        setUserText(prevUserText => {
          cargarNota(prevUserText)
          return `${prevUserText}`
        })
      }else{
        publicar(true)
      }
    }else if(keyCode === 37 && control.anterior){// izquierda
      cambiarGimnasta(control.anterior)
    }else if(keyCode === 39 && control.siguiente){// derecha
      cambiarGimnasta(control.siguiente)
    }
    // console.log(event)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, puesto, saltos, indexE]);

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  useEffect(() => {// actualizar orden de paso con current
    if(orden_de_paso&&orden_de_paso.length){
      let newOrdenDePaso = orden_de_paso.map(o=>{
        if(o.id!==current.id) return o;
        return {...o, publicado: current.publicado, total_general: current.total_general}
      });
      sendState({orden_de_paso:newOrdenDePaso})
    }
  }, [publicado, id]);
  // End teclado fisico
  const cargarNota =(nota)=>{
    if(nota==='')nota=null;
    sendServer({
      domain: organizacion,
      app: modalidad,
      action:'cargarNota',
      puesto: 'Arbitro',
      panel: panel,
      competencia_id: competencia_id,
      id:inscripcion,
      data:{
        id:current.id,
        competencia_id:current.competencia,
        [puesto]:nota,
        publicado:false,
      }
    })
  }
  const cargarCantSaltos =(nota)=>{
    if(nota==='')nota=null;
    sendServer({
      domain: organizacion,
      app: modalidad,
      action:'cargarNota',
      puesto: 'Arbitro',
      panel: panel,
      competencia_id: competencia_id,
      id: inscripcion,
      data:{
        id:current.id,
        competencia_id:competencia_id,
        saltos:nota,
        publicado:false,
      }
    })
  }
  const publicar = (publicado)=>{
    sendServer({
      domain: organizacion,
      app: modalidad,
      action:'cargarNota',
      puesto: 'dificultad',
      panel: panel,
      getOrdenDePaso: true,
      competencia_id: competencia_id,
      id: inscripcion?inscripcion:id,
      data:{
        ...notas,
        id:current.id,
        competencia_id:current.competencia,
        publicado:publicado,
      }
    })
  }
  const cambiarGimnasta = id =>{
    setInscripcion(null)
    if(id)sendServer({
      domain: organizacion,
      app: modalidad,
      action:'cambiarGimnasta',
      puesto: 'dificultad',
      panel: panel,
      getOrdenDePaso: false,
      competencia_id: competencia_id,
      id:id
    })
  }
  const clearInscription=()=>{
    sendServer({
      domain: organizacion,
      app: modalidad,
      action:'cargarNota',
      puesto: 'dificultad',
      panel: panel,
      getOrdenDePaso: true,
      competencia_id: competencia_id,
      id: inscripcion?inscripcion:id,
      data:{
        id:current.id,
        publicado: false,
        ejecución_1: [],
        ejecución_2: [],
        ejecución_3: [],
        ejecución_4: [],
        ejecución_5: [],
        ejecución_6: [],
        saltos:null,
        total_ejecucion: null,
        dificultad: null,
        tiempo_de_vuelo: null,
        desplazamiento_horizontal: null,
        sincronizacion: null,
        penalizacion: null,
        total_general: null,
      }
    })
  }
  const onChange = e =>{
    let newFilter = {...filter,[e.target.name]:e.target.value};
    if(e.target.value==='Limpiar'){
      newFilter=initialFilter;
    }
    setfilter(newFilter)
    let newUrl = '?'
    for (let f in newFilter) {
      if(newFilter[f]!==''){
        newUrl+=`${newUrl==='?'?'':'&'}${f}=${newFilter[f]}`
      }
    }
    navigate(newUrl);
  }
  return (
    <>
      <p className='noPrint' children={
        <>
          {current.id ?
            <>
              <img className='mini' src={`https://consugi.consugisoft.com/media/${current.representacion__bandera}`} alt='Bandera' />
              <span
                children={`(${current.representacion}) ${current.categoria_edad} - ${current.aparato} - ${current.participante}`}
              />
            </>
            : null}
        </>
      } />
      <Row className='marginAuto bold' children={
        <>
          <Col
            className={`noPadding text-center btn teclaNota ${saltos===null?'btn-primary bold':'btn-info'}`}
            children='-'
            onClick={()=>cargarCantSaltos(null)}
          />
          {
            saltosView[aparato!==undefined?aparato:'IND'].map((s, index)=>
              <Col
                className={`noPadding text-center btn teclaNota ${saltos===s.val?'btn-primary bold':'btn-info'}`}
                children={s.show}
                onClick={()=>cargarCantSaltos(s.val)}
                key={s.show}
              />
            )
          }
        </>
      }/>
      {
        [1,2,3,4,5,6].map((e, index) =>
          <Row
            className='marginAuto'
            key={`e${e}`}
            children={
              <>
                <Col
                  className='noPadding text-center btn teclaNota bold btn-primary'
                  children={`E${e}`}
                  onClick={()=>{setpuesto(`ejecución_${e}`);setUserText(JSON.parse('['+current[`ejecución_${e}`]+']'));setindexE(null)}}
                  />
                {
                  saltosView[aparato!==undefined?aparato:'IND'].map((ee, index)=>{
                    if((saltos&&index<saltos)||(saltos+1===saltosView[aparato!==undefined?aparato:'IND'].length)){
                      return <Col
                        key={`e${e}${index}`}
                        className={`noPadding text-center notasE ${diferencia([current[`ejecución_${e}`][index], medias[index]], aparato)}`}
                        children={current[`ejecución_${e}`][index]}
                        onClick={()=>{setpuesto(`ejecución_${e}`);setUserText(JSON.parse('['+current[`ejecución_${e}`]+']'));setindexE(index<current[`ejecución_${e}`].length?index:null)}}
                      />
                    }else {
                      return <Col key={`e${e}${index}`} className='noPadding text-center'/>
                    }
                  })
                }
              </>
            }
          />
        )
      }
      <Row className='marginAuto success' children={
            <>
              <Col className='noPadding text-center bold' children={`Me`}/>
              {
                saltosView[aparato!==undefined?aparato:'IND'].map((ee, index)=>{
                  if((saltos&&index<saltos)||(saltos+1===saltosView[aparato!==undefined?aparato:'IND'].length)){
                    return <Col
                      key={`me${index}`}
                      className='noPadding text-center' 
                      children={medias[index]}
                    />
                  }else {
                    return <Col key={`me${index}`}  className='noPadding text-center'/>
                  }
                })
              }
            </>
      }/>
      <Row className='marginAuto' children={
        <>
          <Col className={`noPadding text-center pre-wrap text-white bold ${current['total_ejecucion']===notas.total_ejecucion?'success':'warning'}`} children={
            `E\n${notas.total_ejecucion.toFixed(2)}`
          }/>
          {
            notasView[aparato!==undefined?aparato:'IND'].map((e, index)=>
              <Col
                key={`nt${index}`}
                className={`noPadding text-center pre-wrap teclaNota ${e.class}`}
                children={
                  `${e.show}\n${current[e.val]?current[e.val].toFixed(e.fixed):''}`
                }
                onClick={()=>{setpuesto(e.val);setUserText(`${current[e.val]?current[e.val]:''}`)}}
              />
            )
          }
          <Col
            className={`noPadding text-center pre-wrap text-white bold ${current['total_general']===notas.total_general?'success':'warning'}`}
            children={
            `Total\n${notas.total_general.toFixed(3)}`
            }
          />
        </>
      }/>
      <Row className="controles marginAuto noPrint mt10" children={
        <>
          <Col onClick={()=>cambiarGimnasta(control.anterior)} className={`fs20 btn-div btn-${control.anterior?"primary":"disabled"}`} children={'◄'}/>
          <Col onClick={()=>cambiarGimnasta(control.siguiente)} className={`fs20 btn-div btn-${control.siguiente?"primary":"disabled"}`} children={'►'}/>
          <Col onClick={()=>setdel(true)} className={`btn-div btn-danger`} children={'Del'}/>
          <Col onClick={current.publicado?()=>publicar(false):()=>{}} className={`btn-div btn-${current.publicado?"warning":"disabled"}`} children={'Desp'}/>
          <Col onClick={!current.publicado?()=>publicar(true):()=>{}} className={`btn-div btn-${!current.publicado?"success":"disabled"}`} children={'Enviar'}/>
        </>
      }/>
      
      <div className='tableContainer' children={
        <table className='mt30 w100 table table-striped' children={
          <>
            <thead children={
              <>
                <tr children={
                  <th colSpan={40} className='text-center' children={
                    <>
                      ORDEN DE PASO
                      <Row className="controles marginAuto noPrint mt30" children={
                        <>
                          <Col xs={6} sm={4} md={3} lg={2}className="mt10" children={
                            <>
                              <label children={'_'}/>
                              <input className="w100" type='button' value='Limpiar' onClick={onChange}/>
                            </>
                          }/>
                          <Col xs={6} sm={4} md={3} lg={2}className="mt10" children={
                            <>
                              <label children={'Final/Clasificación'}/>
                              <select className='w100' name='concurso' value={filter.concurso} onChange={onChange} children={
                                <>
                                  <option></option>
                                  <option value="clasificación">Clasificación</option>
                                  <option value="final">Final</option>
                                </>
                              }/>
                            </>
                          }/>
                          <Col xs={6} sm={4} md={3} lg={2}className="mt10" children={
                            <>
                              <label children={'Modalidad'}/>
                              <select className='w100' name='modalidad' value={filter.modalidad} onChange={onChange} children={
                                <>
                                  <option></option>
                                  {modalidades.map(e=><option key={e} value={e}>{e}</option>)}
                                </>
                              }/>
                            </>
                          }/>
                          <Col xs={6} sm={4} md={3} lg={2}className="mt10" children={
                            <>
                              <label children={'Edad'}/>
                              <select className='w100' name='edad' value={filter.edad} onChange={onChange} children={
                                <>
                                  <option></option>
                                  {edades.map(e=><option key={e} value={e}>{e}</option>)}
                                </>
                              }/>
                            </>
                          }/>
                          <Col xs={6} sm={4} md={3} lg={2}className="mt10" children={
                            <>
                              <label children={'Gimnasta'}/>
                              <input className='w100' type='text' name='gimnasta' value={filter.gimnasta} onChange={onChange}/>
                            </>
                          }/>
                          <Col xs={6} sm={4} md={3} lg={2}className="mt10" children={
                            <>
                              <label children={'Rotacion'}/>
                              <input className='w100' type='number' name='rotacion' value={filter.rotacion} onChange={onChange}/>
                            </>
                          }/>
                        </>
                      }/>
                      
                    </>
                  }/>
                  }/>
                <tr children={
                  <>
                    <th children={'#'} className='text-end'/>
                    <th children={'Gimnastas'}/>
                    <th children={'Rep.'}/>
                    <th children={'Mod.'}/>
                    <th children={'Edad'}/>
                    <th children={'Rot.'}/>
                    <th children={'Total'}/>
                  </>
                }/>
              </>
            }/>
            <tbody children={
              <>
                {orden_de_paso_local?
                  orden_de_paso_local.map(i=>
                    <tr 
                      key={i.id}
                      className={`click ${i.id===current.id&&current.puntuando===true?'btn-primary':(i.id===current.id?'btn-warning':'')}`}
                      children={
                        <>
                          <td children={i.orden_de_salida} onClick={()=>setInscripcion(i.id)} className='text-end'/>
                          <td onClick={()=>cambiarGimnasta(i.id)} children={i.participante}/>
                          <td onClick={()=>cambiarGimnasta(i.id)} children={i.representacion}/>
                          <td onClick={()=>cambiarGimnasta(i.id)} children={i.aparato!=='SALTO'?i.aparato:(i.primer_salto?'SALTO 1':'SALTO 2')}/>
                          <td onClick={()=>cambiarGimnasta(i.id)} children={i.categoria_edad}/>
                          <td onClick={()=>cambiarGimnasta(i.id)} children={i.rotacion}/>
                          <td onClick={()=>cambiarGimnasta(i.id)} children={i.total_general!==null?i.total_general.toFixed(3):''} className={`text-end ${i.publicado?'success':''}`}/>
                        </>
                      }
                    />
                  )
                :null}
              </>
            }/>
          </>
        }/>
      }/>
      <Modal show={Boolean(puesto)} fullscreen={true} onHide={() => setpuesto('')}>
        <Modal.Header closeButton>
          <Modal.Title>
            <p className='noPrint' children={
              <>
                {current.id ?
                  <>
                    <img className='mini' src={`https://consugi.consugisoft.com/media/${current.representacion__bandera}`} alt='Bandera' />
                    <span
                      children={`(${current.representacion}) ${current.categoria_edad} - ${current.aparato} - ${current.participante}`}
                    />
                  </>
                  : null}
              </>
            } />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className='row marginAuto'>
          <div className='actionsContainer' children={
            <>
              <div className='text-center userTextCont' children={
                <>
                  {typeof(userText)==='string'?
                    <b className='userText' children={userText}/>
                  :
                  userText.map((u, index)=>
                    <span 
                      className={`notasE fs25px ${index===indexE?'indexSelect':''}`}
                      children={u}
                      onClick={()=>setindexE(index)}
                    />
                  )
                  }
                </>
              } />
              <div className='text-center puesto' children={puesto.replace('_', ' ').replace('_total', ' ')} />
              <div className="btn-group col-12" role="group" aria-label="Basic mixed styles example">
                {Boolean(userText===''||userText.length===0)?
                  <button type="button" className="btn btn-danger" onClick={()=>setpuesto('')}>Cancel</button>
                :
                  <button type="button" className="btn btn-danger" onClick={()=>setUserText(puesto.includes('ejecución_')?[]:'')}>Del</button>
                }
                <button type="button" className="btn btn-success" onClick={()=>cargarNota(userText)}>Enviar</button>
              </div>
            </>
          }/>
          <div className='tecladoContainer' children={
            <>
            {caracteres.map(n=><button type="button" key={n} onClick={()=>handleUserKeyPress({key:n})} className="btn btn-primary" children={<span children={n}/>}/>)}
            <button
              type="button"
              className="btn btn-primary"
              onClick={()=>handleUserKeyPress({key:'◄', keyCode: 8})}
              children={<span children={'◄'}/>}
              />
            </>
          }/>
        </div>
        </Modal.Body>
      </Modal>
      <Modal show={del} onHide={() => setdel(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar Notas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='noPrint' children={
            <>
              {current.id ?
                <>
                  <img className='mini' src={`https://consugi.consugisoft.com/media/${current.representacion__bandera}`} alt='Bandera' />
                  <span
                    children={`(${current.representacion}) ${current.categoria_edad} - ${current.aparato} - ${current.participante}`}
                  />
                </>
                : null}
            </>
          } />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setdel(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={() => clearInscription()}>
            Eliminar notas
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}