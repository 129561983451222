import React, { useContext, useEffect, useState } from "react";
import globalContext from "../../../context/globalContext";
import { useParams } from "react-router-dom";
import { convenciones } from "../Convenciones";

export default function Ranking() {
  const URLdomain = "https://consugi.consugisoft.com";
  const { state, sendServer, URLdomainb, sendState } = useContext(globalContext);
  const { current, competence, online, ranking } = state;
  let { competencia_id, modalidad, organizacion } = useParams();
  const [params, setParams] = useState({ chroma: "black", panel: null });
  let urlParams = new URLSearchParams(window.location.search);

  // Configuración segura de campos por modalidad
  const camposModalidad = convenciones[modalidad] || {
    total: "total",
    siglas: "representacion__siglas",
    bandera: "representacion__bandera",
    aparato: "categoria_de_participacion",
    ejecución_final: "ejecución_final"
  };

  const handleFullScreen = () => {
    const element = document.getElementById("full");
    if (!element) return;

    if (!document.fullscreenElement) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  useEffect(() => {
    if (urlParams.get("chroma")) {
      setParams({
        chroma: urlParams.get("chroma"),
        panel: urlParams.get("panel"),
      });
    }
  }, []);

  useEffect(() => {
    if (organizacion === state.URLdomain && competence) {
      sendServer({
        domain: organizacion,
        app: modalidad,
        action: "getRanking",
        competencia_id: competencia_id,
        panel: urlParams.get("panel"),
      });
      document.title = `Ranking`;
      try {
        const fullElement = document.getElementById("full");
        if (fullElement) {
          fullElement.requestFullscreen().catch(e => console.log("Fullscreen error:", e));
        }
      } catch (e) {
        console.error("Fullscreen error:", e);
      }
    } else {
      sendServer({
        domain: organizacion,
        app: "global",
        action: "getCompetence",
        id: competencia_id,
      });
      sendState({ URLdomain: organizacion });
    }
  }, [competence, URLdomain]);

  useEffect(() => {
    let interval = setInterval(() => {
      if (organizacion === state.URLdomain && competence && competence.activa) {
        sendServer({
          domain: organizacion,
          app: modalidad,
          action: "getRanking",
          competencia_id: competencia_id,
          panel: urlParams.get("panel"),
        });
      }
    }, 8000);
    return () => clearInterval(interval);
  }, [online, competence]);

  let cantidades = [0, 9, 16, 23, 30, 37];
  let listScroll = [0, 354, 708, 1062, 1416, 1770];
  const [currentscroll, setcurrentscroll] = useState(0);

  useEffect(() => {
    if (!urlParams.get("noScroll")) {
      let interval = setInterval(() => {
        const scrollElement = document.querySelector("div.scrollRank");
        if (scrollElement && ranking?.length > 0) {
          if (ranking.length > cantidades[currentscroll + 1]) {
            setcurrentscroll(currentscroll + 1);
          } else {
            setcurrentscroll(0);
          }
          scrollElement.scrollTo(0, listScroll[currentscroll]);
        }
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [currentscroll, ranking?.length]);

  if (!current?.id || !competence) return null;

  const sortedRanking = ranking
    ? [...ranking].sort((a, b) => {
        if (a.dns && !b.dns) return 1;
        if (!a.dns && b.dns) return -1;
        return (b.total || 0) - (a.total || 0);
      })
    : [];

  return (
    <div
      className="seccion-datos ranking h100"
      id="full"
      onClick={handleFullScreen}
      style={{ padding: "0%" }}
    >
      <link rel="stylesheet" href="/static/css/fullScreen.css" />
      <link href="/static/css/estilos.css" rel="stylesheet" />
      <div>
        <header
          className="cabecera-titulo"
          style={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <img src="/static/img/ASU2025_B.png" style={{ marginLeft: "0%" }} alt="Logo ASU 2025" />
          
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <h1 style={{ fontSize: "60px", margin: 0 }}>
              {competence?.modalidad?.toUpperCase() || "PARKOUR"}
            </h1>

            <h2 style={{ paddingTop: "10px", fontSize: "30px", margin: 0 }}>
              FEMENINA{" "}
              {current?.concurso === "III" || current?.final ? "- FINAL" : null}{" "}
              - {current?.[camposModalidad.aparato] || "PARKOUR"}
            </h2>
          </div>
          
          <img
            src={`/static/img/${competence?.modalidad || "Parkour"}.png`}
            alt="Logo modalidad"
            style={{ marginLeft: "0%", width: "100px" }}
          />
        </header>
        
        <div className="datos">
          <div
            className="col-md-9 bottomBlock"
            style={{ width: "100%" }}
          >
            <div
              className="text-center col-3 text-yellow"
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: "30px",
              }}
            >
              <h1 style={{ fontSize: "40px", fontWeight: "bolder" }}>RESULTADO</h1>
            </div>

            <div className="scrollRank" style={{ maxHeight: "67vh" }}>
              {sortedRanking?.length > 0 ? (
                sortedRanking.map((r, index) => (
                  <div
                    className="marginAuto bordergreen gradient row fila-dato"
                    id={`rank-${index}`}
                    key={`${r.participante}-${index}`}
                    style={{
                      height: "0%",
                      display: "flex",
                      alignItems: "start",
                      justifyContent: "space-between",
                      flexGrow: "1",
                      padding: "none",
                    }}
                  >
                    <div
                      className="text-center col-1"
                      style={{ fontSize: "50px" }}
                    >
                      {index + 1}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "15%",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        className="col-1"
                        style={{ color: "yellow", fontSize: "50px" }}
                      >
                        {r[camposModalidad.siglas] || ""}{" "}
                      </div>
                      <div
                        style={{
                          backgroundImage: `url('https://consugi.consugisoft.com/media/${
                            r[camposModalidad.bandera] || ""
                          }')`,
                        }}
                        className="representacionChroma col-1"
                      ></div>
                    </div>

                    <div className="col" style={{ fontSize: "50px" }}>
                      {r.participante || ""}
                    </div>

                    <div
                      className="text-end"
                      style={{
                        fontSize: "50px",
                        width: "13%",
                      }}
                    >
                      {r.dns ? "DNS" : (r.total?.toFixed(3) || "0.000")}
                    </div>
                  </div>
                ))
              ) : (
                <div style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  fontSize: "30px",
                  color: "white"
                }}>
                  No hay resultados disponibles
                </div>
              )}
            </div>
          </div>
        </div>
        <footer>
          <img src="/static/img/footer.png" alt="Footer" style={{ width: "100%" }} />
        </footer>
      </div>
    </div>
  );
}