import React from "react";
import ReactDOM from "react-dom/client";
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import GlobalState from "./context/GlobalState";
import { BrowserRouter } from "react-router-dom";
// import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<GlobalState children={<BrowserRouter children={<App/>}/>}/>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
if(window.location.host!=='localhost:3000'){
    serviceWorkerRegistration.register();
}else{
    serviceWorkerRegistration.unregister();
}
// reportWebVitals();

/*
/home/aerobica/gimnasia/gym_front/node_modules/webpack-dev-server/client/clients/WebSocketClient.js
14:
    if(url.includes('wss://react.consugisoft.com')){
      url = 'wss://react.consugisoft.com/ws'
    }
*/