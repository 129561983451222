const mediana = (lista, aparato) =>{
  lista = lista.filter(l=>l!==undefined)
  if(lista.length===0)return undefined
  if(aparato==='SYN'){
    let valor = 0.0;
    if(lista.length!==6){
      lista.map((e)=>valor+=e)
      valor = Math.round10(valor/lista.length, -2)
    }else{
      let m1 = lista.splice(0,(lista.length/2));//rev
      let m2 = lista.splice(0,lista.length);//rev
      m1.sort((a,b) => a-b);
      m2.sort((a,b) => a-b);
      valor = Math.round10((m1[1]+m2[1])/2, -2)
    }
    return valor;
  }else{
    lista.sort((a,b) => a-b);
    if(lista.length===4||lista.length===3){
      lista.splice(lista.length-1,1)
      lista.splice(0,1)
    }else if(lista.length===6||lista.length===5){
      lista.splice(lista.length-2,2)
      lista.splice(0,2)
    }
    let valor = 0;
    lista.map((e)=>valor+=e)
    if(lista.length%2){
      return Math.round10((valor/lista.length)*2, -2)
    }
    return valor;
  }
}
const calcularE = (array, saltos, aparato='IND') => {
  array = array.filter(l=>l!==undefined)
  if(saltos===null||array.length===0)return 0;
  let sum = 0;
  let total_ejecucion = 0;
  array.forEach((e)=>sum+=e)
  if(aparato==="IND"){// 18.2.3.2
    total_ejecucion= Math.round10((saltos*2)-(sum*0.1), -3)
  }else if(aparato==="SYN"){// 18.2.5.3
    total_ejecucion= Math.round10(saltos-(sum*0.1), -3)
  }else if(aparato==="DMT"){//18.2.3.2 - 16.5:34
      total_ejecucion= Math.round10(((saltos+8)*2)-(sum*0.1), -2);
  }else if(aparato==="TUM"){//16.4 pag 24 (a la cantidad de elementos válidos más dos (2) puntos)
    total_ejecucion= Math.round10(((saltos+2)*2)-(sum*0.1), -2);
  }
  return total_ejecucion;
}
const diferencia = (array, aparato) => {
  if(aparato==='IND')array[1]=array[1]/2;
  array.sort((a,b) => a-b);//ascendente
  let val = array[1]-array[0];
  if(val<=1)return ''
  if(val>2.5)return 'backred'
  if(val>1.5)return 'backorange'
  if(val>1)return 'backyellow'
}
export {mediana, calcularE, diferencia };