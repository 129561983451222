import React, { useContext, useEffect, useState } from "react";
import globalContext from "../../../context/globalContext";
import { useParams } from "react-router-dom";
import { convenciones } from "../Convenciones";

export default function Individual() {
  const URLdomain = "https://dev.consugisoft.com";
  const { state, sendServer, sendState } = useContext(globalContext);
  const { current, competence, online } = state;
  const { competencia_id, modalidad, organizacion } = useParams();
  let urlParams = new URLSearchParams(window.location.search);
  const panel = urlParams.get("panel");
  const id = urlParams.get("id");

  const [configPantalla, setConfigPantalla] = useState({
    fondo: null,
    logo_modalidad: null,
    pie_pagina: null,
    croma: null,
    activo: false,
  });

  useEffect(() => {
    document.title = `Individual`;
    sendState({ URLdomain: organizacion });

    sendServer({
      domain: organizacion,
      app: modalidad,
      action: id ? "getInscripcion" : "getCurrent",
      competencia_id: competencia_id,
      panel: panel,
      id: id,
    });

    if (!competence) {
      sendServer({
        domain: organizacion,
        app: "global",
        action: "getCompetence",
        id: competencia_id,
      });
    }

    sendServer(
      {
        domain: organizacion,
        app: "global",
        action: "getFondoPantalla",
        competencia_id: competencia_id,
      },
      (response) => {
        if (response && response.fondoPantalla) {
          setConfigPantalla({
            fondo: response.fondoPantalla,
            logo_modalidad: response.logoModalidad || null,
            pie_pagina: response.piePagina || null,
            croma: response.croma || null,
            activo: true,
          });
        } else {
          setConfigPantalla({
            fondo: "/static/img/FullScreen_Large.png",
            logo_modalidad: `/static/img/${competence?.modalidad}.png`,
            pie_pagina: "/static/img/footer.png",
            croma: null,
            activo: false,
          });
        }
      }
    );

    const interval = setInterval(() => {
      sendServer({
        domain: organizacion,
        app: modalidad,
        action: id ? "getInscripcion" : "getCurrent",
        competencia_id: competencia_id,
        panel: panel,
        id: id,
      });
    }, 8000);

    return () => clearInterval(interval);
  }, [online, competence]);

  if (!current || !competence) return null;

  const convencionesModalidad = convenciones[modalidad] || {};

  const buildUrl = (path) => {
    if (!path) return null;
    if (path.startsWith("http")) return path;
    if (path.startsWith("/static/")) return path;
    return `${URLdomain}${path}`;
  };

  const backgroundImage = configPantalla.fondo
    ? `url('${buildUrl(configPantalla.fondo)}')`
    : `url('/static/img/FullScreen_Large.png')`;

  const piePaginaUrl =
    buildUrl(configPantalla.pie_pagina) || `/static/img/footer.png`;

  const logoModalidadUrl =
    configPantalla.logo_modalidad || `/static/img/${competence.modalidad}.png`;

  const banderaUrl = current.representacion__bandera
    ? `url('https://consugi.consugisoft.com/media/${current.representacion__bandera}')`
    : "";

  const renderParkourInfo = () => {
    if (modalidad !== "Parkour") return null;

    const disciplina = current.categoria_de_participacion?.split('_')[0] || '';

    return (
      <div className="notasIndividual text-end noPadding col-3">
        <div className="alFinal" style={{ fontSize: "4vw" }}>
          {/* SKILL */}
          {disciplina === "SKILL" && (
            <>
              {current.skill_1 !== null && (
                <>
                  <span className="float-start">S1</span>
                  {current.skill_1.toFixed(3)}
                  <br />
                </>
              )}
              {current.skill_2 !== null && (
                <>
                  <span className="float-start">S2</span>
                  {current.skill_2.toFixed(3)}
                  <br />
                </>
              )}
              {current.skill_3 !== null && (
                <>
                  <span className="float-start">S3</span>
                  {current.skill_3.toFixed(3)}
                  <br />
                </>
              )}
              {current.skill_4 !== null && (
                <>
                  <span className="float-start">S4</span>
                  {current.skill_4.toFixed(3)}
                  <br />
                </>
              )}
              {current.skill_5 !== null && (
                <>
                  <span className="float-start">S5</span>
                  {current.skill_5.toFixed(3)}
                  <br />
                </>
              )}
            </>
          )}

          {/* FREESTYLE */}
          {disciplina === "FREESTYLE" && (
            <>
              {current.ejecución_final !== null && (
                <>
                  <span className="float-start">E</span>
                  {current.ejecución_final.toFixed(3)}
                  <br />
                </>
              )}
              {current.deduccion_final !== null && (
                <>
                  <span className="float-start">D</span>
                  {current.deduccion_final.toFixed(3)}
                  <br />
                </>
              )}
            </>
          )}

          {/* SPEEDRUN */}
          {disciplina === "SPEEDRUN" && current.tiempo_final !== null && (
            <>
              <span className="float-start">T</span>
              {current.tiempo_final.toFixed(3)}
              <br />
            </>
          )}

          {/* Penalidades (CJP) para FREESTYLE y SPEEDRUN */}
          {(disciplina === "FREESTYLE" || disciplina === "SPEEDRUN") && 
            current.deducciones_CJP !== null && current.deducciones_CJP !== 0 && (
              <>
                <span className="float-start red-text">P</span>
                <span className="red-text">
                  {current.deducciones_CJP.toFixed(3)}
                </span>
                <br />
              </>
          )}
        </div>
      </div>
    );
  };

  return (
    <section
      className="seccion-datos ranking h100"
      id="full"
      onClick={() => {
        !document.fullscreenElement
          ? document.getElementById("full").requestFullscreen()
          : document.exitFullscreen();
      }}
      style={{ backgroundImage: backgroundImage, backgroundSize: "cover" }}
    >
      <div
        className="notranslate noSelect container-lg"
        style={{ height: "100%" }}
      >
        <div className="black">
          <header
            className="cabecera-titulo"
            style={{
              gap: "20px",
              padding: "30px",
              width: "100%",
              alignItems: "center",
            }}
          >
            <img
              src="/static/img/ASU2025_B.png"
              alt="Logo ASU2025"
              className="header-img"
            />
            <div className="header-text">
              <h1 style={{ fontSize: "100px" }}>
                {competence.modalidad.toUpperCase()}
              </h1>
              <h2>
                {current.categoria_de_participacion?.replace(/_/g, ' ') || ''} - 
                {current.concurso === "III" || current.final ? " FINAL" : " CLASIFICACIÓN"}
              </h2>
            </div>
            <img
              src={`/static/img/${competence.modalidad}.png`}
              alt="Logo modalidad"
              className="header-img"
            />
          </header>
          <link rel="stylesheet" href="/static/css/fullScreen.css" />
          <link rel="stylesheet" href="/static/css/estilos.css" />
          <div className="marginAuto individual row">
            <div style={{ display: "flex", width: "40%", alignItems: "center" }}>
              <div
                style={{ backgroundImage: banderaUrl }}
                className="contenedorbandera col-3"
              ></div>
              <div style={{ width: "10%" }}>
                {current.representacion__siglas || ''}
              </div>
            </div>

            <div className="text-truncate marquees noPadding col-7">
              <span>{current.categoria_edad || ''}</span>
              <br />
              <span className="marqueeChild" style={{ fontSize: "90px" }}>
                {current.participante}
              </span>
            </div>

            <div className="modalidadChroma col-2"></div>
          </div>
          <div className="marginAuto individual row">
            {modalidad === "Parkour" ? (
              renderParkourInfo()
            ) : (
              <div className="notasIndividual text-end noPadding col-3">
                {/* Renderizado para otras modalidades */}
              </div>
            )}

            <div className="totalIndividual text-end noPadding col">
              {current.dns
                ? "DNS"
                : current.dnf
                ? "DNF"
                : current.dsq
                ? "DSQ"
                : current.total !== null && current.publicado
                ? current.total.toFixed(3)
                : ""}
              {/* Mostrar tiempo bruto para SPEEDRUN */}
              {current.categoria_de_participacion?.includes("SPEEDRUN") && 
                current.tiempo_final !== null && (
                  <div style={{ fontSize: "0.6em", marginTop: "10px" }}>
                    Tiempo: {current.tiempo_final.toFixed(3)}
                  </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <footer>
        <img src={piePaginaUrl} alt="Pie de página" />
      </footer>
    </section>
  );
}