import React, { useContext, useEffect, useState } from 'react'
import globalContext from '../../../context/globalContext'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Header } from './Header'
import { items } from "./ItemsHeader";
import { Format } from "./Format";
import { useParams } from 'react-router-dom';

const clasifican = 8;
const reservas = 2;
const finalesdirectas = ['ALL AROUND M', 'ALL AROUND F', 'TEAM M', 'TEAM F']
const Table = (props) => {
  const { state, URLdomain } = useContext(globalContext);
  const { organizacion } = useParams();
  const urlParams = useParams();
  const [localQuery, setlocalQuery] = useState([])
  const { competence } = state;
  const { query, modalidad, params, titulo } = props;
  const [concurso, setconsurso] = useState(params.concurso);

  const aparatosOrden = ["MANOS", "CUERDA", "ARO", "BALON", "MAZAS", "CINTA"];
  const [aparatos, setAparatos] = useState([]);
  const [aparatoImg, setAparatoImg] = useState({})
  useEffect(() => {
    let newAparatos = new Set();
    let images = {};
    query.forEach(e => {
      newAparatos.add(e.aparato);
      images[e.aparato] = e.aparato__icono;
    });
    const orderedAparatos = Array.from(newAparatos).sort((a, b) => {
      return aparatosOrden.indexOf(a) - aparatosOrden.indexOf(b);
    });
    setAparatos(orderedAparatos);
    setAparatoImg(images);
  }, [query])

  useEffect(() => {
    console.log(urlParams.modalidad)
    if (urlParams.modalidad === "Artistica" && query.length) {
      setconsurso(query[0].concurso);
    } else if ((urlParams.modalidad === "Aerobica" || urlParams.modalidad === "Ritmica") && (query.length && query[0].final === true)) {
      setconsurso('III');
    } else {
      setconsurso('I')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  useEffect(() => {
    let clasificados = {};
    let count = 0;
    let newQuery = []
    // let puesto = 1;
    if (Format[urlParams.modalidad] && Format[urlParams.modalidad][modalidad]) {
      if (modalidad === 'ALL AROUND M' || modalidad === 'ALL AROUND F' || modalidad === 'TEAM M' || modalidad === 'TEAM F') {
        let calculo_aa_team = competence.calculo_aa_team
        setlocalQuery(Format[urlParams.modalidad][modalidad](query, false, calculo_aa_team))
      } else {
        setlocalQuery(Format[urlParams.modalidad][modalidad](query))
      }
    } else {
      query.forEach((e, index) => {
        if (count < reservas + clasifican) {//8Q 2R
          if (!clasificados.hasOwnProperty(competence.modalidad === "Aerobica" ? e.representacion : e.equipo)) {
            // equipo funciona para artistica y ritmica ↑↑↑
            clasificados[competence.modalidad === "Aerobica" ? e.representacion : e.equipo] = 1;
            e = { ...e, q: (count < clasifican ? 'Q' : 'R') }
            count++;
          } else if (clasificados[competence.modalidad === "Aerobica" ? e.representacion : e.equipo] < 2) {
            clasificados[competence.modalidad === "Aerobica" ? e.representacion : e.equipo]++;
            e = { ...e, q: (count < clasifican ? 'Q' : 'R') }
            count++;
          }
        }
        if (competence.modalidad === "Ritmica") e = { ...e, puesto: index + 1 }
        // if(!e.puesto)e = {...e, puesto:puesto}
        newQuery.push(e);
        // puesto ++;
        // let f = newQuery[newQuery.length-1];
        // let numero_gimnasta = e.numero_gimnasta;
      });
      setlocalQuery(newQuery);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])
  const sinImageHeader = ['ALL AROUND', 'ALL AROUND F', 'ALL AROUND M', 'TEAM F', 'TEAM M', 'orden_de_paso', 'calendario', 'medallistas', 'clasificacion_medallas', 'multi_medallistas', 'lista_de_jueces', 'asignacion_de_paneles']
  const sinCategoriaQ = ['calendario', 'medallistas', 'clasificacion_medallas', 'multi_medallistas', 'lista_de_jueces', 'asignacion_de_paneles']
  const noStriped = ['asignacion_de_paneles']
  const itemMod = !titulo ? modalidad : (modalidad === 'PRIMER SALTO' ? 'Individual Judges Scores Salto' : titulo)
  if (!competence || organizacion !== state.URLdomain) return null;
  return (
    <table className={"centered table newTable " + (noStriped.indexOf(modalidad) === -1 ? 'striped' : 'noborderButton')} children={
      <>
        <thead className='upper' children={
          <>
            <tr children={
              <th colSpan={40} children={
                <>
                  <Row children={
                    <>
                      {competence ?
                        <>
                          <Col
                            className="encabezado hide_md_down imgHeader"
                            children={
                              <img
                                alt='org'
                                src={`https://consugi.consugisoft.com/media/${competence.organizadores}`}
                              />
                            }
                          />
                          <Col className="encabezado" xs={12} sm={7}>
                            <p className='text-center m0' children={competence.nombre_de_la_competencia} />
                            <p className='text-center m0' children={
                              <>
                                <a href={`${competence.ubicacion}`} target="_blank" rel="noreferrer" children={competence.lugar} />
                                <span> Del {competence.inicio} al {competence.fin}</span>
                              </>
                            } />
                            <p className='text-center m0 bold' children={
                              <>
                                {sinCategoriaQ.indexOf(modalidad) === -1 ?
                                  (
                                    localQuery.length ?
                                      `${concurso !== 'I' || modalidad === 'team' || finalesdirectas.indexOf(modalidad) > -1 ? 'FINAL' : 'CLASIFICACION'} - ${competence.modalidad === 'Aerobica' || competence.modalidad === 'Ritmica' ? localQuery[0].categoria_edad : localQuery[0].categoria} - ${modalidad.replace(/_/g, ' ')}`
                                      : modalidad.replace(/_/g, ' ')
                                  )
                                  : modalidad.replace(/_/g, ' ')}
                                {titulo ? <><br />{titulo}</> : ''}
                              </>
                            } />
                          </Col>
                          <Col
                            className="encabezado hide_md_down imgHeader"
                            children={
                              <img
                                alt='log'
                                src={`https://consugi.consugisoft.com/media/${competence.logo}`}
                              />
                            } />
                        </>
                        : null}
                    </>
                  } />
                  {sinImageHeader.indexOf(modalidad) === -1 ? <hr color={'grey'} /> : null}
                  {competence.modalidad === 'Ritmica' && localQuery.length > 0
                    ? <Row className={sinImageHeader.indexOf(modalidad) !== -1 ? '' : 'modalidad'} style={{ backgroundImage: `url('https://consugi.consugisoft.com/static/media/${localQuery[0].aparato__icono}')` }} />
                    : <Row className={sinImageHeader.indexOf(modalidad) !== -1 ? '' : 'modalidad'} style={{ backgroundImage: `url('${URLdomain}/static/recursos/${competence.modalidad}/${modalidad}.png')` }} />
                  }
                </>
              } />
            } />
            <Header modalidad={`${titulo ? titulo : modalidad}`} final={concurso !== 'I'} query={query} aparatos={aparatos}
              aparatoImg={aparatoImg} />
          </>
        } />
        <tbody className="resultadosCont" children={
          <>
            {localQuery.map((q, index) =>
              <tr key={`${q.id}${index}`} className={q.class} children={
                <>
                  {competence.modalidad === 'Ritmica' && modalidad === 'ALL AROUND'
                    ?<>{items[competence.modalidad]['all'].map((i, index) =>
                      (concurso !== 'I' && i.item === 'q') || q[i.item] === 'blank' ? null :
                        <td
                          key={`${q.id}${i}${index}`}
                          rowSpan={q.rowSpan && q.rowSpan[i.item] ? q.rowSpan[i.item] : null}
                          children={
                            i.format && q[i.item] ? i.format(q[i.item]) : (
                              i.toFixed ? (
                                q[i.item] !== null ? q[i.item].toFixed(i.toFixed) : ''
                              )
                                : (i.item !== 'representacion__bandera' && i.item !== 'equipo__bandera' ? q[i.item] : (q[i.item] ? <div className='miniBandera' style={{ backgroundImage: `url('https://consugi.consugisoft.com/media/${q[i.item]}')` }} /> : '\n'))
                            )
                          }
                          className={i.clases}
                        />
                      )}
                      
                      {aparatos.map((i, index)=>
                        <td 
                          key={i.show+index} 
                          children={q[i]}
                        />
                      )}
                      <td
                        children={q.total_general?.toFixed(3)}
                        className={'text-end w80'}
                      />
                    </>
                    :items[competence.modalidad][items[competence.modalidad].hasOwnProperty(itemMod) ? itemMod : 'generic'].map((i, index) =>
                      (concurso !== 'I' && i.item === 'q') || q[i.item] === 'blank' ? null :
                        <td
                          key={`${q.id}${i}${index}`}
                          rowSpan={q.rowSpan && q.rowSpan[i.item] ? q.rowSpan[i.item] : null}
                          children={
                            i.format && q[i.item] ? i.format(q[i.item]) : (
                              i.toFixed ? (
                                q[i.item] !== null ? q[i.item].toFixed(i.toFixed) : ''
                              )
                                : (i.item !== 'representacion__bandera' && i.item !== 'equipo__bandera' ? q[i.item] : (q[i.item] ? <div className='miniBandera' style={{ backgroundImage: `url('https://consugi.consugisoft.com/media/${q[i.item]}')` }} /> : '\n'))
                            )
                          }
                          className={i.clases}
                        />
                      )
                  }
                </>
              } />
            )}
            {localQuery.length === 0 ? <tr children={<td colSpan={40} className='text-center' children={'...Sin resultados, consulta con sistemas para mas información.'} />} /> : null}
          </>
        } />
        {competence.patrocinadores && localQuery.length > 4 ?
          <tfoot children={
            <>
              <tr children={
                <td colSpan={40} className='noPadding' children={
                  <div className="encabezado" style={{ backgroundImage: `url('https://consugi.consugisoft.com/media/${competence.patrocinadores}')` }} />
                } />
              } />
              {/* <tr children={
                <td colSpan={40} children={
                  <img className='float-end firma' src='https://react.consugisoft.com/static/firmas/JuanCarlos.png'/>
                }/>
              }/> */}
            </>
          } />
          : null}
      </>
    } />
  )
}

export { Table, clasifican, reservas };