import React from "react";

export default function Header({ modality, discipline, isFinal }) {
  return (
    <header className="cabecera-titulo" style={headerStyle}>
      <img
        src="/static/img/ASU2025_B.png"
        alt="Logo ASU2025"
        className="header-img"
      />
      <div className="header-text">
        <h1 style={{ fontSize: "100px" }}>{modality.toUpperCase()}</h1>
        <h2>
          {discipline?.replace(/_/g, " ") || ""} -
          {isFinal ? " FINAL" : " CLASIFICACIÓN"}
        </h2>
      </div>
      <img
        src={`/static/img/${modality}.png`}
        alt={`Logo ${modality}`}
        className="header-img"
      />
    </header>
  );
}

const headerStyle = {
  gap: "20px",
  padding: "30px",
  width: "100%",
  alignItems: "center",
};
