import React, { useContext, useEffect, useState } from "react";
import globalContext from "../../../context/globalContext";
import { useParams } from "react-router-dom";
import { convenciones } from "../Convenciones";

// Diccionario de siglas estándar para países o representaciones
const siglasPaises = {
  "Estados Unidos": "USA",
  México: "MEX",
  Canadá: "CAN",
  "Nueva Zelanda": "NZL",
  "Costa Rica": "CRC",
  "El Salvador": "SVL",
};

export default function Ranking() {
  const URLdomain = "https://consugi.consugisoft.com";
  const { state, sendServer } = useContext(globalContext);
  const { current, competence, online, ranking } = state;
  let { competencia_id, modalidad, organizacion } = useParams();
  const [params, setParams] = useState({ chroma: "black" });
  let urlParams = new URLSearchParams(window.location.search);
  const [localRanking, setLocalRanking] = useState([]);
  const [currentscroll, setCurrentScroll] = useState(0);

  useEffect(() => {
    if (urlParams.get("chroma")) {
      setParams({ chroma: urlParams.get("chroma") });
    }
  }, []);

  useEffect(() => {
    if (ranking) {
      let newRanking = [];
      let representacion = "";
      let numero_gimnasta = null;
      ranking.forEach((e) => {
        if (
          modalidad === "Artistica" &&
          numero_gimnasta !== e.numero_gimnasta
        ) {
          newRanking.push({
            ...e,
            representacion: e.equipo__nombre,
          });
          numero_gimnasta = e.numero_gimnasta;
        } else if (modalidad === "Ritmica") {
          newRanking.push(e);
        } else if (
          modalidad !== "Artistica" &&
          representacion !== e.representacion
        ) {
          newRanking.push(e);
          representacion = e.representacion;
        }
      });

      // Ordenar la lista para que los DNS aparezcan al final
      const sortedRanking = newRanking.sort((a, b) => {
        if (a.dns && !b.dns) return 1; // a es DNS, b no es DNS → a va después de b
        if (!a.dns && b.dns) return -1; // a no es DNS, b es DNS → a va antes de b
        return 0; // Mantiene el orden original si ambos son DNS o no son DNS
      });

      console.log("Ranking recibido:", sortedRanking);

      setLocalRanking(sortedRanking);
      console.log("Datos de localRanking (All Around):", sortedRanking);
    }
  }, [ranking]);

  useEffect(() => {
    if (competence) {
      sendServer({
        domain: organizacion,
        app: modalidad,
        action: "getAllAround",
        competencia_id: competencia_id,
        subdivision: 1,
        categoria: urlParams.get("categoria"),
        niveles_usag: competence.niveles_usag,
      });
      document.title = `AllAround`;
    } else {
      sendServer({
        domain: organizacion,
        app: "global",
        action: "getCompetence",
        id: competencia_id,
      });
    }
  }, [competence]);

  useEffect(() => {
    let interval = setInterval(() => {
      sendServer({
        domain: organizacion,
        app: modalidad,
        action: "getAllAround",
        competencia_id: competencia_id,
        subdivision: 1,
        categoria: urlParams.get("categoria"),
        niveles_usag: competence.niveles_usag,
      });
    }, 8000);
    return () => clearInterval(interval);
  }, [online, competence]);

  let cantidades = [0, 9, 16, 23, 30, 37];
  let listScroll = [0, 354, 708, 1062, 1416, 1770];

  useEffect(() => {
    if (!urlParams.get("noScroll")) {
      let interval = setInterval(() => {
        if (localRanking.length > cantidades[currentscroll + 1]) {
          setCurrentScroll(currentscroll + 1);
        } else {
          setCurrentScroll(0);
        }
        document
          .querySelector("div.scrollRank")
          .scrollTo(0, listScroll[currentscroll]);
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [currentscroll, localRanking.length]);

  // Función para extraer siglas del nombre del equipo
  const getSiglasFromEquipo = (equipo) => {
    if (!equipo) return "N/A";

    // Verifica si el nombre del equipo está en el diccionario
    if (siglasPaises[equipo]) {
      return siglasPaises[equipo];
    }

    // Si no está en el diccionario, procede con la lógica original
    const palabras = equipo.split(" ");
    if (palabras.length === 1) {
      return palabras[0].substring(0, 3).toUpperCase();
    } else {
      return palabras.map((palabra) => palabra[0].toUpperCase()).join("");
    }
  };

  // Función para extraer siglas del nombre del archivo de la bandera
  const getSiglasFromBandera = (bandera) => {
    if (!bandera) return "N/A";
    const nombreArchivo = bandera.split("/").pop().split(".")[0];
    return nombreArchivo.toUpperCase();
  };

  if (!competence) return null;

  return (
    <div
      className="seccion-datos ranking h100"
      id="full"
      onClick={() => {
        if (!document.fullscreenElement) {
          document
            .getElementById("full")
            .requestFullscreen()
            .catch((err) => {
              console.error(
                "Error al intentar activar pantalla completa:",
                err
              );
            });
        } else {
          document.exitFullscreen();
        }
      }}
      style={{ cursor: "pointer", padding: "0%" }} // Cambia el cursor para indicar que es clickeable
    >
      <link rel="stylesheet" href="/static/css/fullScreen.css" />
      <link href="/static/css/estilos.css" rel="stylesheet" />
      <div>
        <header
          className="cabecera-titulo"
          style={{
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <img src="/static/img/ASU2025_B.png" />
          <div>
            <h1 style={{ fontSize: "60px" }}>
              {competence.modalidad.toUpperCase()}
            </h1>
            <h2
              style={{
                paddingTop: "10px",
                fontSize: "30px",
                paddingTop: "10px",
              }}
            >
              FINAL FEMENINA ALL AROUND
            </h2>
          </div>
          <img
            src={`/static/img/${competence.modalidad}.png`}
            alt="logo"
            style={{ marginLeft: "0%" }}
          />
        </header>
        <div className="datos">
          <div
            className="col-md-9 bottomBlock"
            style={{
              width: "100%",
            }}
          >
            <div
              className="text-center col-3 text-yellow"
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                justifyItems: "center",

                alignItems: "center",
                paddingLeft: "30px",
              }}
            >
              <h1 style={{ fontSize: "40px", fontWeight: "bolder" }}>
                RESULTADO
              </h1>
            </div>

            <div className="scrollRank" style={{ maxHeight: "67vh" }}>
              {localRanking
                ? localRanking.map((r, index) => {
                    // Obtener las siglas
                    const siglas =
                      r[convenciones[modalidad]["siglas"]] || // Intenta acceder desde convenciones
                      r.siglas || // Intenta acceder a la propiedad "siglas"
                      r.equipo__siglas || // Intenta acceder a la propiedad "equipo__siglas"
                      getSiglasFromEquipo(r.equipo) || // Extrae siglas del nombre del equipo
                      getSiglasFromBandera(r.equipo__bandera) || // Extrae siglas del nombre del archivo de la bandera
                      "N/A"; // Si no se encuentran las siglas, muestra "N/A"

                    return (
                      <div
                        className="marginAuto bordergreen gradient row fila-dato"
                        id={index + 1}
                        key={index + 1}
                        style={{
                          height: "0%",
                          display: "flex",
                          alignItems: "start",
                          justifyContent: "space-between",
                          flexGrow: "1",
                          padding: "none",
                        }}
                      >
                        <div
                          className="text-center col-1"
                          style={{ fontSize: "50px" }}
                        >
                          {index + 1}
                        </div>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "15%",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            className="col-1"
                            style={{ color: "yellow", fontSize: "50px" }}
                          >
                            {siglas}
                          </div>
                          <div
                            style={{
                              backgroundImage: `url('https://consugi.consugisoft.com/media/${
                                r[convenciones[modalidad]["bandera"]]
                              }')`,
                              marginRight: "5px",
                            }}
                            className="representacionChroma col-1"
                          ></div>
                        </div>

                        <div className="col" style={{ fontSize: "50px" }}>
                          {`${r.participante}`}
                        </div>

                        

                        <div
                          className="text-end"
                          style={{
                            fontSize: "1.2em",
                            fontSize: "50px",
                            width: "13%",
                          }}
                        >
                          {
                            r.dns // Si el gimnasta no participó (DNS)
                              ? "DNS" // Muestra "DNS"
                              : r[convenciones[modalidad]["total_general"]] !==
                                null // Si no es DNS, verifica si el total_general no es null
                              ? r[
                                  convenciones[modalidad]["total_general"]
                                ].toFixed(3) // Muestra el total_general formateado
                              : "" // Si no cumple las condiciones, muestra una cadena vacía
                          }
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        </div>
        <footer>
          <img src="/static/img/footer.png" alt="Footer" />
        </footer>
      </div>
    </div>
  );
}
