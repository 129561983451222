import { SubTableAsignaciones } from "./art/SubTableAsignaciones";
import { clasifican, reservas } from "./Table";

const proto = window.location.protocol;

const salto = (query) => {
  let newQuery = [];
  let puesto = 1;
  let clasificados = {};
  let count = 0;
  query.forEach(i => {
    if (!newQuery.length || (newQuery[newQuery.length - 1]['numero_gimnasta'] !== i['numero_gimnasta'])) {
      if (count < reservas + clasifican && !clasificados.hasOwnProperty(i.equipo)) {
        clasificados[i.equipo] = 1;
        i = { ...i, q: (count < clasifican ? 'Q' : 'R') }
      } else if (clasificados[i.equipo] < 2) {
        clasificados[i.equipo]++;
        i = { ...i, q: (count < clasifican ? 'Q' : 'R') }
      }
      count++;
      newQuery.push({
        ...i,
        puesto: puesto,
      })
      puesto++;
    } else {
      ['dificultad', 'ejecución_final', 'neutrales', 'nota_final'].forEach(a => {
        let n = a !== 'neutrales' ? 3 : 1
        newQuery[newQuery.length - 1][a] = `${newQuery[newQuery.length - 1][a]!==null ? `${newQuery[newQuery.length - 1][a].toFixed(n)}\n${i[a] ? i[a].toFixed(n) : ''}` : `\n${i[a] ? i[a].toFixed(n) : ''}`}`
      });
      ['ejecución_1', 'ejecución_2', 'ejecución_3', 'ejecución_4', 'ejecución_5', 'ejecución_6', 'ejecución_7', 'línea_total', 'cronómetro', 'deducciones', ].forEach(a => {
        let n = 1;
        newQuery[newQuery.length - 1][a] = `${newQuery[newQuery.length - 1][a]!==null ? `${newQuery[newQuery.length - 1][a].toFixed(n)}\n${i[a] ? i[a].toFixed(n) : ''}` : `\n${i[a] ? i[a].toFixed(n) : ''}`}`
      });
    }

  });
  return newQuery
}

const notaAparato = (i, tachado = false, calculo_aa_team='primer') => {
  if (i['aparato'] === 'SALTO' && calculo_aa_team==='promedio'){
    return <>
    <b children={i.salto ? i.salto.toFixed(3) : ''} className={`float-end ${tachado && i.tachar ? 'tachado' : ''}`} /><br />
    <span children={'Promedio'} />
  </>
  }
  return <>
    <span children={i.dificultad ? i.dificultad.toFixed(3) : ''} />
    <b children={i.nota_final ? i.nota_final.toFixed(3) : ''} className={`float-end ${tachado && i.tachar ? 'tachado' : ''}`} /><br />
    <span children={i.ejecución_final ? i.ejecución_final.toFixed(3) : ''} />
    <span children={i.neutrales ? i.neutrales.toFixed(3) : ''} className='red-text float-end' />
  </>
}

// ALL AROUND ARTISTICA
const all = (query, tachado = false, calculo_aa_team='primer') => {
  let newQuery = [];
  let puesto = 1;
  let dorsal = null;
  query.forEach(i => {
    if (!newQuery.length || (newQuery[newQuery.length - 1]['numero_gimnasta'] !== i['numero_gimnasta'])) {
      newQuery.push({
        ...i,
        puesto: <b children={puesto} />,
        participanteB: i.participante,
        participante: <><div className='miniBandera12h' style={{ backgroundImage: `url('${proto}//colombia.consugisoft.com/media/${i.equipo__bandera}')` }} />{i.equipo}<br />{i.participante}</>
      })
      puesto++;
    }
    if (i['aparato'] === 'SALTO' && i['numero_gimnasta'] === dorsal) { } else {
      newQuery[newQuery.length - 1][i.aparato] = notaAparato(i, tachado, calculo_aa_team);
    }
    if (i['aparato'] === 'SALTO') {
      dorsal = i['numero_gimnasta']
    }
  });
  return newQuery
}

const team = (query, tachado = false, calculo_aa_team='primer') => {
  query = all(query, true)
  let newQuery = [];
  let c = {}
  query.forEach(i => {
    if (i.equipo__nombre !== c.equipo__nombre) {
      if (c.equipo__nombre) newQuery.push({ puesto: <><br /><br /></>, class: 'space' })
      newQuery.push({
        puesto: <b children={i.puestoTeam} />,
        categoria: i.categoria,
        VIGA: <b
          children={i.notas_aparatos_equipo.VIGA ? i.notas_aparatos_equipo.VIGA.toFixed(3) : ''}
          className='float-end'
        />,
        BARRA: <b
          children={i.notas_aparatos_equipo.BARRA ? i.notas_aparatos_equipo.BARRA.toFixed(3) : ''}
          className='float-end'
        />,
        SALTO: <b
          children={i.notas_aparatos_equipo.SALTO ? i.notas_aparatos_equipo.SALTO.toFixed(3) : ''}
          className='float-end'
        />,
        SUELO: <b
          children={i.notas_aparatos_equipo.SUELO ? i.notas_aparatos_equipo.SUELO.toFixed(3) : ''}
          className='float-end'
        />,
        ANILLAS: <b
          children={i.notas_aparatos_equipo.ANILLAS ? i.notas_aparatos_equipo.ANILLAS.toFixed(3) : ''}
          className='float-end'
        />,
        ARZONES: <b
          children={i.notas_aparatos_equipo.ARZONES ? i.notas_aparatos_equipo.ARZONES.toFixed(3) : ''}
          className='float-end'
        />,
        PARALELAS: <b
          children={i.notas_aparatos_equipo.PARALELAS ? i.notas_aparatos_equipo.PARALELAS.toFixed(3) : ''}
          className='float-end'
        />,
        ASIMETRICAS: <b
          children={i.notas_aparatos_equipo.ASIMETRICAS ? i.notas_aparatos_equipo.ASIMETRICAS.toFixed(3) : ''}
          className='float-end'
        />,
        total_equipo: <>
          {i.total_equipo ? <b children={i.total_equipo.toFixed(3)} /> : ''}
          {i.neutrales_equipo ? <>
            <br />
            <span className="red-text" children={i.neutrales_equipo} />
          </> : null}
        </>,
        numero_gimnasta: <div className='miniBandera' style={{ backgroundImage: `url('${proto}//colombia.consugisoft.com/media/${i.equipo__bandera}')` }} />,
        participante: i.equipo__nombre,
        class: 'team',
      })
      c = i;
    }
    if (!newQuery.length || (newQuery[newQuery.length - 1]['numero_gimnasta'] !== i['numero_gimnasta'])) {
      newQuery.push({
        ...i,
        puesto: null,
        participante: i.participanteB,
        total_equipo: '',
      })
    }
    newQuery[newQuery.length - 1][i.aparato] = notaAparato(i, true, calculo_aa_team);
  });
  return newQuery
}
const medallas = { 1: 'ORO', 2: 'PLATA', 3: 'BRONCE' }
const medallistas = (query) => {
  let newQuery = [];
  let numberObject = 0;
  let numberObjectEquipo = 0;
  let puesto = null;
  let count = 1;
  let equipo = null;
  let countEquipo = 1;
  query.forEach(e => {
    puesto = null;
    equipo = null;
    e.list.forEach((i, index) => {
      if (i.puesto !== puesto && newQuery.length) {
        newQuery[numberObject]['rowSpan'] = { ...newQuery[numberObject]['rowSpan'], medalla: count - 1 }
        count = 1;
        numberObject = newQuery.length;
      }
      if (i.equipo !== equipo && newQuery.length) {
        newQuery[numberObjectEquipo]['rowSpan'] = { ...newQuery[numberObjectEquipo]['rowSpan'], equipo: countEquipo - 1, equipo__bandera: countEquipo - 1 }
        countEquipo = 1;
        numberObjectEquipo = newQuery.length;
        equipo = i.equipo;
      }
      newQuery.push({
        ...i,
        rowSpan: {
          evento: index === 0 ? e.list.length : null,
        },
        evento: index === 0 ? e.item : 'blank',
        medalla: count === 1 ? medallas[i.puesto] : 'blank',
        rowMedalla: count,
        participante: i.participante,
        equipo: countEquipo === 1 ? i.equipo : 'blank',
        equipo__bandera: countEquipo === 1 ? i.equipo__bandera : 'blank',
      })
      if (i.equipo === equipo || equipo === null) {
        countEquipo++;
      }
      equipo = i.equipo;
      puesto = i.puesto;
      count++;
    })
    if(Boolean(newQuery.length))newQuery[numberObject]['rowSpan'] = { ...newQuery[numberObject]['rowSpan'], medalla: count - 1, equipo: countEquipo - 1, equipo__bandera: countEquipo - 1 }
  });
  return newQuery
}
const calendario = query => {
  let newQuery = [];
  let inicio = null;
  if (query) query.forEach(e => {
    newQuery.push({
      ...e,
      inicio: e.inicio.slice(0, 10) === inicio ? null : e.inicio,
      horaInicio: e.inicio,
      object: e
    });
    inicio = e.inicio.slice(0, 10);
  })
  return newQuery
}
const clasificacion_medallas = query => {
  let newQuery = [];
  for (let i in query) {
    if (!newQuery.length) {
      newQuery.push({ ...query[i], equipo: i })
    } else {
      for (let index = 0; index < newQuery.length; index++) {
        const e = newQuery[index];
        if (e.oro < query[i].oro) {
          newQuery.splice(index, 0, { ...query[i], equipo: i })
          break;
        } else if (e.oro === query[i].oro && e.plata < query[i].plata) {
          newQuery.splice(index, 0, { ...query[i], equipo: i })
          break;
        } else if (e.oro === query[i].oro && e.plata === query[i].plata && e.bronce < query[i].bronce) {
          newQuery.splice(index, 0, { ...query[i], equipo: i })
          break;
        } else if (newQuery.length === index + 1) {
          newQuery.splice(index + 1, 0, { ...query[i], equipo: i })
          break;
        }
      }
    }
  }
  newQuery = newQuery.map((e, index) => {
    return { ...e, puesto: index + 1 }
  })
  return newQuery;
}
const multi_medallistas = query => {
  let newQuery = [];
  for (let i in query) {
    if (query[i]['totalMedallas'] < 2) continue;
    let eventos = '';
    let medallas = '';
    for (let event in query[i]['evento']) {
      if (eventos === '') {
        eventos += `${event}:`;
        medallas += `${query[i]['evento'][event]}`;
      } else {
        eventos += `\n${event}:`;
        medallas += `\n${query[i]['evento'][event]}`;
      }
    }
    if (!newQuery.length) {
      newQuery.push({ ...query[i], equipo: i, eventos: eventos, medalla: medallas })
    } else {
      for (let index = 0; index < newQuery.length; index++) {
        const e = newQuery[index];
        if (e.oro < query[i].oro) {
          newQuery.splice(index, 0, { ...query[i], equipo: i, eventos: eventos, medalla: medallas })
          break;
        } else if (e.oro === query[i].oro && e.plata < query[i].plata) {
          newQuery.splice(index, 0, { ...query[i], equipo: i, eventos: eventos, medalla: medallas })
          break;
        } else if (e.oro === query[i].oro && e.plata === query[i].plata && e.bronce < query[i].bronce) {
          newQuery.splice(index, 0, { ...query[i], equipo: i, eventos: eventos, medalla: medallas })
          break;
        } else if (newQuery.length === index + 1) {
          newQuery.splice(index + 1, 0, { ...query[i], equipo: i, eventos: eventos, medalla: medallas })
          break;
        }
      }
    }
  }
  newQuery = newQuery.map((e, index) => {
    return { ...e, puesto: index + 1 }
  })
  return newQuery;
}
const asignacion_de_paneles = query => {
  let objects = [];
  let subQuerys = [];
  let panel = null;
  if (query) query.forEach((i, index) => {
    if (panel !== i.panel){
      panel = i.panel
      if(Boolean(subQuerys.length) && subQuerys[subQuerys.length - 1].length===1){
        subQuerys[subQuerys.length - 1].push([])
      }else{
        subQuerys.push([[]])
      }
    }
    subQuerys[subQuerys.length - 1][subQuerys[subQuerys.length - 1].length-1].push(i)
  });
  subQuerys.forEach((i, index) => {
    i.forEach((e, indexb) => {
      subQuerys[index][indexb] = <SubTableAsignaciones key={`subTableAsig${indexb}`} query={e}/>
    });
  });
  
  subQuerys.forEach((i, index) => {
    objects.push({'object': <div className="subTableCont"  key={`subTableCont${index}`} children={i}/>})
  });
  return objects
};
const medallistasAer = query=> {
  return [];
}
const Format = {
  'ALL AROUND F': all,
  'ALL AROUND M': all,
  'TEAM F': team,
  'TEAM M': team,
  'SALTO': salto,
  calendario: calendario,
  'medallistas': medallistas,
  'clasificacion_medallas': clasificacion_medallas,
  'multi_medallistas': multi_medallistas,
  asignacion_de_paneles: asignacion_de_paneles,
  'medallistas': medallistas,
}

export { Format };