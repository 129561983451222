import { useContext, useEffect, useState } from 'react'
import globalContext from '../../../../context/globalContext';
import { Header } from '../Header';
import { items } from "../ItemsHeader";

export const SubTableAsignaciones = (props) => {
  const { state, URLdomain } = useContext(globalContext);
  const [titulo, setTitulo] = useState('')
  const {query, concurso}= props;
  const modalidad = 'SubTableAsignaciones';
  useEffect(() => {
    if(query.length)setTitulo(query[0].panel__nombre_panel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])
  
  const {competence} = state;
  return (
    <table className="centered striped table mt50px" children={
        <>
          <thead style={{}} children={
            <>
            <tr children={
              <>
              <th colSpan={10} className='text-center' children={titulo}/>
              </>
            }/>
              <Header modalidad={modalidad} clases={'borderSubTable title'}/>
            </>
          }/>
          <tbody className="resultadosCont" children={
            <>
            {query.map((q, index)=>
              <tr key={`${q.id}${index}`} className={q.class} children={
                <>
                  {items[competence.modalidad][modalidad].map((i, index)=>
                    concurso!=='I'&&i.item==='q'?null:
                      <td
                        key={`${q.id}${i}${index}`}
                        children={
                          i.format&&q[i.item]?i.format(q[i.item]):(
                            i.toFixed?(
                              q[i.item]?q[i.item].toFixed(i.toFixed):''
                            )
                            :(i.item!=='representacion__bandera'&&i.item!=='equipo__bandera'?q[i.item]:(q[i.item]?<div className='miniBandera' style={{backgroundImage:`url('https://consugi.consugisoft.com/media/${q[i.item]}')`}}/>:'\n'))
                          )
                        }
                        className={i.clases+` ${q.numero_gimnasta_count===2&&i.item==='numero_gimnasta'?' bold':''}`}
                      />
                  )}
                </>
              }/>
            )}
            {query.length===0?<tr children={<td colSpan={40} className='text-center' children={'...Sin resultados, consulta con sistemas para mas información.'}/>}/>:null}
            </>
          }/>
        </>
      }/>
  )
}