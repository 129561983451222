import { calendario, lista_de_jueces, panel, asignacion_de_paneles, SubTableAsignaciones } from "./Artistica"


const Trampolin = {
  calendario: calendario,
  lista_de_jueces: lista_de_jueces,
  panel: panel,
  asignacion_de_paneles: asignacion_de_paneles,
  SubTableAsignaciones: SubTableAsignaciones,
};
export {Trampolin};