import React, { useCallback, useContext, useEffect, useState } from 'react'
import Col from 'react-bootstrap/esm/Col'
import Row from 'react-bootstrap/esm/Row'
import { useParams } from 'react-router-dom'
import globalContext from '../../../context/globalContext'
import { calcularArtRitmica, calcularEjeRitmica, sumNotNull, promedio } from '../Functions'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { useNavigate } from "react-router-dom";

const lineone = [
  {name: 'dificultad_b', show: 'DB'},
  {name: 'dificultad_a', show: 'DA'},
]
const linetwo = [
  {name: 'artistica_1', show: 'A1'},
  {name: 'artistica_2', show: 'A2'},
  {name: 'artistica_3', show: 'A3'},
  {name: 'artistica_4', show: 'A4'},
]
const linethree = [
  {name: 'ejecución_1', show: 'E1'},
  {name: 'ejecución_2', show: 'E2'},
  {name: 'ejecución_3', show: 'E3'},
  {name: 'ejecución_4', show: 'E4'}
]
const linefourt = [
  {name: 'línea_1', show: 'Linea 1'},
  {name: 'línea_2', show: 'Linea 2'},
  {name: 'cronómetro', show: 'Crono'},
  {name: 'coordinadora', show: 'Coor'}
]
const linefive = [
  {name: 'dificultad_1', show: 'D'},
  {name: 'deducciones_dificultad_total', show: 'D', red: true},
]

const caracteres = ['1','2','3','4','5','6','7','8','9','.','0'];

export default function Coordinador() {
  const initialNotas = {
    dificultad_final:0,
    artistica_final:0,
    ejecución_final:0,
    nota_final:0,
    línea_total:0,
  }
  const [notas, setnotas] = useState(initialNotas);
  const [inscripcion, setInscripcion] = useState(null);
  const [del, setdel] = useState(false)
  const [puesto, setpuesto] = useState('');
  const [userText, setUserText] = useState("");
  const { state, sendServer, URLdomain, sendState } = useContext(globalContext);
  const { online, competence, current, success, orden_de_paso, authorized } = state;
  const { id } = current;

  let { competencia_id, modalidad, organizacion, panel } = useParams();
  const [control, setcontrol] = useState({anterior:null, siguiente:null})
  const navigate = useNavigate();

    // Navigate
    const [orden_de_paso_local, setorden_de_paso_local] = useState([]);
 
    const initialFilter = {aparato:'', edad:'', gimnasta:'', concurso:''};
    const [filter, setfilter] = useState(initialFilter);
    const [edades, setedades] = useState([])
    const [aparatos, setaparatos] = useState([])
    useEffect(() => {// filtrado
      if(!edades.length && orden_de_paso){
        let newEdades = [];
        let newAparatos = [];
        // eslint-disable-next-line array-callback-return
        orden_de_paso.map(o=>{
          if(!newEdades.includes(o.categoria_edad))newEdades.push(o.categoria_edad)
          if(!newAparatos.includes(o.aparato))newAparatos.push(o.aparato)
        })
        setedades(newEdades);
        setaparatos(newAparatos);
      }
      if(orden_de_paso){// && orden_de_paso.length
        setorden_de_paso_local(
          orden_de_paso.filter(o=>
            (
                  (filter.edad===''||filter.edad===o.categoria_edad)
               && (filter.aparato===''||filter.aparato===o.aparato)
              && (filter.concurso===''|| ((filter.concurso==='final' && o.final)||(filter.concurso==='clasificación' && !o.final)) )
              && (filter.gimnasta===''||o.participante.toLowerCase().includes(filter.gimnasta.toLowerCase()))
            )
          )
        )
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orden_de_paso, filter])

    useEffect(() => {
      let urlParams = new URLSearchParams(window.location.search);
      let newFilter = filter;
      for (let f in filter) {
        if (urlParams.get(f)) {
          newFilter = { ...newFilter, [f]: urlParams.get(f) };
        }
      }
      setfilter(newFilter);
      sendServer({domain: organizacion, app:'global', action:'getAsignations', id:competencia_id})
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
 // End navigate

  useEffect(() => {// anterior - siguiente
    if(orden_de_paso_local){
      let anterior,actual,siguiente;
      // eslint-disable-next-line array-callback-return
      orden_de_paso_local.map((gimnasta)=>{
        if(id===gimnasta.id){
          actual = gimnasta.id;
        }else if(!actual){
          anterior = gimnasta.id;
        }else if(actual && !siguiente){
          siguiente = gimnasta.id;
        }
      })
      setcontrol({anterior:anterior, siguiente:siguiente})

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, orden_de_paso_local])
 
  useEffect(() => {
    if(online&&!competence)sendServer({domain: organizacion, app:'global', action:'getCompetence', id:competencia_id});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [online, competence, competencia_id, state.URLdomain])

  useEffect(() => {
    sendServer({
      domain: organizacion,
      app: modalidad,
      action: !inscripcion?'getCurrent':'getInscripcion',
      panel,
      id:inscripcion,
      getOrdenDePaso: true,
      competencia_id: competencia_id,
    })
    document.title = `coordinador | ${current.participante}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inscripcion, state.URLdomain])
  
  useEffect(() => {
    let interval = setInterval(() => {// && params.ver==='resultados'
      // if(online){
        sendServer({
          domain: organizacion, 
          app: modalidad,
          panel,
          action:!inscripcion?'getCurrent':'getInscripcion',
          id:inscripcion,
          competencia_id: competencia_id,
        })
      // }
    }, 3000);
    return ()=> clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ competence, competencia_id, sendServer])
 
  useEffect(() => {
    
    const dificultad_final = competence.niveles_usag ? promedio([current.dificultad_a, current.dificultad_b]) : current.dificultad_a + current.dificultad_b;
    const artistica_final = calcularArtRitmica([current.artistica_1, current.artistica_2, current.artistica_3, current.artistica_4 ])
    const ejecución_final = calcularEjeRitmica([current.ejecución_1, current.ejecución_2, current.ejecución_3, current.ejecución_4 ])
    const línea_total = sumNotNull([current.línea_1, current.línea_2]);
    const total_faltas = sumNotNull([línea_total, current.cronómetro, current.coordinadora])
    const nota_final = sumNotNull([dificultad_final, artistica_final, ejecución_final]) - total_faltas
    setnotas((prev) => ({ ...prev, dificultad_final, artistica_final, ejecución_final, línea_total, nota_final, total_faltas }))
  }, [current])

  useEffect(() => {
    // Cerrar modal
    if(success){
      setpuesto('');
      setUserText('');
      setdel(false)
      sendState({success:false})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success])

  // Teclado fisico
  const handleUserKeyPress = useCallback(event => {
    const { key, keyCode  } = event;
    if(caracteres.includes(key)){
      setUserText(prevUserText => {
        if(prevUserText.match(/[.]/) && key==='.'){
          console.log('No puedes poner dos puntos')
        }else if(prevUserText.length > 4){
          console.log('maximo 5 caracteres');
        }else if(parseFloat(`${prevUserText}${key}`) > 50){
          console.log('El valor maximo es 10');
        }else{
          return `${prevUserText}${key}`
        }
        return `${prevUserText}`
      })
    }else if(keyCode === 8){
      setUserText(prevUserText => `${prevUserText.substr(0, prevUserText.length-1)}`)
    }else if(keyCode === 46){// suprimir
      if(puesto!==""){
        setUserText(``)
      }else{
        publicar(false)
      }
    }else if(keyCode === 188){
      setUserText(prevUserText => {
        if(prevUserText.match(/[.]/))return `${prevUserText}`
        return `${prevUserText}.`
      })
    }else if(keyCode === 13){// Enter
      if(puesto!==""){
        setUserText(prevUserText => {
          cargarNota(prevUserText)
          return `${prevUserText}`
        })
      }else{
        publicar(true)
      }
    }else if(keyCode === 37 && control.anterior){// izquierda
      cambiarGimnasta(control.anterior)
    }else if(keyCode === 39 && control.siguiente){// derecha
      cambiarGimnasta(control.siguiente)
    }
    // console.log(event)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, id]);

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

 
  // End teclado fisico

  const cargarNota =(nota)=>{
    if(nota==='')nota=null;
    sendServer({
      domain: organizacion,
      app: modalidad,
      action:'cargarNota',
      puesto: 'coordinador',
      panel: panel,
      competencia_id: competencia_id,
      data:{
        [puesto]:nota,
        id:current.id,
        competencia_id:current.competencia,
        publicado:false,
      }
    })
  }
  const publicar = (publicado)=>{
    sendServer({
      domain: organizacion,
      app: modalidad,
      action:'cargarNota',
      puesto: 'coordinador',
      panel: panel,
      getOrdenDePaso: true,
      competencia_id: competencia_id,
      data: {
        ...notas,
        publicado,
        id:current.id,
        competencia_id:current.competencia,
      }
    })
  }
  const clearInscription=()=>{
    sendServer({
      domain: organizacion, 
      app: modalidad,
      action:'cargarNota',
      puesto: 'coordinador',
      panel: panel,
      getOrdenDePaso: true,
      competencia_id: competencia_id,
      
      data: {
        id: current.id,
        publicado: false,
        dificultad_final: null,
        dificultad_a: null,
        dificultad_b: null,
        artistica_final: null,
        artistica_1: null,
        artistica_2: null,
        artistica_3: null,
        artistica_4: null,
        ejecución_1: null,
        ejecución_2: null,
        ejecución_3: null,
        ejecución_4: null,
        ejecución_final: null,
        línea_1: null,
        línea_2: null,
        línea_total: null,
        cronómetro: null,
        coordinadora: null,
        nota_final: null,
        competencia_id:current.competencia,
      },
      
    })
  }

  const loadtype = puesto =>{
    setpuesto(puesto)
    if(current[puesto]){
      setUserText(current[puesto].toString())
    }else{
      setUserText('')
    }
  }
  const cambiarGimnasta = id =>{
    if(inscripcion)setInscripcion(null)
    if(id)sendServer({
      domain: organizacion,
      app: modalidad,
      action:'cambiarGimnasta',
      puesto: 'coordinador',
      panel: panel,
      getOrdenDePaso: true,
      competencia_id: competencia_id,
      id:id
    })
  }
  const onChange = e =>{
    let newFilter = {...filter,[e.target.name]:e.target.value};
    if(e.target.value==='Limpiar'){
      newFilter=initialFilter;
    }
    setfilter(newFilter)
    let newUrl = '?'
    for (let f in newFilter) {
      if(newFilter[f]!==''){
        newUrl+=`${newUrl==='?'?'':'&'}${f}=${newFilter[f]}`
      }
    }
    navigate(newUrl);
  }
  

  return (
    <>
      <p className='noPrint' children={
        <>
        {current?.id?
          <>
            <img className='mini' src={`https://consugi.consugisoft.com/media/${current.representacion__bandera}`} alt='Bandera'/>
            <span
              // onClick={()=>loadtype('division_d')}
              children={`(${current.representacion}) ${current.aparato} - ${current.categoria_edad} - ${current.participante}`}
            />
          </>
        :null}
        </>
      }/>
      <Row className='marginAuto noPrint' children={
        <>
          {lineone.map(l=><Col onClick={()=>loadtype(l.name)} key={l.name} className={`${l.red?'red':'blue'} enter`} children={`${l.show}\n${current[l.name]!=null?current[l.name].toFixed(2):''}`}/>)}
          <Col className={`totales ${current.dificultad_final===notas.dificultad_final?'success':'warning'}`} children={`D\n${notas.dificultad_final.toFixed(3)}`}/>
        </>
      }/>
      {!competence.niveles_usag &&(<Row className='marginAuto noPrint' children={
        <>
          {linetwo.map(l=><Col onClick={()=>loadtype(l.name)} key={l.name} className={`${l.red?'red':'blue'} enter`} children={`${l.show}\n${current[l.name]!=null?current[l.name].toFixed(2):''}`}/>)}
          <Col className={`totales ${current.artistica_final===notas.artistica_final?'success':'warning'}`} children={`A\n${notas.artistica_final.toFixed(3)}`}/>
        </>
      }/>)}
      <Row className='marginAuto noPrint' children={
        <>
          {linethree.map(l=><Col onClick={()=>loadtype(l.name)} key={l.name} className={`${l.red?'red':'blue'} enter`} children={`${l.show}\n${current[l.name]!=null?current[l.name].toFixed(2):''}`}/>)}
          <Col className={`totales ${current.ejecución_final===notas.ejecución_final?'success':'warning'}`} children={`E\n${notas.ejecución_final.toFixed(3)}`}/>
        </>
      }/>
      <Row className='marginAuto noPrint' children={
        <>
          {linefourt.map(l=><Col onClick={()=>loadtype(l.name)} key={l.name} className={`${l.red?'red':'blue'} enter`} children={`${l.show}\n${current[l.name]!=null?current[l.name].toFixed(2):''}`}/>)}
          <Col className={`totales ${current.nota_final===notas.nota_final?'success':'warning'}`} children={`TOTAL\n${notas.nota_final.toFixed(3)}`}/>
        </>
      }/>
       <Row className="controles marginAuto noPrint mt10" children={
      <>
        <Col onClick={()=>cambiarGimnasta(control.anterior)} className={`btn-div btn-${control.anterior?"primary":"disabled"}`} children={'◄'}/>
        <Col onClick={()=>cambiarGimnasta(control.siguiente)} className={`btn-div btn-${control.siguiente?"primary":"disabled"}`} children={'►'}/>
        <Col onClick={()=>setdel(true)} className={`btn-div btn-danger`} children={'Del'}/>
        <Col onClick={()=>publicar(false)} className={`btn-div btn-${current.publicado?"warning":"disabled"}`} children={'Desp'}/>
        <Col onClick={()=>publicar(true)} className={`btn-div btn-${!current.publicado?"success":"disabled"}`} children={'Enviar'}/>
      </>
      }/>
      <div className='tableContainer' children={
        <table className='mt30 w100 table table-striped' children={
          <>
            <thead children={
              <>
                <tr children={
                  <th colSpan={40} className='text-center' children={
                    <>
                      ORDEN DE PASO
                      <Row className="controles marginAuto noPrint mt30" children={
                        <>
                          <Col xs={6} sm={4} md={3} lg={2}className="mt10" children={
                            <>
                              <label children={'_'}/>
                              <input className="w100" type='button' value='Limpiar' onClick={onChange}/>
                            </>
                          }/>
                          <Col xs={6} sm={4} md={3} lg={2}className="mt10" children={
                            <>
                              <label children={'Final/Clasificación'}/>
                              <select className='w100' name='concurso' value={filter.concurso} onChange={onChange} children={
                                <>
                                  <option></option>
                                  <option value="clasificación">Clasificación</option>
                                  <option value="final">Final</option>
                                </>
                              }/>
                            </>
                          }/>
                          <Col xs={6} sm={4} md={3} lg={2}className="mt10" children={
                            <>
                              <label children={'Aparato'}/>
                              <select className='w100' name='aparato' value={filter.aparato} onChange={onChange} children={
                                <>
                                  <option></option>
                                  {aparatos.map(e=><option key={e} value={e}>{e}</option>)}
                                </>
                              }/>
                            </>
                          }/>
                          <Col xs={6} sm={4} md={3} lg={2}className="mt10" children={
                            <>
                              <label children={'Categoría'}/>
                              <select className='w100' name='edad' value={filter.edad} onChange={onChange} children={
                                <>
                                  <option></option>
                                  {edades.map(e=><option key={e} value={e}>{e}</option>)}
                                </>
                              }/>
                            </>
                          }/>
                          <Col xs={6} sm={4} md={3} lg={2}className="mt10" children={
                            <>
                              <label children={'Gimnasta'}/>
                              <input className='w100' type='text' name='gimnasta' value={filter.gimnasta} onChange={onChange}/>
                            </>
                          }/>
                        </>
                      }/>
                      
                    </>
                  }/>
                  }/>
                <tr children={
                  <>
                    <th children={'#'}/>
                    <th children={'Gimnastas'}/>
                    <th children={'Rep.'}/>
                    <th children={'Mod.'}/>
                    <th children={'Edad'}/>
                    <th children={'Total'}/>
                  </>
                }/>
              </>
            }/>
            <tbody children={
              <>
                {orden_de_paso_local?
                  orden_de_paso_local.map(i=>
                    <tr key={i.id} className={`click ${i.puntuando?'btn-primary':''} ${i.id===id?'btn-warning':''}`} children={
                      <>
                        <td children={i.orden_de_salida} onClick={()=>setInscripcion(i.id)} className={`text-end`}/>
                        <td onClick={()=>cambiarGimnasta(i.id)} children={i.participante}/>
                        <td children={i.representacion}/>
                        <td children={i.aparato}/>
                        <td children={i.categoria_edad}/>
                        <td children={i.nota_final?.toFixed(3)} className={`text-end ${i.publicado?'success':''}`}/>
                      </>
                    }/>
                  )
                :null}
              </>
            }/>
          </>
        }/>
      }/>

      <Modal show={Boolean(puesto)} fullscreen={true} onHide={() => setpuesto('')}>
        <Modal.Header closeButton>
          <Modal.Title>
            {current?
              <p children={
                <>
                  <img className='mini' src={`https://consugi.consugisoft.com/media/${current.representacion__bandera}`} alt='Bandera'/>
                  <span children={`(${current.representacion}) ${current.categoria_de_participacion} - ${current.categoria_edad} - ${current.aparato} - ${current.participante}`}/>
                </>
              }/>
            :null}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className='row marginAuto'>
          <div className='actionsContainer' children={
            <>
              <div className='text-center userTextCont' children={<b className='userText' children={userText}/>} />
              <div className='text-center puesto' children={puesto.replace('_', ' ').replace('_total', ' ')} />
              <div className="btn-group col-12" role="group" aria-label="Basic mixed styles example">
                {userText===''?
                <button type="button" className="btn btn-danger" onClick={()=>setpuesto('')}>Cancel</button>
                :<button type="button" className="btn btn-danger" onClick={()=>setUserText('')}>Del</button>}
                <button type="button" className="btn btn-success" onClick={()=>cargarNota(userText)}>Enviar</button>
              </div>
            </>
          }/>
          <div className='tecladoContainer' children={
            <>
            {caracteres.map(n=><button type="button" key={n} onClick={()=>handleUserKeyPress({key:n})} className="btn btn-primary" children={<span children={n}/>}/>)}
            <button type="button" className="btn btn-primary" onClick={()=>setUserText(userText.substr(0, userText.length-1))} children={<span children={'◄'}/>}/>
            </>
          }/>
        </div>
        </Modal.Body>
      </Modal>
      <Modal show={del} onHide={() => setdel(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar Notas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {current?
              <p children={
                <>
                  <img className='mini' src={`https://consugi.consugisoft.com/media/${current.representacion__bandera}`} alt='Bandera'/>
                  <span children={`(${current.representacion}) ${current.categoria_de_participacion} - ${current.categoria_edad} - ${current.aparato} - ${current.participante}`}/>
                </>
              }/>
            :null}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setdel(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={() => clearInscription()}>
            Eliminar notas
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}