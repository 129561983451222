import React, { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useParams } from "react-router-dom";
import globalContext from '../../../context/globalContext';

const ModaPanel = (props) => {
  const { object, clearPanel } = props;
  const {sendServer, sendState, state } = useContext(globalContext);
  const {success } = state;
  let { competencia_id, organizacion, puesto, panel } = useParams();
  const initial =  {
    competencia_id:null,
    orden: '',
    nombre_panel: '',
  }
  const [panelState, setPanelState] = useState(initial)
  const [deleteObject, setdeleteObject] = useState(false)
  useEffect(() => {
    if(object.id)setPanelState({
      id: object.id,
      competencia_id:object.competencia_id,
      orden:object.orden,
      nombre_panel:object.nombre_panel,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [object])
  useEffect(() => {
    if(success){setPanelState(initial);sendState({success:null})}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success])
  
  const onChange = e => {
    setPanelState({...panelState, [e.target.name]:e.target.value})
  }
  const save = () => {
    sendServer(
      {
        domain: organizacion,
        app: 'global',
        action: panelState.id?'updatePanel':'createPanel',
        competencia_id: competencia_id,
        params: panelState,
        puesto: puesto,
        panel: panel,
      });
  }
  const deletePanel = () => {
    sendServer(
      {
        domain: organizacion,
        app: 'global',
        action: 'deletePanel',
        competencia_id: competencia_id,
        params: panelState,
        puesto: puesto,
        panel: panel,
      });
    clear()
  }
  const clear = () =>{
    clearPanel()
    setPanelState(initial)
    setdeleteObject(false)
  }
  return (
    <>
      <Button variant="outline-primary" onClick={()=>setPanelState({...initial, competencia_id:competencia_id})}>
        NUEVO PANEL
      </Button>

      <Modal show={Boolean(panelState.competencia_id)} onHide={clear}>
        <Modal.Header closeButton className={deleteObject?'btn-danger':''}>
          <Modal.Title>{deleteObject?'CONFIRMAR ELIMINAR':(panelState.id?'MODIFICAR':'NUEVO')} PANEL</Modal.Title>
        </Modal.Header>
          <Form onSubmit={e=>{e.preventDefault();save()}}>
            <Modal.Body className='row'>
              <Form.Group className="mb-3 col-sm-4">
                <Form.Label className='bold'>Orden</Form.Label>
                <Form.Control
                  autoFocus
                  type="number"
                  name='orden'
                  onChange={onChange}
                  value={panelState.orden}
                  required={true}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className='bold'>Nombre Panel</Form.Label>
                <Form.Control
                  type="text"
                  name='nombre_panel'
                  onChange={onChange}
                  value={panelState.nombre_panel}
                  required={true}
                />
              </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={deleteObject?()=>setdeleteObject(false):clear}>
              CANCELAR
            </Button>
            {Boolean(panelState.id)&&!deleteObject?<Button variant="danger" onClick={()=>setdeleteObject(true)}>
              ELIMINAR
            </Button>:''}
            {deleteObject?<Button variant="danger" onClick={deletePanel}>
              CONFIRMAR
            </Button>:''}
            {!deleteObject?<Button variant="primary" type='submit'>
            {panelState.id?'GUARDAR':'CREAR'}
            </Button>:''}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default ModaPanel;