const removeNullSort = (array) => {
  return array.filter(a => a !== null && a !== "DNS").sort((a, b) => a - b);
};


const promedioAER = (array) => { 
  array = removeNullSort(array);
  if (array.length > 3) {
    const trimmedArray = array.slice(1, array.length - 1);
    // Calcula el promedio de los elementos recortados
    let value = 0;
    trimmedArray.forEach(a => value += a);
    let promedio = value / trimmedArray.length;
    // Verifica la diferencia entre las notas promediadas
    const maxTrimmed = Math.max(...trimmedArray);
    const minTrimmed = Math.min(...trimmedArray);
    const difference = maxTrimmed - minTrimmed;

    if (promedio >= 8.00 && promedio <= 10.00) {
      // Rango de 8.00 a 10.00: la diferencia no puede ser mayor a 0.3
      if (difference > 0.3) {
        value = 0;
        array.forEach(a => value += a);
        return value / array.length; 
      }
    } else if (promedio >= 7.00 && promedio < 8.00) {
      // Rango de 7.00 a 7.99: la diferencia no puede ser mayor a 0.4
      if (difference > 0.4) {
        value = 0;
        array.forEach(a => value += a);
        return value / array.length;
      }
    } else if (promedio >= 6.00 && promedio < 7.00) {
      // Rango de 6.00 a 6.99: la diferencia no puede ser mayor a 0.5
      if (difference > 0.5) {
        value = 0;
        array.forEach(a => value += a);
        return value / array.length;
      }
    } else if (promedio >= 0.00 && promedio < 6.00) {
      // Rango de 0.00 a 5.99: la diferencia no puede ser mayor a 0.6
      if (difference > 0.6) {
        value = 0;
        array.forEach(a => value += a);
        return value / array.length;
      }
    }
    return promedio; // Si la diferencia es aceptable, devuelve el promedio calculado con trimmedArray
  } else {
    // Si no tiene más de 3 elementos, promedia directamente
    let value = 0;
    array.forEach(a => value += a);
    return array.length ? value / array.length : 0;
  }
}

const promedio = (array)=>{
    array = removeNullSort(array);
    if(array.length>3){
      array.splice(0,1)//elimina el mas pequeño
      array.splice(array.length-1,1)//elimina el mas grande
    }
    let value = 0;
    array.map(a=>value+=a)
    if(!value||!array.length)return 0;
    return value/array.length;
}

const calcularArtRitmica = (array) => {
  array = removeNullSort(array);
  if(array.length===4 || array.length === 3){//lo hice para que lo hiciera con 3 
    array.splice(0,1)
    array.splice(array.length-1,1)
  }
  let value = 0;
  array.map(a=>value+=a)
  if(!value||!array.length)return 0;
  return 10 - (value/array.length);
}

const promedioART = (array)=>{
  array = removeNullSort(array);
  if(array.length===7){
    array.splice(0,1)
    array.splice(array.length-1,1)
    array.splice(0,1)
    array.splice(array.length-1,1)
  }else if(array.length>3){
    array.splice(0,1)
    array.splice(array.length-1,1)
  }
  let value = 0;
  array.map(a=>value+=a)
  if(!value||!array.length)return 0;
  return value/array.length;
}
const calcularEjeRitmica = calcularArtRitmica;

const sumNotNull = (array) => {
  array = removeNullSort(array);
  let value = 0;
  array.map(a=>value+=a)
  return value;
}
export {promedio, promedioAER, promedioART, calcularArtRitmica, calcularEjeRitmica, sumNotNull};