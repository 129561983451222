import React, { useContext, useEffect, useState } from 'react'
import globalContext from '../../context/globalContext'
import { useParams } from 'react-router-dom'
import Col from 'react-bootstrap/esm/Col'
import Row from 'react-bootstrap/esm/Row'
import { convenciones } from "./Convenciones";

export default function Ranking() {
  const {state, sendServer, URLdomain, sendState} = useContext(globalContext)
  const {current, competence, online, ranking} = state;
  let { competencia_id, modalidad, organizacion} = useParams();
  const [params, setParams] = useState({chroma:'black', panel: null})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  let urlParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    if(urlParams.get('chroma')){
      setParams({chroma:urlParams.get('chroma'), panel:urlParams.get('panel'), });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(organizacion===state.URLdomain&&competence){
      sendServer({
        domain: organizacion,
        app: modalidad,
        action:'getRanking',
        competencia_id: competencia_id,
        panel:urlParams.get('panel')
      })
      document.title = `Ranking`;
      try {
        document.getElementById("full").requestFullscreen()
      } catch (e) {}
    }else{
      sendServer({domain: organizacion, app:'global', action:'getCompetence', id:competencia_id});
      sendState({URLdomain:organizacion})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competence, URLdomain])
  useEffect(() => {
    let interval = setInterval(() => {// && params.ver==='resultados'
      if(organizacion===state.URLdomain && competence && competence.activa){
        sendServer({
          domain: organizacion,
          app: modalidad,
          action:'getRanking',
          competencia_id: competencia_id,
          panel:urlParams.get('panel')
        })
      }
    }, 8000);
    return ()=> clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [online, competence])
  if(!current.id||!competence)return null;
  return (
    <div className={`h100 ${params.chroma} seccion-datos`} id='full' onClick={() => { !document.fullscreenElement ? document.getElementById("full").requestFullscreen() : document.exitFullscreen() }} children={
      <>
        <link rel="stylesheet" href="/static/css/fullScreen.css" />
        <div className='bottomBlock'>
          <Row className={`marginAuto border${params.chroma}`} children={
            <>
              <Col xs={11} className='text-truncate marquee' children={
                <span className='marqueeChild' children={
                  `
                    ${competence.nombre_de_la_competencia} -
                    ${current[convenciones[modalidad]['aparato']]} -
                    ${current[convenciones[modalidad]['categoria_edad']]}
                  `
                }/>
              }/>
              <Col xs={1} className={`modalidadChroma`} style={{backgroundImage:`url('${URLdomain}/static/recursos/${competence.modalidad}/${current[convenciones[modalidad]['aparato']]}.png')`}}/>
            </>
          }/>
          <div className='autoScroll'>
            {ranking?
              ranking.map((r, index) =>
                <Row className={`marginAuto border${params.chroma}`} children={
                  <>
                    <Col xs={1} className='text-truncate text-center' children={index+1}/>
                    <Col xs={1} className='representacionChroma' style={{backgroundImage:`url('https://consugi.consugisoft.com/media/${r[convenciones[modalidad]['bandera']]}')`}}/>
                    <Col xs={8} className='text-truncate' children={`${r.participante}`}/>
                    <Col xs={2} className='text-truncate text-end' children={r.ranking_salto&&r.aparato==='SALTO'?r.salto.toFixed(3):r[convenciones[modalidad]['total']].toFixed(3)}/>
                  </>
                }/>
              )
            :null}
          </div>
        </div>
      </>
    } />
  )
}