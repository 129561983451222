import { useContext, useEffect, useState } from 'react'
import globalContext from '../../../../context/globalContext';
import { Header } from '../Header';
import { items } from "../ItemsHeader";

export const SubTable = (props) => {
  const { state, URLdomain } = useContext(globalContext);
  const [categorias, setcategorias] = useState([])
  const {localQuery, modalidad, concurso}= props;
  useEffect(() => {
    let categoriasList = [];
    localQuery.map(e=>{
      if(categoriasList.indexOf(modalidad!=='lista_de_equipos'?e.categoria:e.equipo__nombre))categoriasList.push(modalidad!=='lista_de_equipos'?e.categoria:e.equipo__nombre)
    })
    if(modalidad==='lista_de_equipos')categoriasList[0]+=` ${localQuery.length}`
    setcategorias(categoriasList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localQuery])
  
  const {competence} = state;
  return (
    <table className="centered striped table" children={
        <>
          <thead style={{}} children={
            <>
              <tr children={
                <th colSpan={4} style={{
                    height:'50px'
                  }}
                  children={
                  <div className='subEncabezado' style={{
                    backgroundImage:`url('${URLdomain}/static/recursos/${competence.modalidad}/${localQuery[0].aparato}.png')`,
                    height:'50px!important',
                    width:'100%'
                  }}/>}
                />
              }/>
              <Header modalidad={modalidad} final={concurso!=='I'} clases={'borderSubTable title'} categorias={categorias}/>
            </>
          }/>
          <tbody className="resultadosCont" children={
            <>
            {localQuery.map((q, index)=>
              <tr key={`${q.id}${index}`} className={q.class} children={
                <>
                  {items[competence.modalidad][modalidad].map((i, index)=>
                    concurso!=='I'&&i.item==='q'?null:
                      <td
                        key={`${q.id}${i}${index}`}
                        children={
                          i.toFixed?(
                            q[i.item]?q[i.item].toFixed(i.toFixed):''
                          )
                            :(i.item!=='representacion__bandera'&&i.item!=='equipo__bandera'?(typeof(q[i.item])==='string'?q[i.item].slice(0,30):q[i.item]):<div className='miniBandera' style={{backgroundImage:`url('https://consugi.consugisoft.com/media/${q[i.item]}')`}}/>)
                        }
                        className={i.clases+` ${q.numero_gimnasta_count===2&&i.item==='numero_gimnasta'?' bold':''}`}
                      />
                  )}
                </>
              }/>
            )}
            {localQuery.length===0?<tr children={<td colSpan={40} className='text-center' children={'...Sin resultados, consulta con sistemas para mas información.'}/>}/>:null}
            </>
          }/>
        </>
      }/>
  )
}