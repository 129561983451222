import React, { useContext, useEffect, useState } from 'react'
import Col from 'react-bootstrap/esm/Col'
import Row from 'react-bootstrap/esm/Row'
import globalContext from '../../context/globalContext'
import { Header } from '../competence/results/Header'
import { useParams } from "react-router-dom";
import { items } from '../competence/results/ItemsHeader'
import ModalCalendario from './calendario/ModalCalendario'

export const Calendario = () => {
  const { state, URLdomain, sendServer } = useContext(globalContext);
  const [event, setEvent] = useState({})
  const { competence, calendario } = state;
  const [localQuery, setlocalQuery] = useState([])
  const { organizacion, competencia_id } = useParams();
  useEffect(() => {
    sendServer({domain: organizacion, app:'global', action:'getCalendar', competencia_id: competencia_id});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    let newQuery = [];
    let inicio = null;
    if(calendario)calendario.map(e=>{
      newQuery.push({
        ...e,
        inicio:e.inicio.slice(0, 10)===inicio?null:e.inicio,
        horaInicio: e.inicio,
        object:e
      });
      inicio = e.inicio.slice(0, 10);
    })
    setlocalQuery(newQuery)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendario])
  const clearEvent = () => setEvent({});
  return (
    <>
      <table className="centered table newTable" children={
        <>
          <thead className='upper' children={
            <>
              <tr children={
                <th colSpan={40} children={
                  <>
                    <Row children={
                      <>
                        {competence?
                          <>
                            <Col
                              className="encabezado hide_md_down imgHeader"
                              children={
                                <img
                                  src={`https://consugi.consugisoft.com/media/${competence.organizadores}`}
                                />
                              }
                            />
                            <Col className="encabezado" xs={12} sm={8}>
                              <p className='text-center m0' children={competence.nombre_de_la_competencia} />
                              <p className='text-center m0' children={
                                <>
                                  <a href={`${competence.ubicacion}`} target="_blank" rel="noreferrer" children={competence.lugar} />
                                  <span> Del {competence.inicio} al {competence.fin}</span>
                                </>
                              } />
                              <p className='text-center m0' children={
                                "CALENDARIO"
                              } />
                            </Col>
                            <Col
                              className="encabezado hide_md_down imgHeader"
                              children={
                                <img
                                  src={`https://consugi.consugisoft.com/media/${competence.logo}`}
                                />
                            }/>
                          </>
                          : null}
                      </>
                    } />
                  </>
                } />
              } />
              <tr className='noPrint' children={
                <th colSpan={40} className='text-center' children={<ModalCalendario object={event} clearEvent={clearEvent}/>} />
              }/>
              <Header modalidad={'calendario'}/>
            </>
          } />
          <tbody className="resultadosCont" children={
            <>
              {localQuery.map((q, index)=>
                <tr onClick={()=>setEvent(q.object)} key={`${q.id}${index}`} className={q.class+'  changeOver'} children={
                  <>
                    {items[competence.modalidad]['calendario'].map((i, index)=>
                      <td
                        key={`${q.id}${i}${index}`}
                        children={i.format&&q[i.item]?i.format(q[i.item]):q[i.item]}
                        className={i.clases}
                      />
                    )}
                  </>
                }/>
              )}
            </>
          } />
        </>
      } />
    </>
  )
}