import React, { useContext, useEffect } from 'react'
import globalContext from '../../context/globalContext'
import { useParams } from 'react-router-dom'
import Col from 'react-bootstrap/esm/Col'
import Row from 'react-bootstrap/esm/Row'
import { convenciones } from "./Convenciones";

export default function Chroma() {
  const URLdomain = 'https://consugisoft.com'
  const {state, sendServer, URLdomainb, sendState} = useContext(globalContext)
  const {current, competence, online} = state;
  const { competencia_id, modalidad, organizacion } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const panel = urlParams.get('panel')
  const id = urlParams.get('id')
  useEffect(() => {
    document.title = `Chroma`;
    sendState({URLdomain:organizacion})
    sendServer({
      domain: organizacion,
      app: modalidad,
      action:id?'getInscripcion':'getCurrent',
      competencia_id: competencia_id,
      panel: panel,
      id: id,
    })
    let interval = setInterval(() => {
      sendServer({
        domain: organizacion, 
        app: modalidad,
        action:id?'getInscripcion':'getCurrent',
        competencia_id: competencia_id,
        panel: panel,
        id: id,
      })
    }, 6000);
    return ()=> clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [online, competence])
  if(!current.id)return null;
  return (
    <div className='chromaGreen h100' id='full' onClick={() => { !document.fullscreenElement ? document.getElementById("full").requestFullscreen() : document.exitFullscreen() }} children={
      <>
        <link rel="stylesheet" href="/static/css/fullScreen.css" />
        <main/>
        <div className='bottomBlock gradient seccion-datos-chromaB'>
          {current.publicado?
            <>
              {current[convenciones[modalidad]['artística_final']]?
                <Row className='marginAuto lh1' children={
                  <>
                    <Col xs={10} className='chromaGreen'/>
                    <Col xs={2} className='text-truncate text-end' children={
                      <>
                        <span children='A' className='float-start'/>
                        {current[convenciones[modalidad]['artística_final']].toFixed(3)}
                      </>
                    }/>
                  </>
                }/>
              :null}
              {current[convenciones[modalidad]['ejecución_final']]?
                <Row className='marginAuto lh1' children={
                  <>
                    <Col xs={10} className='chromaGreen'/>
                    <Col xs={2} className='text-truncate text-end' children={
                      <>
                        <span children='E' className='float-start'/>
                        {current[convenciones[modalidad]['ejecución_final']].toFixed(3)}
                      </>
                    }/>
                  </>
                }/>
              :null}
              {current[convenciones[modalidad]['dificultad']]?
                <Row className='marginAuto lh1' children={
                  <>
                    <Col xs={10} className='chromaGreen'/>
                    <Col xs={2} className='text-truncate text-end' children={
                      <>
                        <span children='D' className='float-start'/>
                        {current[convenciones[modalidad]['dificultad']].toFixed(3)}
                      </>
                    }/>
                  </>
                }/>
              :null}

              {current[convenciones[modalidad]['aparato']]==='IND'&&current.tiempo_de_vuelo?
                <Row className='marginAuto lh1' children={
                  <>
                    <Col xs={10} className='chromaGreen'/>
                    <Col xs={2} className='text-truncate text-end' children={
                      <>
                        <span children='T' className='float-start'/>
                        {current.tiempo_de_vuelo.toFixed(3)}
                      </>
                    }/>
                  </>
                }/>
              :null}
              {current[convenciones[modalidad]['aparato']]==='SYN'&&current.sincronizacion?
                <Row className='marginAuto lh1' children={
                  <>
                    <Col xs={10} className='chromaGreen'/>
                    <Col xs={2} className='text-truncate text-end' children={
                      <>
                        <span children='S' className='float-start'/>
                        {current.sincronizacion.toFixed(3)}
                      </>
                    }/>
                  </>
                }/>
              :null}
              {(current[convenciones[modalidad]['aparato']]==='SYN'||current[convenciones[modalidad]['aparato']]==='IND')&&current.desplazamiento_horizontal?
                <Row className='marginAuto lh1' children={
                  <>
                    <Col xs={10} className='chromaGreen'/>
                    <Col xs={2} className='text-truncate text-end' children={
                      <>
                        <span children='H' className='float-start'/>
                        {current.desplazamiento_horizontal.toFixed(3)}
                      </>
                    }/>
                  </>
                }/>
              :null}
              {current[convenciones[modalidad]['neutrales']]?
                <Row className='marginAuto lh1' children={
                  <>
                    <Col xs={10} className='chromaGreen'/>
                    <Col xs={2} className='text-truncate text-end red-text' children={
                      <>
                        <span children='' className='float-start'/>
                        {current[convenciones[modalidad]['neutrales']].toFixed(3)}
                      </>
                    }/>
                  </>
                }/>
              :null}
            </>
          :null}
          <Row className='marginAuto' children={
            <>
            
              {modalidad==='Ritmica'?
                <Col xs={1} className='modalidadChroma chromaGreen' style={{ backgroundImage: `url('${URLdomain}/static/media/${current['aparato__icono']}')` }}/>
              :
                <Col
                  xs={1}
                  className={modalidad==='Trampolin'?null:'modalidadChroma chromaGreen'}
                  style={{borderRadius:'3px', backgroundImage:`url('${URLdomain}/static/recursos/${modalidad}/${current[convenciones[modalidad]['aparato']]}.png')`}}
                  children={modalidad==='Trampolin'?current.aparato:null}
                  />
              }
              
              <Col xs={1} className='representacionChroma' style={{backgroundImage:`url('https://consugi.consugisoft.com/media/${current[convenciones[modalidad]['bandera']]}')`}}/>
              <Col xs={current.publicado?8:10} className='text-truncate' children={`
                ${current[convenciones[modalidad]['siglas']]} | ${current[convenciones[modalidad]['categoria_edad']]}
                | ${current.participante}
              `}/>
              {current.publicado?
                <Col xs={2} className='text-truncate text-end' children={
                  <b>
                    <span children='T' className='float-start'/>
                    {current[convenciones[modalidad]['total']]!==null?current[convenciones[modalidad]['total']].toFixed(3):'DNS'}
                  </b>
                }/>
              :null}
            </>
          }/>
        </div>
      </>
    } />
  )
}