import React, { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useParams } from "react-router-dom";
import globalContext from '../../../context/globalContext';

const ModalCalendario = (props) => {
  const { object, clearEvent } = props;
  const {sendServer, sendState, state } = useContext(globalContext);
  const {success, calendario} = state;
  let { competencia_id, modalidad, organizacion, puesto, panel } = useParams();
  const initial = () => {
    return {
      competencia_id:null,
      inicio:(calendario&&calendario.length>0)?calendario[calendario.length-1].inicio:new Date().toISOString().slice(0, 10)+'T00:00',
      fin :(calendario&&calendario.length>0)?calendario[calendario.length-1].fin:'00:00',
      evento:'',
      detalles: ''
    }
  }
  const [event, setEvent] = useState(initial())
  const [deleteObject, setdeleteObject] = useState(false)
  useEffect(() => {
    if(object.id)setEvent(object)
  }, [object])
  useEffect(() => {
    if(success){setEvent(initial());sendState({success:null})}
  }, [success])
  
  const onChange = e => {
    setEvent({...event, [e.target.name]:e.target.value})
  }
  const save = () => {
    sendServer(
      {
        domain: organizacion,
        app: 'global',
        action: event.id?'updateEvent':'createEvent',
        competencia_id: competencia_id,
        params: event,
        puesto: puesto,
        panel: panel,
      });
  }
  const deleteEvent = () => {
    sendServer(
      {
        domain: organizacion,
        app: 'global',
        action: 'deleteEvent',
        competencia_id: competencia_id,
        params: event,
        puesto: puesto,
        panel: panel,
      });
    clear()
  }
  const clear = () =>{
    clearEvent()
    setEvent(initial())
    setdeleteObject(false)
  }
  const onChangeInicio = e =>{
    console.log(e.target.value, event.inicio.slice(11,16), event.inicio)
    if(e.target.name==='time'){
      // 2022-12-16T01:01:00
      setEvent({...event, inicio: event.inicio.slice(0,10)+'T'+e.target.value+':00'})
    }else{
      setEvent({...event, inicio: e.target.value+'T'+event.inicio.slice(11,16)+':00'})
    } 
  }
  return (
    <>
      <Button variant="outline-primary" onClick={()=>setEvent({...initial(), competencia_id:competencia_id})}>
        NUEVO EVENTO
      </Button>

      <Modal show={Boolean(event.competencia_id)} onHide={clear}>
        <Modal.Header closeButton className={deleteObject?'btn-danger':''}>
          <Modal.Title>{deleteObject?'CONFIRMAR ELIMINAR':(event.id?'MODIFICAR':'NUEVO')} EVENTO</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Fecha</Form.Label>
              <Form.Control
                autoFocus
                type="date"
                name='inicio'
                onChange={onChangeInicio}
                value={event.inicio?event.inicio.slice(0,10):''}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Inicio</Form.Label>
              <Form.Control
                type="time"
                name='time'
                onChange={onChangeInicio}
                value={event.inicio?event.inicio.slice(11,16):''}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Fin</Form.Label>
              <Form.Control
                type="time"
                name='fin'
                onChange={onChange}
                value={event.fin}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Evento</Form.Label>
              <Form.Control
                type="text"
                name='evento'
                onChange={onChange}
                value={event.evento}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Detalles</Form.Label>
              <Form.Control
                as="textarea"
                rows={2}
                name='detalles'
                onChange={onChange}
                value={event.detalles}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={deleteObject?()=>setdeleteObject(false):clear}>
            CANCELAR
          </Button>
          {Boolean(event.id)&&!deleteObject?<Button variant="danger" onClick={()=>setdeleteObject(true)}>
            ELIMINAR
          </Button>:''}
          {deleteObject?<Button variant="danger" onClick={deleteEvent}>
            CONFIRMAR
          </Button>:''}
          {!deleteObject?<Button variant="primary" onClick={save}>
          {event.id?'GUARDAR':'CREAR'}
          </Button>:''}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalCalendario;