import React, {useContext, useEffect, useState} from 'react'
import globalContext from '../../context/globalContext';
import { useNavigate, useParams } from "react-router-dom";
import Row from 'react-bootstrap/Row'

export const Competences = ()=> {
  const initialFilter = {modalidad:'', range1:'', range2:''};
  const [filter, setfilter] = useState(initialFilter)//, getLocalData 
  const { state, sendServer, URLdomain, sendState} = useContext(globalContext);
  const { competences, domains } = state;
  const navigate = useNavigate();
  let { organizacion } = useParams();
  
  useEffect(() => {
    document.title = `Soft`;
    if(domains[organizacion]){
      if(organizacion!==state.URLdomain)sendState({URLdomain:organizacion})
      if(state.URLdomain!=='')sendServer({domain: organizacion, app:'global', action:'getInitial'});
      let urlParams = new URLSearchParams(window.location.search);
      let newFilter= filter;
      for (let f in filter) {
        if(urlParams.get(f)){
          newFilter={...newFilter,[f]:urlParams.get(f)};
        }
      }
      setfilter(newFilter);
      sendState({competence:null, query:[], current:{}, filtros:{}})  
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.URLdomain, domains[organizacion]])


  const onChange = e =>{
    let newFilter = {...filter,[e.target.name]:e.target.value};
    if(e.target.value==='Limpiar'){
      newFilter=initialFilter;
    }
    setfilter(newFilter)
    let newUrl = '?'
    for (let f in newFilter) {
      if(newFilter[f]!==''){
        newUrl+=`${newUrl==='?'?'':'&'}${f}=${newFilter[f]}`
      }
    }
    navigate(newUrl);
  }
  return (
    <>
      <h1 children='Competencias' className='text-center'/>
      <hr/>
      <div className="row marginAuto">
        <div className="col-xs-12 col-md-6"> 
        <div className='row marginAuto' children={<h6 className="text-end">Modalidad</h6>}/>
          
          <div className='row marginAuto' children={
            <>
              <input className="col m-2" type='button' value='Limpiar' onClick={onChange}/>
              <select className='col m-2' name='modalidad' value={filter.modalidad} onChange={onChange} children={
                <>
                  <option></option>
                  <option value="Aerobica">Aerobica</option>
                  <option value="Artistica">Artística</option>
                  <option value="Ritmica">Rítmica</option>
                  <option value="Trampolin">Trampolín</option>
                </>
              }/>
            </>
          }/>
        </div>
        <div className="col-xs-12 col-md-6">
        <div className='row marginAuto' children={<h6 className="text-center">Rango De Fechas</h6>}/>
          <div className='row marginAuto' children={
            <>
              <input className='col m-2' type="date" name='range1' value={filter.range1} onChange={onChange}/>
              <input className='col m-2' type="date" name='range2' value={filter.range2} onChange={onChange}/>
            </>
          }/>
        </div>
      </div>
      <hr/>
      {competences?
        <Row className='marginAuto' children={
          // eslint-disable-next-line array-callback-return
          competences.map(c=>{
            if(
              (filter.modalidad===''||filter.modalidad===c.modalidad)&&
              (filter.range1===''||new Date(c.inicio)>=new Date(filter.range1))&&
              (filter.range2===''||new Date(c.inicio)<=new Date(filter.range2))){
              return <div key={c.id} className="col-xs-12 col-md-12 col-lg-6" children={//
                <div className='card m-2 link' onClick={()=>{navigate(`${c.modalidad}/${c.id}`)}} children={
                  <div className="row g-0">
                    <div className="col-sm-5 competences" style={{backgroundImage:`url("https://consugi.consugisoft.com/media/${c.publicidad}")`}}/>
                    <div className="col-sm-7 mh165">
                      <div className="card-body">
                        <h5 className="card-title">{c.nombre_de_la_competencia}</h5>
                        <a href={`${c.ubicacion}`} target='_blank' rel="noreferrer">
                          <span children={`${c.lugar}`}/>
                        </a><br/>
                        Del <span children={`${c.inicio}`}/> al <span children={`${c.fin}`}/>
                      </div>
                    </div>
                  </div>
                }/>
              }/>
            }
          })
        }/>
      :null}
    </>
  )
}