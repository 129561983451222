import React, { useContext, useEffect, useState } from 'react'
import globalContext from '../../context/globalContext'
import { useParams } from 'react-router-dom'
import Col from 'react-bootstrap/esm/Col'
import Row from 'react-bootstrap/esm/Row'
import { convenciones } from "./Convenciones";

export default function Team() {
  const URLdomain = 'https://consugisoft.com'
  const {state, sendServer } = useContext(globalContext)
  const {current, competence, online, ranking} = state;
  let { competencia_id, modalidad, organizacion} = useParams();
  const [params, setParams] = useState({chroma:'black'})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  let urlParams = new URLSearchParams(window.location.search);
  const [localRanking, setlocalRanking] = useState([])
  useEffect(() => {
    if(urlParams.get('chroma')){
      setParams({chroma:urlParams.get('chroma')});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(ranking){
        let newRanking = []
        let representacion = '';

        ranking.forEach(e => {
            if(modalidad==='Artistica' && representacion!==e.equipo){
              newRanking.push({
                ...e,
                representacion:e.equipo,
              });
              representacion=e.equipo;
            }else if(modalidad!=='Artistica' && representacion!==e.representacion){
              newRanking.push(e)
              representacion=e.representacion;
            };
        });

        setlocalRanking(newRanking)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ranking])
  
  useEffect(() => {
    if(competence){
      sendServer({
        domain: organizacion,
        app: modalidad,
        action:'getTeam',
        competencia_id: competencia_id,
        subdivision: 1,
        categoria: urlParams.get('categoria'),
        niveles_usag: competence.niveles_usag,
        })
      document.title = `Team`;
      try {
        document.getElementById("full").requestFullscreen()
      } catch (e) {}
    }else{
      sendServer({domain: organizacion, app:'global', action:'getCompetence', id:competencia_id});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competence, URLdomain])
  
  useEffect(() => {
    let interval = setInterval(() => {// && params.ver==='resultados'
      if(competence && competence.activa){
        sendServer({
          domain: organizacion,
          app: modalidad,
          action:'getTeam',
          competencia_id: competencia_id,
          subdivision: 1,
          categoria: urlParams.get('categoria'),
          niveles_usag: competence.niveles_usag,
        })
      }
    }, 8000);
    return ()=> clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [online, competence])
  // if(!current.id||!competence)return null;
  if(!competence)return null;
  return (
    <div className={`h100 ${params.chroma}`} id='full' onClick={() => { !document.fullscreenElement ? document.getElementById("full").requestFullscreen() : document.exitFullscreen() }} children={
      <>
        <link rel="stylesheet" href="/static/css/fullScreen.css" />
        <div className='bottomBlock'>
          <Row className={`marginAuto border${params.chroma} gradient`} children={
            <>
              <Col xs={11} className='text-truncate marquee' children={
                <span className='marqueeChild' children={
                  `
                    ${competence.nombre_de_la_competencia} -
                    ${current[convenciones[modalidad]['categoria_edad']]}
                  `
                }/>
              }/>
              <Col xs={1} className={`modalidadChroma`} style={{backgroundImage:`url('${URLdomain}/static/recursos/${competence.modalidad}/team.png')`}}/>
            </>
          }/>
          {localRanking?
            localRanking.map((r, index) =>
              <Row className={`marginAuto border${params.chroma} gradient`} key={index+1} children={
                <>
                  <Col xs={1} className='text-truncate text-center' children={index+1}/>
                  <Col xs={1} className='representacionChroma' style={{backgroundImage:`url('https://consugi.consugisoft.com/media/${r[convenciones[modalidad]['bandera']]}')`}}/>
                  <Col xs={8} className='text-truncate' children={`${r.representacion}`}/>
                  <Col xs={2} className='text-truncate text-end' children={r[convenciones[modalidad]['total_team']].toFixed(3)}/>
                </>
              }/>
            )
          :null}
        </div>
      </>
    } />
  )
}