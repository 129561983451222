export const formatTotal = (total, dns) => {
  if (dns) {
    return "DNS"; // Si el gimnasta no participó, mostrar "DNS"
  }

  if (total === null || total === undefined) {
    return ""; // Si es null o undefined, devuelve una cadena vacía
  }

  // Intenta convertir a número
  const number = parseFloat(total);

  // Si la conversión es exitosa y no es NaN, devuelve el número formateado
  if (!isNaN(number)) {
    return number.toFixed(3);
  }

  // Si no se puede convertir a número, devuelve el valor original como string
  return total.toString();
};
