import React, { useContext, useEffect, useState } from "react";
import globalContext from "../../../context/globalContext";
import { useParams } from "react-router-dom";
import { convenciones } from "../Convenciones";

export default function Team() {
  const URLdomain = "https://consugi.consugisoft.com";
  const { state, sendServer } = useContext(globalContext);
  const { current, competence, online, ranking } = state;
  let { competencia_id, modalidad, organizacion } = useParams();
  const [params, setParams] = useState({ chroma: "black" });
  let urlParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (urlParams.get("chroma")) {
      setParams({ chroma: urlParams.get("chroma") });
    }
  }, []);

  useEffect(() => {
    if (competence) {
      sendServer({
        domain: organizacion,
        app: modalidad,
        action: "getTeam",
        competencia_id: competencia_id,
        subdivision: 1,
        categoria: urlParams.get("categoria"),
        niveles_usag: competence.niveles_usag,
      });
      document.title = `Team`;
    } else {
      sendServer({
        domain: organizacion,
        app: "global",
        action: "getCompetence",
        id: competencia_id,
      });
    }
  }, [competence, URLdomain]);

  useEffect(() => {
    let interval = setInterval(() => {
      if (competence && competence.activa) {
        sendServer({
          domain: organizacion,
          app: modalidad,
          action: "getTeam",
          competencia_id: competencia_id,
          subdivision: 1,
          categoria: urlParams.get("categoria"),
          niveles_usag: competence.niveles_usag,
        });
      }
    }, 8000);
    return () => clearInterval(interval);
  }, [online, competence]);

  // Ordenar el ranking: primero los equipos con puntuaciones válidas, luego DNS, DNF y DSQ
  const sortedRanking = ranking
    ? [...ranking].sort((a, b) => {
        const aStatus = a.dns || a.dnf || a.dsq;
        const bStatus = b.dns || b.dnf || b.dsq;

        // Si a tiene DNS, DNF o DSQ y b no, mueve a al final
        if (aStatus && !bStatus) return 1;
        // Si b tiene DNS, DNF o DSQ y a no, mueve b al final
        if (!aStatus && bStatus) return -1;
        // Si ambos tienen DNS, DNF o DSQ, mantener el orden original
        if (aStatus && bStatus) return 0;

        // Ordenar por puntuación descendente
        return (
          b[convenciones[modalidad]["total_team"]] -
          a[convenciones[modalidad]["total_team"]]
        );
      })
    : [];

  if (!competence) return null;

  return (
    <div
      className="seccion-datos ranking h100"
      id="full"
      style={{ padding: "0%" }}
      onClick={() => {
        if (!document.fullscreenElement) {
          document
            .getElementById("full")
            .requestFullscreen()
            .catch((err) => {
              console.error(
                `Error attempting to enable full-screen mode: ${err.message} (${err.name})`
              );
            });
        } else {
          document.exitFullscreen();
        }
      }}
    >
      <link rel="stylesheet" href="/static/css/fullScreen.css" />
      <link href="/static/css/estilos.css" rel="stylesheet" />
      <div>
        <header
          className="cabecera-titulo"
          style={{
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <img src="/static/img/ASU2025_B.png" />
          <div>
            <div style={{ gap: "10px" }}>
              <h1 style={{ fontSize: "60px" }}>
                {competence.modalidad.toUpperCase()}
              </h1>
              <h2
                style={{
                  paddingTop: "10px",
                  fontSize: "30px",
                }}
              >
                RESULTADOS FINALES POR EQUIPO {urlParams.get("categoria")}
              </h2>
            </div>
          </div>
          <img
            src={`/static/img/${competence.modalidad}.png`}
            alt="logo"
            style={{ marginLeft: "0%" }}
          />
        </header>

        <div className="datos">
          <div
            className="col-md-9 bottomBlock"
            style={{
              width: "100%",
            }}
          >
            <div
              className="text-center col-3 text-yellow"
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                justifyItems: "center",

                alignItems: "center",
                paddingLeft: "30px",
              }}
            >
              <h1 style={{ fontSize: "40px", fontWeight: "bolder" }}>
                RESULTADO
              </h1>
            </div>

            <div className="scrollRank" style={{ maxHeight: "67vh" }}>
              {sortedRanking
                ? sortedRanking.map((r, index) => (
                    <div
                      className="marginAuto bordergreen gradient row fila-dato"
                      id={index + 1}
                      key={index + 1}
                      style={{
                        height: "0%",
                        display: "flex",
                        alignItems: "start",
                        justifyContent: "space-between",
                        flexGrow: "1",
                        padding: "none",
                      }}
                    >
                      <div
                        className="text-center col-1"
                        style={{ fontSize: "50px" }}
                      >
                        {index + 1}
                      </div>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "15%",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          className="col-1"
                          style={{ color: "yellow", fontSize: "50px" }}
                        >
                          {r[convenciones[modalidad]["siglas"]]}
                        </div>
                        <div
                          style={{
                            backgroundImage: `url('https://consugi.consugisoft.com/media/${
                              r[convenciones[modalidad]["bandera"]]
                            }')`,
                            marginRight: "5px",
                          }}
                          className="representacionChroma col-1"
                        ></div>
                      </div>

                      <div className="col" style={{ fontSize: "50px" }}>
                        {r[convenciones[modalidad]["representacion"]]}
                      </div>

                      <div
                        className="text-end"
                        style={{
                          fontSize: "1.2em",
                          fontSize: "50px",
                          width: "13%",
                        }}
                      >
                        {
                          r.dns // Si el equipo no participó (DNS)
                            ? "DNS" // Muestra "DNS"
                            : r.dnf // Si el equipo no terminó (DNF)
                            ? "DNF" // Muestra "DNF"
                            : r.dsq // Si el equipo fue descalificado (DSQ)
                            ? "DSQ" // Muestra "DSQ"
                            : r[convenciones[modalidad]["total_team"]] !== null // Si no es DNS, DNF o DSQ, verifica si el total_team no es null
                            ? r[convenciones[modalidad]["total_team"]].toFixed(
                                3
                              ) // Muestra el total_team formateado
                            : "" // Si no cumple las condiciones, muestra una cadena vacía
                        }
                      </div>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
        <footer>
          <img src="/static/img/footer.png" alt="Footer" />
        </footer>
      </div>
    </div>
  );
}
