import React, { useContext, useEffect, useState } from 'react'
import globalContext from '../../../context/globalContext'
import { useParams } from 'react-router-dom'
import { convenciones } from "../Convenciones";

export default function Ranking() {
  const URLdomain = 'https://consugi.consugisoft.com'
  const {state, sendServer, URLdomainb, sendState} = useContext(globalContext)
  const {current, competence, online, ranking} = state;
  let { competencia_id, modalidad, organizacion} = useParams();
  const [params, setParams] = useState({chroma:'black', panel: null})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  let urlParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    if(urlParams.get('chroma')){
      setParams({chroma:urlParams.get('chroma'), panel:urlParams.get('panel'), });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(organizacion===state.URLdomain&&competence){
      sendServer({
        domain: organizacion,
        app: modalidad,
        action:'getRanking',
        competencia_id: competencia_id,
        panel:urlParams.get('panel')
      })
      document.title = `Ranking`;
      try {
        document.getElementById("full").requestFullscreen()
      } catch (e) {}
    }else{
      sendServer({domain: organizacion, app:'global', action:'getCompetence', id:competencia_id});
      sendState({URLdomain:organizacion})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competence, URLdomain])
  useEffect(() => {
    let interval = setInterval(() => {// && params.ver==='resultados'
      if(organizacion===state.URLdomain && competence && competence.activa){
        sendServer({
          domain: organizacion,
          app: modalidad,
          action:'getRanking',
          competencia_id: competencia_id,
          panel:urlParams.get('panel')
        })
      }
    }, 8000);
    return ()=> clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [online, competence])
  let cantidades = [0, 9, 16, 23, 30, 37]
  let listScroll = [0, 354, 708, 1062, 1416, 1770]
  const [currentscroll, setcurrentscroll] = useState(0)
  useEffect(() => {//scroll automatico
    if(!urlParams.get('noScroll')){
      let interval = setInterval(() => {
        if(ranking.length > cantidades[currentscroll+1]){
          setcurrentscroll(currentscroll+1)
        }else{
          setcurrentscroll(0)
        }
        document.querySelector("div.scrollRank").scrollTo( 0, listScroll[currentscroll])
      }, 10000);
      return ()=> clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentscroll, ranking.length])
  if(!current.id||!competence)return null;
  return (
    <div className={`h100 ${params.chroma}`} id="full" onClick={() => { !document.fullscreenElement ? document.getElementById("full").requestFullscreen() : document.exitFullscreen() }} >
      <link rel="stylesheet" href="/static/css/fullScreen.css"/>
      <link href="/static/css/estilos.css" rel="stylesheet"/>
        <div className="seccion-datos">
          <div className="cabecera-titulo">
            {/* <img src={`/static/img/${competence.modalidad}.png`} alt='logo'/> */}
              <h1>{competence.modalidad.toUpperCase()}</h1>
              <h2>RESULTADOS {current.concurso==='III'||current.final?'- FINAL':null} - {current[convenciones[modalidad]['aparato']]}</h2>
          </div>
          <div className="datos">
            <div className="col-md-9 bottomBlock">
              <div className="marginAuto bordergreen gradient row">
                <div className="text-truncate marquee col-11"><span className="marqueeChild">
                  {`
                    ${competence.nombre_de_la_competencia} -
                    ${current[convenciones[modalidad]['categoria_edad']]}
                  `}
                </span></div>
                
                {modalidad==='Ritmica'?
                  <div style={{ backgroundImage: `url('${URLdomain}/static/media/${current['aparato__icono']}')` }}
                  className="modalidadChroma col-1"></div>
                :
                <div style={{backgroundImage:`url('${URLdomain}/static/recursos/${current.aparato}.png')`}}
                  className="modalidadChroma col-1">{modalidad==='Trampolin'?current.aparato:null}</div>
                }
              </div>

              <div className="marginAuto bordergreen gradient row fila-dato">
                <div className="text-truncate text-center col-2"></div>
                <div className="text-truncate col"></div>
                <div className="text-truncate text-end col-3 text-yellow">RESULT</div>
              </div>

              <div className='scrollRank'>
                {ranking ?
                  ranking.map((r, index) =>
                    <div className="marginAuto bordergreen gradient row fila-dato" id={index + 1} key={index + 1}>
                      <div className="text-truncate text-center col-1">{r.total_global!==null?index + 1:null}</div>
                      <div style={{ backgroundImage: `url('https://consugi.consugisoft.com/media/${r[convenciones[modalidad]['bandera']]}')` }}
                        className="representacionChroma col-1"></div>
                      <div className="text-truncate col">{`${r.participante}`}</div>
                        <div className="text-truncate text-end col-3">
                        {modalidad!=='Trampolin'?
                          `${r.ranking_salto&&r.aparato==='SALTO'?r.salto.toFixed(3):r[convenciones[modalidad]['total']].toFixed(3)}`
                        :
                          r.total_global!==null?r.total_global.toFixed(3):'DNS'
                        }
                        </div>
                    </div>
                  )
                : null}
              </div>
            </div>
            <div className="col-md-3 bottomBlock">
              <img src={`https://consugi.consugisoft.com/media/${competence.logo}`} style={{width: '16vw'}} alt='logo'/>
            </div>
          </div>
        </div>
    </div>
  )
}