import React, {useContext, useEffect} from 'react'
import globalContext from '../../context/globalContext';
import { useNavigate } from "react-router-dom";
import Row from 'react-bootstrap/Row'

export const Home = ()=> {
  const { sendState, state, URLdomain} = useContext(globalContext);
  const {subdominios} = state;
  const navigate = useNavigate();
  const classOrg = 'col-xs-12 col-sm-6 col-md-4 organizaciones'
  useEffect(() => {
    document.title = `Soft`;
    sendState({URLdomain:'consugi', competences:[]})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <Row className='marginAuto' children={
    <>
      <h1 children='Seleccione Una Organizacion' className='text-center'/>
      <hr/>
      <div
        className={classOrg}
        onClick={()=>navigate('/consugi')}
        style={{backgroundImage:`url("/static/img/consugi.png")`}}
      />
      {
        subdominios.map(e=>
          <div
            className={classOrg}
            key={e.nombre}
            onClick={()=>{sendState({URLdomain:e.nombre});navigate(`/${e.nombre}`)}}
            style={{backgroundImage:`url("https://consugi.consugisoft.com/media/${e.logoFederacion}")`}}
          />
        )
      }
    </>
  }/>
}