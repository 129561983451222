// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.judgment_btn-div__8bSBt {
    cursor: pointer!important;
    text-align: center!important;
    align-items: center!important;
    padding: 20px 2px!important;
    border: 2px solid white!important;
}
  
.judgment_btn-disabled__pOige {
    cursor: default!important;
    color: #fff!important;
    background-color: #9fa4aa!important;
    border-color: #6c757d!important;
}

.judgment_btn-primary__V-HxV {
    background-color: #0d6efd!important;
    color:white!important;
}

.judgment_btn-danger__FY0Je {
    background-color: #D93D4A!important;
    color: white!important;
}

.judgment_btn-dnf__IC4z9 {
    background-color: black!important;
    color: red!important;
}

.judgment_btn-warning__rCbdY {
    background-color: #ffc107!important;
    color: white!important;
}

.judgment_success__NPn9Q {
    background-color: #198754!important;
    color: white!important;
}

.judgment_warning__UCsro {
    background-color: #ffc107!important;
    color: black!important;
}

.judgment_textEnd__t2zix {
    text-align: end!important;
}

.judgment_tableRow__F9y5g {
    cursor: pointer!important;
}
  
.judgment_tableRow__F9y5g:hover {
    background-color: #FDC021!important;
}

tbody, td, tfoot, th, thead, tr {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    background-color: inherit !important;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/judgment/styles/judgment.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,4BAA4B;IAC5B,6BAA6B;IAC7B,2BAA2B;IAC3B,iCAAiC;AACrC;;AAEA;IACI,yBAAyB;IACzB,qBAAqB;IACrB,mCAAmC;IACnC,+BAA+B;AACnC;;AAEA;IACI,mCAAmC;IACnC,qBAAqB;AACzB;;AAEA;IACI,mCAAmC;IACnC,sBAAsB;AAC1B;;AAEA;IACI,iCAAiC;IACjC,oBAAoB;AACxB;;AAEA;IACI,mCAAmC;IACnC,sBAAsB;AAC1B;;AAEA;IACI,mCAAmC;IACnC,sBAAsB;AAC1B;;AAEA;IACI,mCAAmC;IACnC,sBAAsB;AAC1B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,mCAAmC;AACvC;;AAEA;IACI,qBAAqB;IACrB,mBAAmB;IACnB,eAAe;IACf,oCAAoC;EACtC","sourcesContent":[".btn-div {\n    cursor: pointer!important;\n    text-align: center!important;\n    align-items: center!important;\n    padding: 20px 2px!important;\n    border: 2px solid white!important;\n}\n  \n.btn-disabled {\n    cursor: default!important;\n    color: #fff!important;\n    background-color: #9fa4aa!important;\n    border-color: #6c757d!important;\n}\n\n.btn-primary {\n    background-color: #0d6efd!important;\n    color:white!important;\n}\n\n.btn-danger {\n    background-color: #D93D4A!important;\n    color: white!important;\n}\n\n.btn-dnf {\n    background-color: black!important;\n    color: red!important;\n}\n\n.btn-warning {\n    background-color: #ffc107!important;\n    color: white!important;\n}\n\n.success {\n    background-color: #198754!important;\n    color: white!important;\n}\n\n.warning {\n    background-color: #ffc107!important;\n    color: black!important;\n}\n\n.textEnd {\n    text-align: end!important;\n}\n\n.tableRow {\n    cursor: pointer!important;\n}\n  \n.tableRow:hover {\n    background-color: #FDC021!important;\n}\n\ntbody, td, tfoot, th, thead, tr {\n    border-color: inherit;\n    border-style: solid;\n    border-width: 0;\n    background-color: inherit !important;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn-div": `judgment_btn-div__8bSBt`,
	"btn-disabled": `judgment_btn-disabled__pOige`,
	"btn-primary": `judgment_btn-primary__V-HxV`,
	"btn-danger": `judgment_btn-danger__FY0Je`,
	"btn-dnf": `judgment_btn-dnf__IC4z9`,
	"btn-warning": `judgment_btn-warning__rCbdY`,
	"success": `judgment_success__NPn9Q`,
	"warning": `judgment_warning__UCsro`,
	"textEnd": `judgment_textEnd__t2zix`,
	"tableRow": `judgment_tableRow__F9y5g`
};
export default ___CSS_LOADER_EXPORT___;
