import React, { useContext, useEffect } from 'react';
import { useParams } from "react-router-dom";
import globalContext from '../../context/globalContext';
import { Editor } from '../Editor';
import Cjp from './Aerobica/Cjp';
import Dificultad from './Artistica/Dificultad';
import Generic from './Generic';
import CjpParkour from './Parkour/CjpParkour';
import Coordinador from './Ritmica/Coordinador';
import Arbitro from './Trampolin/Arbitro';

const puestos = {
  Aerobica:{
    cjp:<Cjp/>,
    editor:<Editor/>,
  },
  Artistica:{
    dificultad:<Dificultad/>,
    editor:<Editor/>,
  },
  Trampolin:{
    arbitro:<Arbitro/>,
    dificultad:<Dificultad/>,
    editor:<Editor/>,
  },
  Ritmica:{
    coordinador:<Coordinador/>,
    dificultad:<Dificultad/>,
    editor:<Editor/>,
  },
  Parkour:{
    CjpParkour:<CjpParkour/>,
    editor:<Editor/>,
  },
}

export default function Judge() {
  const { state, sendServer, sendState } = useContext(globalContext);
  const { online, competence } = state;
  let { puesto, competencia_id, organizacion } = useParams();

  useEffect(() => {
    if(organizacion!==state.URLdomain){
      sendState({URLdomain:organizacion})
    }
    if(online&&!competence)sendServer({domain: organizacion, app:'global', action:'getCompetence', id:competencia_id});
    sendServer({domain: organizacion, app:'global', action:'getAsignations', id:competencia_id});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.URLdomain, competence])

  if(!competence)return null;
  return (
    <div className='noSelect' children={puestos[competence.modalidad][puesto]?puestos[competence.modalidad][puesto]:<Generic/>}/>
  )
}