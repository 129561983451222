import React, { useContext, useEffect, useState } from 'react'
import { useParams, useNavigate } from "react-router-dom";
import globalContext from '../../context/globalContext';
import { Filters } from "./results/Filters";
import { Table } from "./results/Table";
import { TableOrdArt } from "./results/art/TableOrdArt";

const consursos = {
  'I': "CLASIFICACIÓN",
  'II': "ALLAROUND",
  'III': "FINAL",
  'IV': "TEAM",
}

export const Competence = ()=> {
  const { state, sendServer, sendState } = useContext(globalContext);
  const [params, setParams] = useState({subdivision:1, categoria:null, ver:'resultados', concurso:'I', modalidad:null})
  const { online, competence, query, filtros, current } = state;
  const {modalidades, categorias,} = filtros;
  let { competencia_id, modalidad, organizacion } = useParams();
  const navigate = useNavigate();
  
  useEffect(() => {
    if(organizacion!==state.URLdomain){
      sendState({URLdomain:organizacion})
    }else{
      let urlParams = new URLSearchParams(window.location.search);
      let newFilter= params;
      for (let p in params) {
        if(urlParams.get(p) && urlParams.get(p)!=='undefined'){
          newFilter={...newFilter, [p]: urlParams.get(p)};
        }
      }
      if(newFilter.categoria){
        if(newFilter.subdivision)newFilter.subdivision = parseInt(newFilter.subdivision)
        setParams(newFilter);
      };
      sendServer({domain: organizacion, app:'global', action:'getAsignations', id:competencia_id});
      sendState({authorized:undefined});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.URLdomain])

  useEffect(() => {
    if(organizacion===state.URLdomain){
      if(!competence){
        sendServer({domain: organizacion, app:'global', action:'getCompetence', id:competencia_id});
        sendServer({
          domain: organizacion,
          app: modalidad,
          action:params.ver==='resultados'?'getInitial':params.ver,
          competencia_id:competencia_id,
          params:params
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competence, state.URLdomain])

  useEffect(() => {
    if(categorias&&!categorias.includes(params['categoria'])&&params.subdivision){
      console.log('actualizando categoria', categorias.includes(params['categoria']))
      console.log(params, categorias, )
      setParams({...params, categoria:categorias[0]})
      // reload({...params, categoria:categorias[0]})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.subdivision, categorias])
  
  useEffect(() => {
    let modalidad, concurso, categoria;
    if(!params.categoria&&current&&competence){
      if(competence.modalidad==='Aerobica'){
        concurso = (current.final?'III':'I');
        modalidad = current.categoria_de_participacion;
        categoria = current.categoria_edad;
        let newParams = {
          subdivision:current.subdivision,
          categoria:categoria,
          ver:'resultados',
          concurso: concurso,
          modalidad:modalidad
        }
        setParams(newParams)
        let newUrl = '?'
        for (let f in newParams) {
          if(newParams[f]!==''){
            newUrl+=`${newUrl==='?'?'':'&'}${f}=${newParams[f]}`
          }
        }
        navigate(newUrl);
      }else if(competence.modalidad==='Artistica'){
        concurso = current.consurso;
        modalidad = current.aparato;
        categoria = current.categoria;
        let newParams = {
          subdivision:current.subdivision,
          categoria:categoria,
          ver:'resultados',
          concurso: current.concurso,
          modalidad:modalidad
        }
        setParams(newParams)
        let newUrl = '?'
        for (let f in newParams) {
          if(newParams[f]!==''){
            newUrl+=`${newUrl==='?'?'':'&'}${f}=${newParams[f]}`
          }
        }
        navigate(newUrl);
      }
    }
    if(modalidades && (modalidades.indexOf(params.modalidad)===-1||params.ver==='orden_de_paso'||params.ver==='lista_equipos'||params.ver==='libro') && params.modalidad){
      let newParams = {...params, modalidad:modalidades[0]}
      setParams(newParams)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, competence])// Aqui estaba filtros como parametro

  useEffect(() => {
    document.title = `${consursos[params.concurso]}-${params.concurso==='II'||params.concurso==='IV'?'':params.categoria+'-'}${params.modalidad}`;
    let interval = setInterval(() => {
      if(online && competence && competence.activa && params.ver==='resultados'){
        reload(params);
      }
    }, 8000);
    return ()=> clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [online, competence, params])

  useEffect(() => {
    if(params.categoria)reload(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  const reload= params=>{
    if(organizacion===state.URLdomain)sendServer({
      domain: organizacion,
      app: modalidad,
      action:params.ver,
      competencia_id: competencia_id,
      params:params
    })
  }

  const onChange = e=>{
    let newParams = {...params,[e.target.name]:e.target.value};
    setParams(newParams)
    let newUrl = '?'
    for (let f in newParams) {
      if(newParams[f]!==''){
        newUrl+=`${newUrl==='?'?'':'&'}${f}=${newParams[f]}`
      }
    }
    navigate(newUrl);
  }
  if(!competence||organizacion!==state.URLdomain)return null;
  return (
    <>
      <Filters params={params} onChange={onChange}/>
      <div className='tableContainer' children={<>
        {/* eslint-disable-next-line array-callback-return */}
        {query?query.map((q,index)=>{
          if(modalidad==='Artistica' && (q.modalidad==='orden_de_paso'||q.modalidad==='lista_de_equipos')){
            return <div className='tablePadding' key={`${q.modalidad}${index}`} children={<TableOrdArt query={q.list} modalidad={q.modalidad} params={params}/>}/>
          }else if(q.modalidad==='portada' && competence.portada){
            return <div  key={`${q.modalidad}${index}`}
              className='portada'
              src={`https://consugi.consugisoft.com/media/${competence.portada}`}
              style={{
                backgroundImage:`url('https://consugi.consugisoft.com/media/${competence.portada}')`,
              }}
            />
          }else if(q.modalidad!=='portada'){
            return <div className='tablePadding' key={`${q.modalidad}${index}`} children={<Table query={q.list} modalidad={q.modalidad} params={params} titulo={q.titulo}/>}/>
          }
        }):null}
      </>}/>
    </>
  )
}
