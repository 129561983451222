import React, { useCallback, useContext, useEffect, useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { useParams } from "react-router-dom";
import globalContext from "../../../context/globalContext";
import { promedioAER, promedio } from "../Functions";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import "../../../App.css";
import styles from "../styles/judgment.module.css";

const lineone = [
  { name: "deduccion_1", show: "D1" },
  { name: "deduccion_2", show: "D2" },
  { name: "deduccion_3", show: "D3" },
];
const linetwo = [
  { name: "ejecución_1", show: "E1" },
  { name: "ejecución_2", show: "E2" },
  { name: "ejecución_3", show: "E3" },
];
const linethree = [{ name: "tiempo_1", show: "TIEMPO" }];
const linefour = [
  { name: "skill_1", show: "Skill 1" },
  { name: "skill_2", show: "Skill 2" },
  { name: "skill_3", show: "Skill 3" },
  { name: "skill_4", show: "Skill 4" },
  { name: "skill_5", show: "Skill 5" },
];
const linefive = [{}];

const caracteres = ["1", "2", "3", "4", "5", "6", "7", "8", "9", ".", "0"];

export default function CjpParkour() {
  const initialNotas = {
    deduccion_final: 0,
    ejecución_final: 0,
    skill_final: 0,
    tiempo_final: 0,
    total: 0,
  };
  const [notas, setnotas] = useState(initialNotas);
  const [puesto, setpuesto] = useState("");
  const [inscripcion, setInscripcion] = useState(null);
  const [del, setdel] = useState(false);
  const [showDNSModal, setShowDNSModal] = useState(false);
  const [showDSQModal, setShowDSQModal] = useState(false);
  const { state, sendServer, URLdomain, sendState } = useContext(globalContext);
  const { online, competence, current, success, orden_de_paso, authorized } =
    state;
  const {
    id,
    deduccion_1,
    deduccion_2,
    deduccion_3,
    ejecución_1,
    ejecución_2,
    ejecución_3,
    skill_1,
    skill_2,
    skill_3,
    skill_4,
    skill_5,
    tiempo_1,
    deducciones_CJP,
    deducciones_dificultad_total,
    division_d,
    categoria_de_participacion,
  } = current || {};
  let { competencia_id, modalidad } = useParams();
  const [control, setcontrol] = useState({ anterior: null, siguiente: null });
  const navigate = useNavigate();

  let { organizacion, panel } = useParams();

  useEffect(() => {
    if (organizacion !== state.URLdomain) {
      sendState({ URLdomain: organizacion });
    }
    sendServer({
      domain: organizacion,
      app: "global",
      action: "getAsignations",
      id: competencia_id,
    });
  }, [organizacion, state.URLdomain]);

  useEffect(() => {
    if (authorized === false) {
      navigate(`/${organizacion}/${modalidad}/${competencia_id}`);
    } else if (authorized === undefined) {
      sendServer({
        domain: organizacion,
        app: modalidad,
        action: "authorized",
        puesto: "cjp",
        panel: panel,
        competencia_id: competencia_id,
      });
    }
  }, [authorized, state.URLdomain]);

  const [orden_de_paso_local, setorden_de_paso_local] = useState([]);
  const initialFilter = { modalidad: "", edad: "", gimnasta: "", concurso: "" };
  const [filter, setfilter] = useState(initialFilter);
  const [edades, setedades] = useState([]);

  useEffect(() => {
    if (!edades.length && orden_de_paso) {
      let newEdades = [];
      orden_de_paso.map((o) => {
        if (!newEdades.includes(o.categoria_edad))
          newEdades.push(o.categoria_edad);
      });
      setedades(newEdades);
    }
    if (orden_de_paso) {
      setorden_de_paso_local(
        orden_de_paso.filter(
          (o) =>
            (filter.modalidad === "" ||
              filter.modalidad === o.categoria_de_participacion) &&
            (filter.edad === "" || filter.edad === o.categoria_edad) &&
            (filter.concurso === "" ||
              (filter.concurso === "final" && o.final) ||
              (filter.concurso === "clasificación" && !o.final)) &&
            (filter.gimnasta === "" ||
              o.participante
                .toLowerCase()
                .includes(filter.gimnasta.toLowerCase()))
        )
      );
    }
  }, [orden_de_paso, filter]);

  useEffect(() => {
    let urlParams = new URLSearchParams(window.location.search);
    let newFilter = filter;
    for (let f in filter) {
      if (urlParams.get(f)) {
        newFilter = { ...newFilter, [f]: urlParams.get(f) };
      }
    }
    setfilter(newFilter);
    sendServer({
      domain: organizacion,
      app: "global",
      action: "getAsignations",
      id: competencia_id,
    });
  }, []);

  useEffect(() => {
    if (orden_de_paso_local) {
      let anterior, actual, siguiente;
      orden_de_paso_local.map((gimnasta) => {
        if (id === gimnasta.id) {
          actual = gimnasta.id;
        } else if (!actual) {
          anterior = gimnasta.id;
        } else if (actual && !siguiente) {
          siguiente = gimnasta.id;
        }
      });
      setcontrol({ anterior: anterior, siguiente: siguiente });
    }
  }, [id, orden_de_paso_local]);

  useEffect(() => {
    if (online && !competence)
      sendServer({
        domain: organizacion,
        app: "global",
        action: "getCompetence",
        id: competencia_id,
      });
  }, [online, competence, competencia_id, state.URLdomain]);

  useEffect(() => {
    sendServer({
      domain: organizacion,
      app: modalidad,
      action: !inscripcion ? "getCurrent" : "getInscripcion",
      id: inscripcion,
      getOrdenDePaso: true,
      competencia_id: competencia_id,
    });
    document.title = `CJP | ${current?.participante || ""}`;
  }, [inscripcion, state.URLdomain]);

  useEffect(() => {
    let interval = setInterval(() => {
      sendServer({
        domain: organizacion,
        app: modalidad,
        action: !inscripcion ? "getCurrent" : "getInscripcion",
        id: inscripcion,
        competencia_id: competencia_id,
      });
    }, 3000);
    return () => clearInterval(interval);
  }, [competence, competencia_id, sendServer]);

  useEffect(() => {
    if (current) {
      if (current.dns) {
        setnotas({
          deduccion_final: 0,
          ejecución_final: 0,
          skill_final: 0,
          tiempo_final: 0,
          total: "DNS",
        });
        return;
      }

      let deduccion_final = promedioAER([
        current.deduccion_1,
        current.deduccion_2,
        current.deduccion_3,
      ]);

      let ejecución_final = 0;
      if (
        current.ejecución_1 !== null ||
        current.ejecución_2 !== null ||
        current.ejecución_3 !== null
      ) {
        ejecución_final = promedioAER([
          current.ejecución_1,
          current.ejecución_2,
          current.ejecución_3,
        ]);
      }

      // Cálculo del skill_final como suma de los 5 skills (enteros)
      let skill_final = 0;
      const isSkillCategory =
        current.categoria_de_participacion?.includes("SKILL_");
      if (isSkillCategory) {
        const skills = [
          current.skill_1 || 0,
          current.skill_2 || 0,
          current.skill_3 || 0,
          current.skill_4 || 0,
          current.skill_5 || 0,
        ].map((s) => Math.round(s)); // Asegurar que son enteros

        skill_final = skills.reduce((sum, s) => sum + s, 0);
      }

      let tiempo_final = 0;
      if (current.tiempo_1) tiempo_final = current.tiempo_1;

      let total = isSkillCategory
        ? skill_final
        : deduccion_final + ejecución_final + tiempo_final;

      [current.deducciones_CJP, current.deducciones_dificultad_total].forEach(
        (l) => {
          if (l) total -= l;
        }
      );
      if (total < 0) total = 0;

      setnotas({
        deduccion_final: deduccion_final,
        ejecución_final: ejecución_final,
        skill_final: skill_final,
        tiempo_final: parseFloat(tiempo_final.toFixed(3)),
        total: parseFloat(total.toFixed(3)),
      });
    }
  }, [
    id,
    deduccion_1,
    deduccion_2,
    deduccion_3,
    ejecución_1,
    ejecución_2,
    ejecución_3,
    skill_1,
    skill_2,
    skill_3,
    skill_4,
    skill_5,
    tiempo_1,
    deducciones_CJP,
    deducciones_dificultad_total,
    division_d,
    current?.categoria_de_participacion,
  ]);

  useEffect(() => {
    if (success) {
      setpuesto("");
      setUserText("");
      setdel(false);
      sendState({ success: false });
    }
  }, [success]);

  const [userText, setUserText] = useState("");
  const handleUserKeyPress = useCallback(
    (event) => {
      const { key, keyCode } = event;
      const isSkillField = puesto.startsWith("skill_");

      if (caracteres.includes(key)) {
        setUserText((prevUserText) => {
          // No permitir puntos decimales en campos de skill
          if (isSkillField && key === ".") return prevUserText;

          // Limitar la longitud del texto (2 dígitos para skills, 6 para otros)
          if (prevUserText.length >= (isSkillField ? 2 : 6))
            return prevUserText;

          // Validar rango para campos de skill (0-10)
          if (isSkillField) {
            const newValue = parseInt(`${prevUserText}${key}` || "0");
            if (newValue > 10) return "10"; // Valor máximo 10
          }

          return `${prevUserText}${key}`;
        });
      } else if (keyCode === 8) {
        setUserText((prevUserText) =>
          prevUserText.substr(0, prevUserText.length - 1)
        );
      } else if (keyCode === 46) {
        if (puesto !== "") {
          setUserText(``);
        } else {
          publicar(false);
        }
      } else if ((keyCode === 188 || keyCode === 190) && !isSkillField) {
        setUserText((prevUserText) => {
          if (prevUserText.match(/[.]/)) return prevUserText;
          return `${prevUserText}.`;
        });
      } else if (keyCode === 13) {
        if (puesto !== "") {
          setUserText((prevUserText) => {
            cargarNota(prevUserText);
            return `${prevUserText}`;
          });
        } else {
          publicar(true);
        }
      } else if (keyCode === 37 && control.anterior) {
        cambiarGimnasta(control.anterior);
      } else if (keyCode === 39 && control.siguiente) {
        cambiarGimnasta(control.siguiente);
      }
    },
    [current, id, puesto, control.anterior, control.siguiente]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  const cargarNota = (nota) => {
    if (nota === "") nota = null;
    else if (typeof nota === "string") {
      // Para campos de skill, convertimos a entero
      if (puesto.startsWith("skill_")) {
        nota = Math.round(parseFloat(nota));
      } else {
        nota = parseFloat(nota).toFixed(3);
      }
    }

    sendServer({
      domain: organizacion,
      app: modalidad,
      action: "cargarNota",
      puesto: "cjp",
      panel: panel,
      competencia_id: competencia_id,
      data: {
        id: current.id,
        competencia_id: current.competencia,
        [puesto]: nota,
        publicado: false,
      },
    });
  };

  const publicar = (publicado) => {
    sendServer({
      domain: organizacion,
      app: modalidad,
      action: "cargarNota",
      puesto: "cjp",
      panel: panel,
      getOrdenDePaso: true,
      competencia_id: competencia_id,
      data: {
        ...notas,
        id: current.id,
        competencia_id: current.competencia,
        dns: false,
        publicado: publicado,
      },
    });
  };

  const ButtonDNS = () => {
    sendServer({
      domain: organizacion,
      app: modalidad,
      action: "cargarNota",
      puesto: "cjp",
      panel: panel,
      getOrdenDePaso: true,
      competencia_id: competencia_id,
      data: {
        id: current.id,
        competencia_id: current.competencia,
        deduccion_1: null,
        deduccion_2: null,
        deduccion_3: null,
        deduccion_final: null,
        ejecución_1: null,
        ejecución_2: null,
        ejecución_3: null,
        ejecución_final: null,
        skill_1: null,
        skill_2: null,
        skill_3: null,
        skill_4: null,
        skill_5: null,
        skill_final: null,
        tiempo_1: null,
        tiempo_final: null,
        deducciones_CJP: null,
        deducciones_dificultad_total: null,
        total: null,
        dns: true,
        dsq: false,
        publicado: true,
      },
    });
  };

  const ButtonDSQ = () => {
    sendServer({
      domain: organizacion,
      app: modalidad,
      action: "cargarNota",
      puesto: "cjp",
      panel: panel,
      getOrdenDePaso: true,
      competencia_id: competencia_id,
      data: {
        id: current.id,
        competencia_id: current.competencia,
        deduccion_1: null,
        deduccion_2: null,
        deduccion_3: null,
        deduccion_final: null,
        ejecución_1: null,
        ejecución_2: null,
        ejecución_3: null,
        ejecución_final: null,
        skill_1: null,
        skill_2: null,
        skill_3: null,
        skill_4: null,
        skill_5: null,
        skill_final: null,
        tiempo_1: null,
        tiempo_final: null,
        deducciones_CJP: null,
        deducciones_dificultad_total: null,
        total: null,
        dsq: true,
        dns: false,
        publicado: true,
      },
    });
  };

  const clearInscription = () => {
    sendServer({
      domain: organizacion,
      app: modalidad,
      action: "cargarNota",
      puesto: "cjp",
      panel: panel,
      getOrdenDePaso: true,
      competencia_id: competencia_id,
      data: {
        id: current.id,
        publicado: false,
        deduccion_1: null,
        deduccion_2: null,
        deduccion_3: null,
        deduccion_final: null,
        ejecución_1: null,
        ejecución_2: null,
        ejecución_3: null,
        ejecución_final: null,
        skill_1: null,
        skill_2: null,
        skill_3: null,
        skill_4: null,
        skill_5: null,
        skill_final: null,
        tiempo_1: null,
        tiempo_final: null,
        deducciones_CJP: null,
        deducciones_dificultad_total: null,
        total: null,
        dns: false,
        dsq: false,
      },
    });
  };

  const loadtype = (puesto) => {
    setpuesto(puesto);
    if (current[puesto] !== null && current[puesto] !== undefined) {
      setUserText(current[puesto].toString());
    } else {
      setUserText("");
    }
  };

  const cambiarGimnasta = (id) => {
    if (inscripcion) setInscripcion(null);
    if (id)
      sendServer({
        domain: organizacion,
        app: modalidad,
        action: "cambiarGimnasta",
        puesto: "cjp",
        panel: panel,
        getOrdenDePaso: true,
        competencia_id: competencia_id,
        id: id,
      });
  };

  const onChange = (e) => {
    let newFilter = { ...filter, [e.target.name]: e.target.value };
    if (e.target.value === "Limpiar") {
      newFilter = initialFilter;
    }
    setfilter(newFilter);
    let newUrl = "?";
    for (let f in newFilter) {
      if (newFilter[f] !== "") {
        newUrl += `${newUrl === "?" ? "" : "&"}${f}=${newFilter[f]}`;
      }
    }
    navigate(newUrl);
  };

  const formatTotal = (total, dsq) => {
    if (total === null || total === undefined) return "";
    if (dsq) return "DSQ";
    if (total === "DNS") return "DNS";
    const number = parseFloat(total);
    if (!isNaN(number)) return number.toFixed(2);
    return total.toString();
  };

  if (!current || !authorized) return null;

  const isSkillCategory =
    current.categoria_de_participacion?.includes("SKILL_");
  const isFreestyleCategory =
    current.categoria_de_participacion?.includes("FREESTYLE_");
  const isSpeedrunCategory =
    current.categoria_de_participacion?.includes("SPEEDRUN_");

  return (
    <>
      <p
        className="noPrint"
        children={
          <>
            {current.id ? (
              <>
                <img
                  className="mini"
                  src={`https://consugi.consugisoft.com/media/${current.representacion__bandera}`}
                  alt="Bandera"
                />
                <span
                  onClick={() => loadtype("division_d")}
                  children={`(${current.representacion}) ${current.categoria_de_participacion}(D/${current.division_d}) - ${current.categoria_edad} - ${current.participante}`}
                />
              </>
            ) : null}
          </>
        }
      />

      {isFreestyleCategory && (
        <>
          <Row
            className="marginAuto noPrint"
            children={
              <>
                {lineone.map((l) => (
                  <Col
                    onClick={() => loadtype(l.name)}
                    key={l.name}
                    className={`${l.red ? "red" : "blue"} enter`}
                    children={`${l.show}\n${
                      current[l.name] != null ? current[l.name].toFixed(2) : ""
                    }`}
                  />
                ))}
                <Col
                  className={`totales ${
                    current.deduccion_final === notas.deduccion_final
                      ? styles.success
                      : styles.warning
                  }`}
                  children={`AT\n${notas.deduccion_final.toFixed(3)}`}
                />
              </>
            }
          />

          <Row
            className="marginAuto noPrint"
            children={
              <>
                {linetwo.map((l) => (
                  <>
                    <Col
                      onClick={() => loadtype(l.name)}
                      key={l.name}
                      className={`${l.red ? "red" : "blue"} enter`}
                      children={`${l.show}\n${
                        current[l.name] != null
                          ? current[l.name].toFixed(2)
                          : ""
                      }`}
                    />
                  </>
                ))}
                <Col
                  onClick={() => loadtype("deducciones_CJP")}
                  className="enter red"
                  children={`cjp\n${
                    current.deducciones_CJP != null
                      ? current.deducciones_CJP.toFixed(2)
                      : ""
                  }`}
                />
                <Col
                  className={`totales ${
                    current.ejecución_final === notas.ejecución_final
                      ? styles.success
                      : styles.warning
                  }`}
                  children={`ET\n${notas.ejecución_final.toFixed(3)}`}
                />
              </>
            }
          />
        </>
      )}

      {isSkillCategory && (
        <Row
          className="marginAuto noPrint"
          children={
            <>
              {linefour.map((l) => (
                <Col
                  onClick={() => loadtype(l.name)}
                  key={l.name}
                  className={`blue enter`}
                  children={`${l.show}\n${
                    current[l.name] != null ? current[l.name] : ""
                  }`}
                />
              ))}
              <Col
                className={`totales ${
                  current.skill_final === notas.skill_final
                    ? styles.success
                    : styles.warning
                }`}
                children={`ST\n${notas.skill_final}`}
              />
            </>
          }
        />
      )}

      {isSpeedrunCategory && (
        <Row
          className="marginAuto noPrint"
          children={
            <>
              {linethree.map((l) => (
                <Col
                  onClick={() => loadtype(l.name)}
                  key={l.name}
                  className={`${l.red ? "red" : "blue"} enter`}
                  children={`${l.show}\n${
                    current[l.name] != null ? current[l.name].toFixed(2) : ""
                  }`}
                />
              ))}
              <Col
                onClick={() => loadtype("deducciones_CJP")}
                className="enter red"
                children={`cjp\n${
                  current.deducciones_CJP != null
                    ? current.deducciones_CJP.toFixed(2)
                    : ""
                }`}
              />
              <Col
                className={`totales ${
                  current.total === notas.total
                    ? styles.success
                    : styles.warning
                }`}
                children={`T\n${
                  current.dns ? "DNS" : formatTotal(notas.total)
                }`}
              />
            </>
          }
        />
      )}

      <Row
        className="controles marginAuto noPrint mt10"
        children={
          <>
            <Col
              onClick={() => cambiarGimnasta(control.anterior)}
              className={`${styles["btn-div"]} ${
                control.anterior
                  ? styles["btn-primary"]
                  : styles["btn-disabled"]
              }`}
              children={"◄"}
            />
            <Col
              onClick={() => cambiarGimnasta(control.siguiente)}
              className={`${styles["btn-div"]} ${
                control.siguiente
                  ? styles["btn-primary"]
                  : styles["btn-disabled"]
              }`}
              children={"►"}
            />
            <Col
              onClick={() => setdel(true)}
              className={styles["btn-div"] + " " + styles["btn-danger"]}
              children={"Delete"}
            />
            <Col
              onClick={() => setShowDNSModal(true)}
              className={styles["btn-div"] + " " + styles["btn-dnf"]}
              children={"DNS"}
            />
            <Col
              onClick={() => setShowDSQModal(true)}
              className={styles["btn-div"] + " " + styles["btn-dnf"]}
              children={"DSQ"}
            />
            <Col
              onClick={current.publicado ? () => publicar(false) : () => {}}
              className={`${styles["btn-div"]} ${
                current.publicado
                  ? styles["btn-warning"]
                  : styles["btn-disabled"]
              }`}
              children={"Desp"}
            />
            <Col
              onClick={!current.publicado ? () => publicar(true) : () => {}}
              className={`${styles["btn-div"]} ${
                current.publicado ? styles["btn-disabled"] : styles["success"]
              }`}
              children={"Enviar"}
            />
          </>
        }
      />
      <div
        className="tableContainer"
        children={
          <table
            className="mt30 w100 table table-striped"
            children={
              <>
                <thead
                  children={
                    <>
                      <tr
                        children={
                          <th
                            colSpan={40}
                            className="text-center"
                            children={
                              <>
                                ORDEN DE PASO
                                <Row
                                  className="controles marginAuto noPrint mt30"
                                  children={
                                    <>
                                      <Col
                                        xs={6}
                                        sm={4}
                                        md={3}
                                        lg={2}
                                        className="mt10"
                                        children={
                                          <>
                                            <label children={"_"} />
                                            <input
                                              className="w100"
                                              type="button"
                                              value="Limpiar"
                                              onClick={onChange}
                                            />
                                          </>
                                        }
                                      />
                                      <Col
                                        xs={6}
                                        sm={4}
                                        md={3}
                                        lg={2}
                                        className="mt10"
                                        children={
                                          <>
                                            <label
                                              children={"Final/Clasificación"}
                                            />
                                            <select
                                              className="w100"
                                              name="concurso"
                                              value={filter.concurso}
                                              onChange={onChange}
                                              children={
                                                <>
                                                  <option></option>
                                                  <option value="clasificación">
                                                    Clasificación
                                                  </option>
                                                  <option value="final">
                                                    Final
                                                  </option>
                                                </>
                                              }
                                            />
                                          </>
                                        }
                                      />
                                      <Col
                                        xs={6}
                                        sm={4}
                                        md={3}
                                        lg={2}
                                        className="mt10"
                                        children={
                                          <>
                                            <label children={"Modalidad"} />
                                            <select
                                              className="w100"
                                              name="modalidad"
                                              value={filter.modalidad}
                                              onChange={onChange}
                                              children={
                                                <>
                                                  <option></option>
                                                  <option value="Speedrun_(M)">
                                                    Men Speed
                                                  </option>
                                                  <option value="Speedrun_(W)">
                                                    Women Speed
                                                  </option>
                                                  <option value="Freestyle_(M)">
                                                    Men Freestyle
                                                  </option>
                                                  <option value="Freestyle_(W)">
                                                    Women Freestyle
                                                  </option>
                                                  <option value="Skill_(M)">
                                                    Men Skill
                                                  </option>
                                                  <option value="Skill_(W)">
                                                    Women Skill
                                                  </option>
                                                </>
                                              }
                                            />
                                          </>
                                        }
                                      />
                                      <Col
                                        xs={6}
                                        sm={4}
                                        md={3}
                                        lg={2}
                                        className="mt10"
                                        children={
                                          <>
                                            <label children={"Edad"} />
                                            <select
                                              className="w100"
                                              name="edad"
                                              value={filter.edad}
                                              onChange={onChange}
                                              children={
                                                <>
                                                  <option></option>
                                                  {edades.map((e) => (
                                                    <option key={e} value={e}>
                                                      {e}
                                                    </option>
                                                  ))}
                                                </>
                                              }
                                            />
                                          </>
                                        }
                                      />
                                      <Col
                                        xs={6}
                                        sm={4}
                                        md={3}
                                        lg={2}
                                        className="mt10"
                                        children={
                                          <>
                                            <label children={"Gimnasta"} />
                                            <input
                                              className="w100"
                                              type="text"
                                              name="gimnasta"
                                              value={filter.gimnasta}
                                              onChange={onChange}
                                            />
                                          </>
                                        }
                                      />
                                    </>
                                  }
                                />
                              </>
                            }
                          />
                        }
                      />
                      <tr
                        children={
                          <>
                            <th children={"#"} />
                            <th children={"Gimnastas"} />
                            <th children={"Rep."} />
                            <th children={"Mod."} />
                            <th children={"Edad"} />
                            <th children={"Total"} />
                          </>
                        }
                      />
                    </>
                  }
                />
                <tbody>
                  {orden_de_paso_local?.map((i) => {
                    const rowClasses = [
                      styles.tableRow,
                      i.puntuando && styles["btn-primary"],
                      i.id === id && styles["btn-warning"],
                    ]
                      .filter(Boolean)
                      .join(" ");

                    return (
                      <tr key={i.id} className={rowClasses}>
                        <td
                          onClick={() => setInscripcion(i.id)}
                          className={styles.textEnd}
                        >
                          {i.orden_de_salida}
                        </td>
                        <td onClick={() => cambiarGimnasta(i.id)}>
                          {i.participante}
                        </td>
                        <td>{i.representacion}</td>
                        <td>{i.categoria_de_participacion}</td>
                        <td>{i.categoria_edad}</td>
                        <td
                          className={`${styles.textEnd} ${
                            i.publicado ? styles.success : ""
                          }`}
                        >
                          {formatTotal(i.total, i.dsq)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </>
            }
          />
        }
      />

      <Modal
        show={Boolean(puesto)}
        fullscreen={true}
        onHide={() => setpuesto("")}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {current ? (
              <p
                children={
                  <>
                    <img
                      className="mini"
                      src={`https://consugi.consugisoft.com/media/${current.representacion__bandera}`}
                      alt="Bandera"
                    />
                    <span
                      children={`(${current.representacion}) ${current.categoria_de_participacion} - ${current.categoria_edad} - ${current.participante}`}
                    />
                  </>
                }
              />
            ) : null}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row marginAuto">
            <div
              className="actionsContainer"
              children={
                <>
                  <div
                    className="text-center userTextCont"
                    children={<b className="userText" children={userText} />}
                  />
                  <div
                    className="text-center puesto"
                    children={puesto.replace("_", " ").replace("_total", " ")}
                  />
                  <div
                    className="btn-group col-12"
                    role="group"
                    aria-label="Basic mixed styles example"
                  >
                    {userText === "" ? (
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => setpuesto("")}
                      >
                        Cancel
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => setUserText("")}
                      >
                        Del
                      </button>
                    )}
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={() => cargarNota(userText)}
                    >
                      Enviar
                    </button>
                  </div>
                </>
              }
            />
            <div
              className="tecladoContainer"
              children={
                <>
                  {caracteres
                    .filter((c) => !(puesto.startsWith("skill_") && c === "."))
                    .map((n) => (
                      <button
                        type="button"
                        key={n}
                        onClick={() => handleUserKeyPress({ key: n })}
                        className="btn btn-primary"
                        children={<span children={n} />}
                      />
                    ))}
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() =>
                      setUserText(userText.substr(0, userText.length - 1))
                    }
                    children={<span children={"◄"} />}
                  />
                </>
              }
            />
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={del} onHide={() => setdel(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar Notas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {current ? (
            <p
              children={
                <>
                  <img
                    className="mini"
                    src={`https://consugi.consugisoft.com/media/${current.representacion__bandera}`}
                    alt="Bandera"
                  />
                  <span
                    children={`(${current.representacion}) ${current.categoria_de_participacion} - ${current.categoria_edad} - ${current.participante}`}
                  />
                </>
              }
            />
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setdel(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={() => clearInscription()}>
            Eliminar notas
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDNSModal} onHide={() => setShowDNSModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Marcar como DNS</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {current ? (
            <p
              children={
                <>
                  <img
                    className="mini"
                    src={`https://consugi.consugisoft.com/media/${current.representacion__bandera}`}
                    alt="Bandera"
                  />
                  <span
                    children={`(${current.representacion}) ${current.categoria_de_participacion} - ${current.categoria_edad} - ${current.participante}`}
                  />
                </>
              }
            />
          ) : null}
          <p>
            ¿Estás seguro de que deseas marcar a este gimnasta como{" "}
            <strong>DNS</strong>?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDNSModal(false)}>
            Cancelar
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              ButtonDNS();
              setShowDNSModal(false);
            }}
          >
            Marcar como DNS
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDSQModal} onHide={() => setShowDSQModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Marcar como DSQ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {current ? (
            <p
              children={
                <>
                  <img
                    className="mini"
                    src={`https://consugi.consugisoft.com/media/${current.representacion__bandera}`}
                    alt="Bandera"
                  />
                  <span
                    children={`(${current.representacion}) ${current.categoria_de_participacion} - ${current.categoria_edad} - ${current.participante}`}
                  />
                </>
              }
            />
          ) : null}
          <p>
            ¿Estás seguro de que deseas marcar a este gimnasta como{" "}
            <strong>DSQ</strong>?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDSQModal(false)}>
            Cancelar
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              ButtonDSQ();
              setShowDSQModal(false);
            }}
          >
            Marcar como DSQ
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
