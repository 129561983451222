import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import globalContext from '../../../context/globalContext';
import Header from '../components/common/Header';
import ParticipantInfo from './components/ParticipantInfo';
import ScoreDisplay from './components/ScoreDisplay';
import Footer from './components/Footer';
import { getDisciplineType } from './utils/disciplineUtils';
import './styles/individual.css';

const Individual = () => {
  const { state, sendServer, sendState } = useContext(globalContext);
  const { current, competence, online } = state;
  const { competencia_id, modalidad, organizacion } = useParams();
  const [config, setConfig] = useState({
    fondo: '/static/img/FullScreen_Large.png',
    logo_modalidad: null,
    pie_pagina: '/static/img/footer.png',
    activo: false,
  });

  // Configuración inicial
  useEffect(() => {
    document.title = 'Individual';
    sendState({ URLdomain: organizacion });

    // Obtener datos del participante
    sendServer({
      domain: organizacion,
      app: modalidad,
      action: id ? 'getInscripcion' : 'getCurrent',
      competencia_id,
      panel: urlParams.get('panel'),
      id: urlParams.get('id'),
    });

    // Obtener datos de la competencia si no están cargados
    if (!competence) {
      sendServer({
        domain: organizacion,
        app: 'global',
        action: 'getCompetence',
        id: competencia_id,
      });
    }

    // Obtener configuración visual
    sendServer(
      {
        domain: organizacion,
        app: 'global',
        action: 'getFondoPantalla',
        competencia_id,
      },
      (response) => {
        if (response?.fondoPantalla) {
          setConfig(prev => ({
            ...prev,
            fondo: response.fondoPantalla,
            logo_modalidad: response.logoModalidad || prev.logo_modalidad,
            pie_pagina: response.piePagina || prev.pie_pagina,
            activo: true,
          }));
        }
      }
    );

    // Configurar intervalo de actualización
    const interval = setInterval(() => {
      sendServer({
        domain: organizacion,
        app: modalidad,
        action: id ? 'getInscripcion' : 'getCurrent',
        competencia_id,
        panel: urlParams.get('panel'),
        id: urlParams.get('id'),
      });
    }, 8000);

    return () => clearInterval(interval);
  }, [online, competence, organizacion, competencia_id, modalidad]);

  if (!current || !competence) return null;

  const disciplineType = getDisciplineType(current, modalidad);
  const backgroundStyle = {
    backgroundImage: `url('${config.fondo}')`,
    backgroundSize: 'cover'
  };

  return (
    <section 
      className="individual-container" 
      id="full"
      style={backgroundStyle}
      onClick={toggleFullscreen}
    >
      <div className="content-wrapper">
        <Header 
          modality={competence.modalidad} 
          discipline={current.categoria_de_participacion}
          isFinal={current.concurso === 'III' || current.final}
        />
        
        <ParticipantInfo
          participant={current.participante}
          category={current.categoria_edad}
          flag={current.representacion__bandera}
          countryCode={current.representacion__siglas}
        />

        <div className="scores-container">
          <ScoreDisplay 
            current={current} 
            modality={modalidad} 
            disciplineType={disciplineType} 
          />
          
          <div className="total-score">
            {renderTotalScore(current)}
            {disciplineType === 'SPEEDRUN' && current.tiempo_final && (
              <div className="time-display">
                Tiempo: {current.tiempo_final.toFixed(3)}
              </div>
            )}
          </div>
        </div>
      </div>
      
      <Footer image={config.pie_pagina} />
    </section>
  );
};

// Función auxiliar para renderizar el puntaje total
const renderTotalScore = (current) => {
  if (current.dns) return 'DNS';
  if (current.dnf) return 'DNF';
  if (current.dsq) return 'DSQ';
  return current.total !== null && current.publicado 
    ? current.total.toFixed(3) 
    : '';
};

// Función para manejar pantalla completa
const toggleFullscreen = () => {
  if (!document.fullscreenElement) {
    document.getElementById('full').requestFullscreen();
  } else {
    document.exitFullscreen();
  }
};

export default Individual;