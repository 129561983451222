import React, { useContext } from 'react'
import { Routes, Route } from "react-router-dom";
import { Home } from "./home/Home";
import { Competence } from "./competence/Competence";
import Judge from './judgment/Judge';
import ChromaB from './screens/Chroma';
import Chroma from './screens/hercules/Chroma';
import RankingB from './screens/Ranking';
import TeamB from './screens/Team';
import AllB from './screens/All';
import All from './screens/hercules/All';
import Ranking from './screens/hercules/Ranking';
import Team from './screens/hercules/Team';
import Reposo from './screens/hercules/Reposo';
import MedalleriaAA from './screens/hercules/MedalleriaAA';
import IndividualB from './screens/Individual';
import Individual from './screens/hercules/Individual';
import Login from './user/Login';
import Notfound from './Notfound';
import Logout from './user/Logout';
import { Competences } from './competence/Competences';
import { Editor } from './Editor';
import globalContext from '../context/globalContext';

const urls = [
  {path:'/',component:<Home/>},
  {path:'/index',component:<Home/>},
  {path:'/index.htm',component:<Home/>},
  {path:'/index.html',component:<Home/>},
  {path:'/:organizacion/',component:<Competences/>},
  {path:'/:organizacion/login',component:<Login/>},
  {path:'/:organizacion/logout',component:<Logout/>},
  {path:'/:organizacion/:modalidad/:competencia_id',component:<Competence/>},

  {path:'/:organizacion/:modalidad/:competencia_id/judge/:puesto/:panel',component:<Judge/>},
  {path:'/:organizacion/:modalidad/:competencia_id/editor/:panel',component:<Editor/>},
  {path:'/:organizacion/:modalidad/:competencia_id/reposo',component:<Reposo/>},
  {path:'/:organizacion/:modalidad/:competencia_id/MedalleriaAA',component:<MedalleriaAA/>},
  {path:'/:organizacion/:modalidad/:competencia_id/chroma',component:<Chroma/>},
  {path:'/:organizacion/:modalidad/:competencia_id/chromab',component:<ChromaB/>},
  {path:'/:organizacion/:modalidad/:competencia_id/individual',component:<Individual/>},
  {path:'/:organizacion/:modalidad/:competencia_id/individualb',component:<IndividualB/>},
  {path:'/:organizacion/:modalidad/:competencia_id/ranking',component:<Ranking/>},
  {path:'/:organizacion/:modalidad/:competencia_id/rankingb',component:<RankingB/>},
  {path:'/:organizacion/:modalidad/:competencia_id/team',component:<Team/>},
  {path:'/:organizacion/:modalidad/:competencia_id/teamb',component:<TeamB/>},
  {path:'/:organizacion/:modalidad/:competencia_id/all',component:<All/>},
  {path:'/:organizacion/:modalidad/:competencia_id/allb',component:<AllB/>},
  {path:'*',component:<Notfound/>},
];

export default function Rutas() {
  const { URLdomain } = useContext(globalContext);
  if(!URLdomain)return null;
  return (
    <>
      <Routes children={
        <>
          {urls.map((url, index)=>(
            <Route key={index} path={url.path} element={url.component}/>  
          ))}
        </>
      }/>
    </>
  )
}