import React, { useContext, useEffect, useState } from 'react'
import Col from 'react-bootstrap/esm/Col'
import Row from 'react-bootstrap/esm/Row'
import globalContext from '../../context/globalContext'
import { Header } from '../competence/results/Header'
import { useParams } from "react-router-dom";
import { items } from '../competence/results/ItemsHeader'
import ModalJueces from './paneles/ModalJueces'
import ModaPanel from './paneles/ModalPanel'
import { SubTablePanel } from './paneles/SubTablePanel'

export const Paneles = () => {
  const { state, URLdomain, sendServer } = useContext(globalContext);
  const [juez, setJuez] = useState({})
  const [panel, setPanel] = useState({})
  const [localPanels, setLocalPanels] = useState([])
  const [localQuery, setlocalQuery] = useState([])
  const [juecesDict, setJuecesDict] = useState({})
  const { competence, lista_de_jueces, lista_de_paneles } = state;
  const { organizacion, competencia_id } = useParams();
  useEffect(() => {    
    let subQuerys = []
    let isPar = 0;
    if(lista_de_paneles)lista_de_paneles.forEach((p, index) => {
      if(isPar%2===0){
        subQuerys.push([])
      }
      subQuerys[subQuerys.length-1].push(<SubTablePanel panel={p} setPanel={setPanel} juecesDict={juecesDict}/>)
      isPar++;
    });
    setLocalPanels(subQuerys)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lista_de_paneles, juecesDict])
  useEffect(() => {
    sendServer({domain: organizacion, app:'global', action:'getListaDeJueces', competencia_id: competencia_id});
    sendServer({domain: organizacion, app:'global', action:'getPanel', competencia_id: competencia_id});
    sendServer({domain: organizacion, app:'global', action:'getAsignacionesDePanel', competencia_id: competencia_id});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    let newDict = {};
    if(lista_de_jueces){
      setlocalQuery(lista_de_jueces)
      lista_de_jueces.forEach(e => newDict[e.id]=e);
      setJuecesDict(newDict)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lista_de_jueces, lista_de_jueces])
  const clearJuez = () => setJuez({});
  const clearPanel = () => setPanel({});
  return (
    <>
      <table className="centered table newTable" children={
        <>
          <thead className='upper' children={
            <>
              <tr children={
                <th colSpan={40} children={
                  <>
                    <Row children={
                      <>
                        {competence?
                          <>
                            <Col
                              className="encabezado hide_md_down imgHeader"
                              children={
                                <img
                                  src={`https://consugi.consugisoft.com/media/${competence.organizadores}`}
                                  alt='img.org'
                                />
                              }
                            />
                            <Col className="encabezado" xs={12} sm={8}>
                              <p className='text-center m0' children={competence.nombre_de_la_competencia} />
                              <p className='text-center m0' children={
                                <>
                                  <a href={`${competence.ubicacion}`} target="_blank" rel="noreferrer" children={competence.lugar} />
                                  <span> Del {competence.inicio} al {competence.fin}</span>
                                </>
                              } />
                              <p className='text-center m0 bold' children={
                                "LISTA DE JUECES"
                              } />
                            </Col>
                            <Col
                              className="encabezado hide_md_down imgHeader"
                              children={
                                <img
                                  src={`https://consugi.consugisoft.com/media/${competence.logo}`}
                                  alt='img.log'
                                />
                            }/>
                          </>
                          : null}
                      </>
                    } />
                  </>
                } />
              } />
              <tr className='noPrint' children={
                <th colSpan={40} className='text-center' children={<ModalJueces object={juez} clearJuez={clearJuez}/>} />
              }/>
              <Header modalidad={'lista_de_jueces'}/>
            </>
          } />
          <tbody className="resultadosCont" children={
            <>
              {localQuery.map((q, index)=>
                <tr onClick={()=>setJuez(q)} key={`${q.id}${index}`} className={q.class+'  changeOver'} children={
                  <>
                    {items[competence.modalidad]['lista_de_jueces'].map((i, index)=>
                      <td
                        key={`${q.id}${i}${index}`}
                        children={i.format&&q[i.item]?i.format(q[i.item]):q[i.item]}
                        className={i.clases}
                      />
                    )}
                  </>
                }/>
              )}
            </>
          }/>
        </>
      }/>
      
      <table className="centered table newTable" children={
        <>
          <thead className='upper' children={
            <>
              <tr children={
                <th colSpan={40} children={
                  <>
                    <Row children={
                      <>
                        {competence?
                          <>
                            <Col
                              className="encabezado hide_md_down imgHeader"
                              children={
                                <img
                                  src={`https://consugi.consugisoft.com/media/${competence.organizadores}`}
                                  alt='img.org'
                                />
                              }
                            />
                            <Col className="encabezado" xs={12} sm={8}>
                              <p className='text-center m0' children={competence.nombre_de_la_competencia} />
                              <p className='text-center m0' children={
                                <>
                                  <a href={`${competence.ubicacion}`} target="_blank" rel="noreferrer" children={competence.lugar} />
                                  <span> Del {competence.inicio} al {competence.fin}</span>
                                </>
                              } />
                              <p className='text-center m0 bold' children={
                                "LISTA DE PANELES"
                              } />
                            </Col>
                            <Col
                              className="encabezado hide_md_down imgHeader"
                              children={
                                <img
                                  src={`https://consugi.consugisoft.com/media/${competence.logo}`}
                                  alt='img.log'
                                />
                            }/>
                          </>
                          : null}
                      </>
                    } />
                  </>
                } />
              } />
              <tr className='noPrint' children={
                <th colSpan={40} className='text-center' children={<ModaPanel object={panel} clearPanel={clearPanel}/>} />
              }/>
            </>
          } />
          <tbody className="resultadosCont" children={
            <>
              {localPanels?
                localPanels.map(p=><tr children={<td className='subTableCont' children={p}/>}/>)
              :
                ''
              }
            </>
          }/>
        </>
      }/>
    </>
  )
}