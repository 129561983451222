import React, { useContext, useEffect, useState } from 'react'
import globalContext from '../../../context/globalContext'
import { useParams } from 'react-router-dom'
import { convenciones } from "../Convenciones";

export default function Team() {
  const URLdomain = 'https://consugi.consugisoft.com'
  const {state, sendServer} = useContext(globalContext)
  const {current, competence, online, ranking} = state;
  let { competencia_id, modalidad, organizacion} = useParams();
  const [params, setParams] = useState({chroma:'black'})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  let urlParams = new URLSearchParams(window.location.search);
  useEffect(() => {
    if(urlParams.get('chroma')){
      setParams({chroma:urlParams.get('chroma')});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(ranking){
        let newRanking = []
        let representacion = '';

        ranking.forEach(e => {
            if(modalidad==='Artistica' && representacion!==e.equipo){
              newRanking.push({
                ...e,
                representacion:e.equipo,
              });
              representacion=e.equipo;
            }else if(modalidad!=='Artistica' && representacion!==e.representacion){
              newRanking.push(e)
              representacion=e.representacion;
            };
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ranking])
  
  useEffect(() => {
    if(competence){
      sendServer({
        domain: organizacion,
        app: modalidad,
        action:'getTeam',
        competencia_id: competencia_id,
        subdivision: 1,
        categoria: urlParams.get('categoria'),
        niveles_usag: competence.niveles_usag,
        })
      document.title = `Team`;
      try {
        document.getElementById("full").requestFullscreen()
      } catch (e) {}
    }else{
      sendServer({domain: organizacion, app:'global', action:'getCompetence', id:competencia_id});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competence, URLdomain])
  
  useEffect(() => {
    let interval = setInterval(() => {// && params.ver==='resultados'
      if(competence && competence.activa){
        sendServer({
          domain: organizacion,
          app: modalidad,
          action:'getTeam',
          competencia_id: competencia_id,
          subdivision: 1,
          categoria: urlParams.get('categoria'),
          niveles_usag: competence.niveles_usag,
        })
      }
    }, 8000);
    return ()=> clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [online, competence])
  // if(!current.id||!competence)return null;
  if(!competence)return null;
  return (
    <div className={`h100 ${params.chroma}`} id="full" onClick={() => { !document.fullscreenElement ? document.getElementById("full").requestFullscreen() : document.exitFullscreen() }} >
      <link rel="stylesheet" href="/static/css/fullScreen.css"/>
      <link href="/static/css/estilos.css" rel="stylesheet"/>
        <div className="seccion-datos">
          <div className="cabecera-titulo">
            {/* <img src={`/static/img/${competence.modalidad}.png`} alt='logo'/> */}
              <h1>{competence.modalidad.toUpperCase()}</h1>
              <h2>RESULTS - FINAL - TEAM</h2>
          </div>
          <div className="datos">
            <div className="col-md-9 bottomBlock">
              <div className="marginAuto bordergreen gradient row">
                <div className="text-truncate marquee col-11"><span className="marqueeChild">
                  {`
                    ${competence.nombre_de_la_competencia} -
                    ${urlParams.get('categoria')}
                  `}
                </span></div>
                <div style={{backgroundImage:`url('${URLdomain}/static/recursos/Aerobica/team.png')`}}
                  className="modalidadChroma col-1"></div>
              </div>

              <div className="marginAuto bordergreen gradient row fila-dato">
                <div className="text-truncate text-center col-2"></div>
                <div className="text-truncate col"></div>
                <div className="text-truncate text-end col-3 text-yellow">RESULT</div>
              </div>

              <div className='scrollRank'>
                {ranking ?
                  ranking.map((r, index) =>
                    <div className="marginAuto bordergreen gradient row fila-dato" id={index + 1} key={index + 1}>
                      <div className="text-truncate text-center col-1">{index + 1}</div>
                      <div style={{ backgroundImage: `url('https://consugi.consugisoft.com/media/${r[convenciones[modalidad]['bandera']]}')` }}
                        className="representacionChroma col-1"></div>
                      <div className="text-truncate col">{r[convenciones[modalidad]['representacion']]}</div>
                      <div className="text-truncate text-end col-3">{r[convenciones[modalidad]['total_team']]?r[convenciones[modalidad]['total_team']].toFixed(3):''}</div>
                    </div>
                  )
                : null}
              </div>
            </div>
            <div className="col-md-3 bottomBlock">
              <img src={`https://consugi.consugisoft.com/media/${competence.logo}`} style={{width: '16vw'}} alt='logo'/>
            </div>
          </div>
        </div>
    </div>
  )
}