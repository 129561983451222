const proto = window.location.protocol;
const options = { weekday: 'short', month: 'short', day: 'numeric' };
const formatDateWhitDay=(str)=> new Date(str).toLocaleDateString('es-CO', options);
const formatTime=(str)=> {
  if(str.slice(11, 16)==='00:00') return '';
  return str.slice(11, 16)
};
const formatNoSeg=(str)=> {
  if(str.slice(0, 5)==='00:00') return '';
  return str.slice(0, 5)
}
const formatBandera = (str)=> {
  return <div class="miniBandera" style={{backgroundImage:`url('${proto}//colombia.consugisoft.com/media/${str}')`}}/>
}
const formatBase10 = (value)=>{
  if(typeof value === "string")return value;
  if(value){
    return (10-value).toFixed(1)
  }else{
    return ''
  }
}
const formatFixed3 = value =>{
  if(typeof value === "string")return value;
  return value.toFixed(3);
}
const formatFixed1 = value =>{
  if(typeof value === "string")return value;
  return value.toFixed(1);
}
const generic = [
  { item: 'puesto', show: 'Rank.', clases: 'text-end' },
  { item: 'numero_gimnasta', show: '#', clases: 'text-end' },
  { item: 'participante', show: 'Gimnastas', clases: 'text-start' },
  { item: 'q', show: 'Q', clases: 'text-end' },
  { item: 'equipo__bandera', show: '', clases: 'text-end' },
  { item: 'equipo', show: 'Equipo', clases: 'text-start hide_md_down' },
  { item: 'dificultad', show: 'D', toFixed: 3, clases: 'text-end hide_md_down' },
  { item: 'ejecución_final', show: 'E', toFixed: 3, clases: 'text-end hide_md_down' },
  { item: 'neutrales', show: 'N', toFixed: 1, clases: 'text-end text-danger hide_md_down' },
  { item: 'nota_final', show: 'Total', toFixed: 3, clases: 'text-end' },
]
const allF = [
  { item: 'puestoAll', show: 'Rank.', clases: 'text-end ht12' },
  { item: 'numero_gimnasta', show: '#', clases: 'text-end ht12' },
  { item: 'participante', show: 'Gimnastas', clases: 'text-start ht12 borderL expArt' },
  { item: 'SALTO', show: <div className="saltoPng modalidad"/>, clases: 'text-start hide_md_down ht12 w80 borderL'},
  { item: 'ASIMETRICAS', show:  <div className="asimetricasPng modalidad"/>, clases: 'text-start hide_md_down ht12 w80 borderL' },
  { item: 'VIGA', show:  <div className="vigaPng modalidad"/>, clases: 'text-start hide_md_down ht12 w80 borderL' },
  { item: 'SUELO', show:  <div className="sueloPng modalidad"/>, clases: 'text-start hide_md_down ht12 w80 borderL' },
  { item: 'total_general', show: 'Total', toFixed: 3, clases: 'text-end w80' },
]
const allM = [
  { item: 'puestoAll', show: 'Rank.', clases: 'text-end ht12' },
  { item: 'numero_gimnasta', show: '#', clases: 'text-end ht12' },
  { item: 'participante', show: 'Gimnastas', clases: 'text-start ht12 borderL expArt' },
  { item: 'SUELO', show:  <div className="sueloPng modalidad"/>, clases: 'text-start hide_md_down ht12 w80 borderL' },
  { item: 'ARZONES', show:  <div className="arzonesPng modalidad"/>, clases: 'text-start hide_md_down ht12 w80 borderL' },
  { item: 'ANILLAS', show:  <div className="anillasPng modalidad"/>, clases: 'text-start hide_md_down ht12 w80 borderL' },
  { item: 'SALTO', show: <div className="saltoPng modalidad"/>, clases: 'text-start hide_md_down ht12 w80 borderL'},
  { item: 'PARALELAS', show: <div className="paralelasPng modalidad"/>, clases: 'text-start hide_md_down ht12 w80 borderL'},
  { item: 'BARRA', show: <div className="barraPng modalidad"/>, clases: 'text-start hide_md_down ht12 w80 borderL'},
  { item: 'total_general', show: 'Total', toFixed: 3, clases: 'text-end w80' },
]
const primer_salto = [
  { item: 'puestoSalto', show: 'Rank.', clases: 'text-end' },
  { item: 'numero_gimnasta', show: '#', clases: 'text-end' },
  { item: 'participante', show: 'Gimnastas', clases: 'text-start' },
  { item: 'equipo__bandera', show: '', clases: 'text-end' },
  { item: 'equipo', show: 'Equipo', clases: 'text-start hide_md_down' },
  { item: 'dificultad', show: 'D', toFixed: 3, clases: 'text-end hide_md_down' },
  { item: 'ejecución_final', show: 'E', toFixed: 3, clases: 'text-end hide_md_down' },
  { item: 'neutrales', show: 'N', toFixed: 1, clases: 'text-end text-danger hide_md_down' },
  { item: 'nota_final', show: 'Total', toFixed: 3, clases: 'text-end' },
]
const salto = [
  { item: 'puesto', show: 'Rank.', clases: 'text-end' },
  { item: 'numero_gimnasta', show: '#', clases: 'text-end' },
  { item: 'participante', show: 'Gimnastas', clases: 'text-start' },
  { item: 'q', show: 'Q', clases: 'text-end' },
  { item: 'equipo__bandera', show: '', clases: 'text-end' },
  { item: 'equipo', show: 'Equipo', clases: 'text-start hide_md_down' },
  { item: 'dificultad', show: 'D', clases: 'text-end hide_md_down' },
  { item: 'ejecución_final', show: 'E', clases: 'text-end hide_md_down' },
  { item: 'neutrales', show: 'N', clases: 'text-end text-danger hide_md_down' },
  { item: 'nota_final', show: 'sub', clases: 'text-end' },
  { item: 'salto', show: 'Total', toFixed: 3, clases: 'text-end' },
]
const team = [
  { item: 'puestoTeam', show: '#', clases: 'text-end' },
  { item: 'representacion__bandera', show: '', clases: 'text-end' },
  { item: 'representacion', show: 'Equipo', clases: 'text-start' },
  { item: 'categoria', show: 'Categoria', clases: 'text-center hide_md_down' },
  { item: 'puesto_a', show: 'Puesto', clases: 'text-end hide_md_down' },
  { item: 'total', show: 'Total', clases: 'text-end' },
]

const lista_equipos = [
  { item: 'count', show: 'Total', clases: 'text-end' },
  { item: 'representacion__bandera', show: '', clases: 'text-end' },
  { item: 'representacion', show: 'Equipo', clases: 'text-start' },
]
const orden_de_paso = [
  { item: 'numero_gimnasta', show: '#', clases: 'text-end' },
  { item: 'participante', show: 'Gimnastas', clases: 'text-start' },
  { item: 'equipo__bandera', show: '', clases: 'text-end w30px' },
  { item: 'equipo__siglas', show: 'Equipo', clases: 'text-start w60px' },
]
const lista_de_equipos = [
  { item: 'numero_gimnasta', show: '#', clases: 'text-end' },
  { item: 'participante', show: 'Gimnastas', clases: 'text-start' },
  { item: 'equipo__bandera', show: '', clases: 'text-end noPadding w30px' },
  { item: 'equipo__siglas', show: 'Equipo', clases: 'text-start w60px' },
]

const teamF = [
  { item: 'puesto', show: 'Rank.', clases: 'text-end ht12' },
  { item: 'numero_gimnasta', show: '#', clases: 'text-end ht12' },
  { item: 'participante', show: 'Gimnastas', clases: 'text-start ht12 borderL expArt' },
  { item: 'SALTO', show: <div className="saltoPng modalidad"/>, clases: 'text-start hide_md_down ht12 w80 borderL'},
  { item: 'ASIMETRICAS', show:  <div className="asimetricasPng modalidad"/>, clases: 'text-start hide_md_down ht12 w80 borderL' },
  { item: 'VIGA', show:  <div className="vigaPng modalidad"/>, clases: 'text-start hide_md_down ht12 w80 borderL' },
  { item: 'SUELO', show:  <div className="sueloPng modalidad"/>, clases: 'text-start hide_md_down ht12 w80 borderL' },
  { item: 'total_equipo', show: 'Total', clases: 'text-end w80 ht12'},
]
const teamM = [
  { item: 'puesto', show: 'Rank.', clases: 'text-end ht12' },
  { item: 'numero_gimnasta', show: '#', clases: 'text-end ht12' },
  { item: 'participante', show: 'Gimnastas', clases: 'text-start ht12 borderL expArt' },
  { item: 'SUELO', show:  <div className="sueloPng modalidad"/>, clases: 'text-start hide_md_down ht12 w80 borderL' },
  { item: 'ARZONES', show:  <div className="arzonesPng modalidad"/>, clases: 'text-start hide_md_down ht12 w80 borderL' },
  { item: 'ANILLAS', show:  <div className="anillasPng modalidad"/>, clases: 'text-start hide_md_down ht12 w80 borderL' },
  { item: 'SALTO', show: <div className="saltoPng modalidad"/>, clases: 'text-start hide_md_down ht12 w80 borderL'},
  { item: 'PARALELAS', show: <div className="paralelasPng modalidad"/>, clases: 'text-start hide_md_down ht12 w80 borderL'},
  { item: 'BARRA', show: <div className="barraPng modalidad"/>, clases: 'text-start hide_md_down ht12 w80 borderL'},
  { item: 'total_equipo', show: 'Total', clases: 'text-end w80 ht12' },
]

const calendario = [
  { item: 'inicio', show: 'FECHA', clases: 'text-start w135px title', format: formatDateWhitDay },
  { item: 'horaInicio', show: 'inicio', clases: 'text-center w70', format: formatTime },
  { item: 'fin', show: 'fin', clases: 'text-center w70', format: formatNoSeg },
  { item: 'evento', show: 'Evento', clases: 'text-start' },
  { item: 'detalles', show: 'Detalles', clases: 'text-start hide_md_down w40pto2' },
]

const medallistas = [
  { item: 'evento', show: 'Evento', clases: 'text-center borderSolid align-middle backgroundWhite' },
  { item: 'medalla', show: 'Medalla', clases: 'text-center borderSolid align-middle backgroundWhite' },
  { item: 'equipo__bandera', show: '', clases: 'text-end noPadding w30px borderSolid align-middle backgroundWhite' },
  { item: 'numero_gimnasta', show: '#', clases: 'text-end borderSolid' },
  { item: 'participante', show: 'Gimnastas', clases: 'text-start ht12 borderL borderSolid' },
  { item: 'equipo', show: 'Equipo', clases: 'text-start borderSolid align-middle backgroundWhite' },
]
const clasificacion_medallas = [
  { item: 'puesto', show: 'Rank.', clases: 'text-end' },
  { item: 'equipo__bandera', show: '', clases: 'text-end noPadding w30px align-middle' },
  { item: 'equipo', show: 'Equipo', clases: 'text-left align-middle' },
  { item: 'oro', show: 'Oro', clases: 'text-center align-middle' },
  { item: 'plata', show: 'Plata', clases: 'text-center align-middle' },
  { item: 'bronce', show: 'Bronce', clases: 'text-center align-middle' },
  { item: 'totalMedallas', show: 'Total', clases: 'text-center align-middle' },
]
const multi_medallistas = [
  { item: 'puesto', show: 'Rank.', clases: 'text-end align-middle ht12 bold' },
  { item: 'numero_gimnasta', show: '#', clases: 'text-end align-middle ht12' },
  { item: 'participante', show: 'Gimnastas', clases: 'text-start align-middle ht12' },
  { item: 'equipo__bandera', show: '', clases: 'text-end noPadding w30px align-middle hide_md_down' },
  { item: 'equipo__siglas', show: 'Equipo', clases: 'text-start w60px align-middle ht12' },
  { item: 'eventos', show: 'Evento', clases: 'text-end align-middle ht12' },
  { item: 'medalla', show: 'Medalla', clases: 'text-start align-middle ht12' },
  { item: 'oro', show: 'Oro', clases: 'text-center align-middle ht12 hide_md_down' },
  { item: 'plata', show: 'Plata', clases: 'text-center align-middle ht12 hide_md_down' },
  { item: 'bronce', show: 'Bronce', clases: 'text-center align-middle ht12 hide_md_down' },
  { item: 'totalMedallas', show: 'Total', clases: 'text-center align-middle ht12 bold' },
]
const lista_de_jueces = [
  { item: 'orden', show: '', clases: 'text-end' },
  { item: 'nombre', show: 'Nombre', clases: 'text-start' },
  { item: 'representacion__bandera', show: '', clases: 'text-start', format: formatBandera},
  { item: 'representacion_id', show: 'Repr.', clases: 'text-start' },
  { item: 'funcion', show: 'Func.', clases: 'text-start' },
  { item: 'id_fig', show: 'Id Fig', clases: 'text-start' },
  { item: 'categoria', show: 'Categ.', clases: 'text-start' },
]
const panel = [
  { item: 'orden', show: '#', clases: 'text-end ht12' },
  { item: 'puesto', show: 'Func.', clases: 'text-start ht12' },
  { item: 'nombre', show: 'Nombre', clases: 'text-start ht12' },
  { item: 'representacion__bandera', show: '', clases: 'text-start ht12', format: formatBandera},
  { item: 'representacion_id', show: 'Repr.', clases: 'text-start ht12' },
]
const asignacion_de_paneles = [
  { item: 'object', show: '', clases: 'text-end' },
]
const SubTableAsignaciones = [
  { item: 'orden', show: '#', clases: 'text-end ht12' },
  { item: 'puesto', show: 'Func.', clases: 'text-start ht12' },
  { item: 'juez__nombre', show: 'Nombre', clases: 'text-start ht12' },
  { item: 'juez__representacion__bandera', show: '', clases: 'text-start ht12', format: formatBandera},
  { item: 'juez__representacion__siglas', show: 'Repr.', clases: 'text-start ht12' },
]
const individualScores = [
  { item: 'puesto', show: 'Rank.', clases: 'text-end bold align-middle' },
  { item: 'numero_gimnasta', show: '#', clases: 'text-end align-middle' },
  { item: 'participante', show: 'Gimnastas', clases: 'text-start align-middle' },
  { item: 'q', show: '', clases: 'text-end ht12 align-middle' },
  { item: 'equipo__bandera', show: '', clases: 'text-end align-middle' },
  { item: 'equipo__siglas', show: '', clases: 'text-start hide_md_down ht12 align-middle' },
  { item: 'dificultad', show: 'D', clases: 'text-end hide_md_down', format:formatFixed3 },
  { item: 'ejecución_1', show: 'E1', clases: 'text-center hide_md_down ht12 lh15px', format:formatBase10 },
  { item: 'ejecución_2', show: 'E2', clases: 'text-center hide_md_down ht12 lh15px', format:formatBase10 },
  { item: 'ejecución_3', show: 'E3', clases: 'text-center hide_md_down ht12 lh15px', format:formatBase10 },
  { item: 'ejecución_4', show: 'E4', clases: 'text-center hide_md_down ht12 lh15px', format:formatBase10 },
  { item: 'ejecución_5', show: 'E5', clases: 'text-center hide_md_down ht12 lh15px', format:formatBase10 },
  { item: 'ejecución_6', show: 'E6', clases: 'text-center hide_md_down ht12 lh15px', format:formatBase10 },
  { item: 'ejecución_7', show: 'E7', clases: 'text-center hide_md_down ht12 lh15px', format:formatBase10 },
  { item: 'ejecución_final', show: 'E', toFixed: 3, clases: 'text-end hide_md_down', format:formatFixed3 },
  { item: 'línea_total', show: 'L', clases: 'text-end text-danger hide_md_down ht12 lh15px', format:formatFixed1},
  { item: 'cronómetro', show: 'T', clases: 'text-end text-danger hide_md_down ht12 lh15px', format:formatFixed1},
  { item: 'deducciones', show: 'O', clases: 'text-end text-danger hide_md_down ht12 lh15px', format:formatFixed1},
  { item: 'nota_final', show: 'Total', toFixed: 3, clases: 'text-end', format:formatFixed3 },
]
const individualScoresSalto = [
  { item: 'puestoSalto', show: 'Rank.', clases: 'text-end bold align-middle' },
  { item: 'numero_gimnasta', show: '#', clases: 'text-end align-middle' },
  { item: 'participante', show: 'Gimnastas', clases: 'text-start align-middle' },
  { item: 'q', show: '', clases: 'text-end ht12 align-middle' },
  { item: 'equipo__bandera', show: '', clases: 'text-end align-middle' },
  { item: 'equipo__siglas', show: '', clases: 'text-start hide_md_down ht12 align-middle' },
  { item: 'dificultad', show: 'D', clases: 'text-end hide_md_down', format:formatFixed3 },
  { item: 'ejecución_1', show: 'E1', clases: 'text-center hide_md_down ht12 lh15px', format:formatBase10 },
  { item: 'ejecución_2', show: 'E2', clases: 'text-center hide_md_down ht12 lh15px', format:formatBase10 },
  { item: 'ejecución_3', show: 'E3', clases: 'text-center hide_md_down ht12 lh15px', format:formatBase10 },
  { item: 'ejecución_4', show: 'E4', clases: 'text-center hide_md_down ht12 lh15px', format:formatBase10 },
  { item: 'ejecución_5', show: 'E5', clases: 'text-center hide_md_down ht12 lh15px', format:formatBase10 },
  { item: 'ejecución_6', show: 'E6', clases: 'text-center hide_md_down ht12 lh15px', format:formatBase10 },
  { item: 'ejecución_7', show: 'E7', clases: 'text-center hide_md_down ht12 lh15px', format:formatBase10 },
  { item: 'ejecución_final', show: 'E', toFixed: 3, clases: 'text-end hide_md_down', format:formatFixed3 },
  { item: 'línea_total', show: 'L', clases: 'text-end text-danger hide_md_down ht12 lh15px', format:formatFixed1},
  { item: 'cronómetro', show: 'T', clases: 'text-end text-danger hide_md_down ht12 lh15px', format:formatFixed1},
  { item: 'deducciones', show: 'O', clases: 'text-end text-danger hide_md_down ht12 lh15px', format:formatFixed1},
  { item: 'nota_final', show: 'Total', toFixed: 3, clases: 'text-end', format:formatFixed3 },
]
const Artistica = {
  ANILLAS: generic,
  ARZONES: generic,
  ASIMETRICAS: generic,
  BARRA: generic,
  PARALELAS: generic,
  SALTO: salto,
  'PRIMER SALTO': primer_salto,
  'ALL AROUND F': allF,
  'ALL AROUND M': allM,
  'TEAM F': teamF,
  'TEAM M': teamM,
  SUELO: generic,
  VIGA: generic,
  team: team,
  lista_equipos: lista_equipos,
  lista_de_equipos: lista_de_equipos,
  orden_de_paso: orden_de_paso,
  calendario: calendario,
  medallistas: medallistas,
  clasificacion_medallas: clasificacion_medallas,
  multi_medallistas: multi_medallistas,
  lista_de_jueces: lista_de_jueces,
  panel: panel,
  asignacion_de_paneles: asignacion_de_paneles,
  SubTableAsignaciones: SubTableAsignaciones,
  'Individual Judges Scores': individualScores,
  'Individual Judges Scores Salto': individualScoresSalto,
}

export {
  Artistica, formatTime, calendario, lista_de_jueces,
  panel,
  asignacion_de_paneles,
  SubTableAsignaciones,
};