import React, { useReducer, useEffect } from "react";
import globalContext from "./globalContext";
import globalReducer from "./globalReducer";

var SHA256 = require("crypto-js/sha256");

// var ws = null;
var db;
function GlobalState(props) {
  const [state, dispatch] = useReducer(globalReducer, {
    domains: {
      local:'https://dev.consugisoft.com',
      consugi:'https://consugi.consugisoft.com',
    },
    subdominios: [],
    filtros:{},
    current: {},
    messages:[],
    online: true,
    URLdomain: '',
    useParams: null,
    ranking:[],
  });
  const {domains, subdominios} = state;
  var request = null;
  if(window.indexedDB){
    request = window.indexedDB.open("Soft", 3);
    request.onsuccess = function(event) {
      db = request.result;
    };
    request.onupgradeneeded = function(event) {
      let dbre = event.target.result;
      var objectStore = dbre.createObjectStore("responses", { keyPath: "pk" });
      objectStore.transaction.oncomplete = event => {
        console.info('Tabla creada!')
      };
    }
  }else{
    console.log("Your browser doesn't support a stable version of IndexedDB. Such and such feature will not be available.");
  }
  useEffect(() => {
    sendServer({domain: 'consugi', app:'global', action:'get_subdominios'})
    if(state.URLdomain===''){
      var url = window.location.href;
      var sub = url.split("/");
      if(sub.length>3&&sub[3]!==''){
        sendState({URLdomain:sub[3]})
      }else{
        sendState({URLdomain:'consugi'})
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    let new_domains = domains
    subdominios.map(e=>{
      new_domains[e.nombre] = e.subdominio;
    })
    sendState({"domains":new_domains})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subdominios])
  
  useEffect(() => {
    // coneccion();
    if(state.URLdomain!==''){
      setTimeout(() => {
        sendServer({domain: state.URLdomain, app:'home', action:'getInitial'})
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state.URLdomain]);

  const getLocalData = (message)=>{
    if(request && db){
      let transaction = db.transaction(["responses"], "readwrite");
      let objectStore = transaction.objectStore("responses");
      let pk = SHA256(btoa(JSON.stringify(message)+state.URLdomain)).toString();
      var index = objectStore.get(pk);
      index.onsuccess = function(event) {
        if(event.target.result){
          delete event.target.result['pk'];
          dispatch({
            type: 'DATA_CHANGE',
            payload: event.target.result,
          });
        }else{
          console.log('No Existe!');
        }
      };
    }
  }
  function sendServer(message){
    // if(state.URLdomain==='')console.log('No hay Organizacion', message)
    if(!message.domain)console.warn("no se esta enviando el dominio");
    getLocalData(message);
    // console.log(message['domain'], (`${domains[ message['domain']?message['domain']:'consugi' ]}/api`))
    fetch(`${domains[message['domain']?message['domain']:'consugi']}/api`,{
      method:'POST',
      mode: 'cors',// 
      body: JSON.stringify(message),
      credentials: "include",
      headers:{
        'Content-Type': 'application/json'
      },
    }).then(response =>response.json()
    ).then(data => {
      dispatch({
        type: 'DATA_CHANGE',
        payload: {...data, online: true},
      });
      if(request && message['app']!=='jueces' && db){
        let transaction = db.transaction(["responses"], "readwrite");
        let objectStore = transaction.objectStore("responses");
        let pk = SHA256(btoa(JSON.stringify(message)+state.URLdomain)).toString();
        data['pk'] = pk;
        var index = objectStore.get(pk);
        index.onsuccess = function(event){
          delete data['user'];
          delete data['authorized'];
          delete data['message'];
          delete data['asignations'];
          delete data['current'];
          if(event.target.result){
            objectStore.put(data);
          }else{
            objectStore.add(data);
          }
        };
      }
    }).catch(
      error => {
        if(state.online){ 
          dispatch({
            type: 'DATA_CHANGE',
            payload: {online: false},
          });
        }
      }
    );
  }
  const sendState = (data)=>{
    dispatch({
      type: 'DATA_CHANGE',
      payload: data,
    });
  }
  return (
    <globalContext.Provider
      value={{
        URLdomain: domains[state.URLdomain],
        state: state,
        sendServer,
        sendState,
      }}
    >
      {props.children}
    </globalContext.Provider>
  );
}

export default GlobalState;
